import { Link } from 'react-router-dom';

import { Spinner } from 'components';
import { routes } from 'constants/index';

import { ListThreeUiProps } from './ListThreeDetails.types';

import './list-three-details.scss';

export default function ListThreeDetails({
  isLoading,
  data,
}: ListThreeUiProps) {
  if (isLoading) return <Spinner />;

  return (
    <div className="list-three-details">
      <div className="grid">
        <div className="sm:col-12 md:col-6 lg:col-2 lg:col-offset-10">
          <Link
            to={`${routes.logs.index}?list=2&employeeId=${data.ticketDetails?.employee_staff_id}`}
            className="std-btn flex flex-align-items-center flex-justify-content-center"
          >
            History of Tickets
          </Link>
        </div>
      </div>

      <div className="list-three-details__info">
        <div className="grid">
          <div className="sm:col-12 md:col-4">
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Azadea ID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.employee_staff_id || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">PersonID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.person_id || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Country</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.country || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Company</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.company_name || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">BU Code</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.bu_code || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">BU Name</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.bu_name || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">EMPFIRSTNAME</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.first_name || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">EMPFATHERNAME</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.emp_father_name || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">EMPFAMILYNAME</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.family_name || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Leave Date</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.leave_date || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Leave Reason</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.leave_reason || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Relation</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.relation || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">DOB</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.dob || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Gender</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.gender || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">NATIONALITY</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.nationality || 'N/A'}
                </div>
              </div>
            </div>
          </div>

          <div className="sm:col-12 md:col-4">
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Marital</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.marital_status || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">COST SHARING</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.cost_sharing || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Position</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.position || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">CLASS</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.class || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Grade</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.grade || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">MOBILEPHONE</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.mobile_no || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">EMAIL</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.email || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">NATIONAL ID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.national_id || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">IQAMANUMBER</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.iq_ama_number || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">IQAMAEXPIRYDATE</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.iq_ama_expiryDate || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">SPONSORID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.sponsorid || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">KID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.kid || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">QID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.qid || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">EMIRATESID</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.emirates_id_no || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">Passport number</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.passport_no || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">NATIONALITY</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.nationality || 'N/A'}
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-12 md:col-4">
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">uid_number</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.uid || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">VISA_ISSUANCE</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.emirate_visa_issuance || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">MEMBER_TYPE</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.member_type || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">ENTITY_TYPE</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.entity_type || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">
                  ESTABLISHMENT_ID
                </div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.establishment_id || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">INSURED</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.insured || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">
                  RESIDENTIAL_LOCATION
                </div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.residential_location || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">WORK_LOCATION</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.work_location || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">SALARYBRACKET</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.employees_salary || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">HASCOMMISSION</div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.commission || 'N/A'}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="sm:col-12 md:col-4">
                <div className="list-three-details__label">
                  USER_PERSON_TYPE
                </div>
              </div>
              <div className="sm:col-12 md:col-8">
                <div className="list-three-details__value">
                  {data.ticketDetails?.user_person_type || 'N/A'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
