import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';
import { useConfigQueries } from 'modules/App/queries';
import { getGermanDate } from 'utils';

import {
  useListFiveQuery,
  useInvoiceInfoQuery,
  useListSixQuery,
} from '../queries';
import {
  useListFiveMutation,
  useUpdateInvoiceStatusMutation,
} from '../mutations';

import {
  ListFiveFilter,
  ListSixFilter,
  DropdownChangeEvent,
  PaginatorPageChangeEvent,
  DataTableRowEvent,
  DataTableExpandedRows,
  ListSixTicket,
  CalendarChangeEvent,
} from './ListFiveNeedForApprovalListing.types';

import ListFiveNeedForApprovalListingUi from './ListFiveNeedForApprovalListing.ui';

export default function ListFiveNeedForApprovalListing() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: any = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    invoiceNumber: searchParams.get('invoiceNumber') || '',
    exceptionId: searchParams.get('exceptionId') || '',
    country: searchParams.get('country') || '',
    minDate: searchParams.get('minDate') || '',
    maxDate: searchParams.get('maxDate') || '',
  };

  //* Local State
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [isRejectionDialogVisible, setIsRejectionDialogVisible] =
    useState(false);
  const [isInvoiceInfoVisible, setIsInvoiceInfoVisible] = useState(false);
  const [isListSixInfoVisible, setIsListSixInfoVisible] = useState(false);
  const [listSixSelectedId, setListSixSelectedId] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [cloverInvoice, setCloverInvoice] = useState('');
  const [expandedRows, setExpandedRows] =
    useState<DataTableExpandedRows | null>(null);
  const [selectedEmployees, setSelectedEmployees] = useState<ListSixTicket[]>(
    []
  );
  const [rejectionInfo, setRejectionInfo] = useState({
    id: '',
    description: '',
  });
  const [filter, setFilter] = useState<ListFiveFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    invoiceNumber: params.invoiceNumber,
    exceptionId: params.exceptionId,
    country: params.country,
    dates: [new Date(params.minDate), new Date(params.maxDate)],
  });

  const [listSixFilter, setListSixFilter] = useState<ListSixFilter>({
    page: params.page,
    first: parseInt(params.first),
    rows: parseInt(params.rows),
  });

  //* Queries & Mutations
  const [, endorsementConfig] = useConfigQueries();
  const listFiveQuery = useListFiveQuery({ ...params, exceptions: 1 });
  const listSixQuery = useListSixQuery(
    { cloverInvoice, ...listSixFilter },
    { enabled: cloverInvoice.length > 0 }
  );
  const invoiceInfoQuery = useInvoiceInfoQuery(selectedInvoice, {
    enabled: selectedInvoice.length > 0,
  });
  const listFiveMutation = useListFiveMutation({
    onSuccess: handleDownloadSuccess,
  });
  const updateInvoiceStatusMutation = useUpdateInvoiceStatusMutation({
    onSuccess: handleUpdateInvoiceStatusSuccess,
  });

  //* Handlers
  function handleClearFilter() {
    setSearchParams({
      page: '1',
      first: '0',
      rows: '50',
      invoiceNumber: '',
      exceptionId: '',
    });

    setFilter({
      page: 1,
      rows: 50,
      first: 0,
      invoiceNumber: '',
      endorsementStatus: '',
      azadeaStatus: '',
      exceptions: '',
      exceptionId: 0,
      dates: null,
    });
  }

  const handleDownload = () => {
    listFiveMutation.mutate({ ...filter, exportable: true, exceptions: '1' });
  };

  function handleInputChange({ target: { name, value } }: any) {
    setFilter({
      ...filter,
      invoiceNumber: value.trim(),
    });
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.invoiceNumber.value.length > 0) {
      setSearchParams({
        ...params,
        invoiceNumber: event.target.elements.invoiceNumber.value.trim(),
      });
      setFilter((filter) => ({ ...filter, page: 1, rows: 50, first: 0 }));
    } else {
      const { invoiceNumber, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      invoiceNumber: event.target.elements.invoiceNumber.value,
    }));
  }

  function handleRejectionDescriptionChange({ target: { name, value } }: any) {
    setRejectionInfo((rejectionInfo) => ({
      ...rejectionInfo,
      [name]: value,
    }));
  }

  function handleRejectionReasonChange(event: DropdownChangeEvent) {
    setRejectionInfo((prevState) => ({
      ...prevState,
      id: String(event.value),
    }));
  }

  function handleExceptionChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      exceptionId: event.value,
      page: 1,
    });

    setFilter({
      ...filter,
      exceptionId: event.value,
      page: 1,
    });
  }

  function handleCountryChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      country: event.value,
      page: 1,
      first: 0,
    });

    setFilter((filter) => ({
      ...filter,
      country: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handleDateChange(event: CalendarChangeEvent) {
    if (Array.isArray(event.value)) {
      const minDate = !!event.value[0]
        ? getGermanDate(event.value[0].toISOString())
        : '';
      const maxDate = !!event.value[1]
        ? getGermanDate(event.value[1].toISOString())
        : '';

      setSearchParams({
        ...params,
        minDate,
        maxDate,
      });

      setFilter((filter) => ({
        ...filter,
        dates: event.value,
        page: 1,
        first: 0,
      }));
    }
  }

  function handleSelectionChange(event: any) {
    setSelectedEmployees(event.value);
  }

  const handleShowListFiveRowDetails = (invoiceNumber: string) => () => {
    setIsInvoiceInfoVisible(true);
    setSelectedInvoice(invoiceNumber);
  };

  const handleHideListFiveRowDetails = () => {
    setIsInvoiceInfoVisible(false);
  };

  function handleApprove() {
    setIsApproveModalVisible(true);
  }

  function handleConfirmApprove() {
    updateInvoiceStatusMutation.mutate({
      list6_id: selectedEmployees.map((invoice) => invoice.id),
      AZADEA_forceApproval: '1',
    });

    setSelectedEmployees([]);
    setIsApproveModalVisible(false);
  }

  function handleCancelApprove() {
    setIsApproveModalVisible(false);
  }

  function handleShowRejectionModal() {
    setIsRejectionDialogVisible(true);
  }

  function handleCloseRejectionModal() {
    setRejectionInfo({
      id: '',
      description: '',
    });
    setIsRejectionDialogVisible(false);
  }

  function handleConfirmReject() {
    updateInvoiceStatusMutation.mutate({
      list6_id: selectedEmployees.map((invoice) => invoice.id),
      AZADEA_rejectionId: rejectionInfo.id,
      AZADEA_rejectionDescription: rejectionInfo.description,
      AZADEA_rejectionIdDescription:
        endorsementConfig.data?.data.rejections
          .find(({ list }) => list === 6)
          ?.options.find(({ key }) => key === Number(rejectionInfo.id))
          ?.value || '',
    });

    setSelectedEmployees([]);
    handleCloseRejectionModal();
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...params,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleListSixPageChange(event: PaginatorPageChangeEvent) {
    setListSixFilter((filter) => ({
      ...filter,
      page: event.page + 1,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  function handleUpdateInvoiceStatusSuccess() {
    listFiveQuery.refetch();
    listSixQuery.refetch();
  }

  function handleRowToggle(event: any) {
    /**
     *! This is a workaround to get the last key of the object
     *! because the event.data object has a dynamic key
     */
    const expandedRowsKeysArray = Object.keys(event.data);
    const expandedRowsKeysArrayLastIndex = expandedRowsKeysArray.length - 1;

    /**
     *! We need to check if the expandedRowsKeysArray has more than one key
     *! because if it has only one key, it means that the row is collapsed
     *! and we need to set the expandedRows state to an empty object
     *! otherwise, we need to set the expandedRows state to the object
     *
     *! This is due to the fact that listSixQuery is dependent on the expandedRows state
     *! and if we have multiple rows expanded, the listSixQuery will be called multiple times
     *! and the data will be overwritten
     */
    setExpandedRows(
      expandedRowsKeysArray.length > 1
        ? {
            [expandedRowsKeysArray[expandedRowsKeysArrayLastIndex]]:
              event.data[expandedRowsKeysArray[expandedRowsKeysArrayLastIndex]],
          }
        : event.data
    );
  }

  const handleRowExpand = (event: DataTableRowEvent) => {
    setCloverInvoice(event.data.clover_invoice);
  };

  const handleRowCollapse = () => {
    setCloverInvoice('');
  };

  function handleListSixInfoDialogShow(id: number) {
    return function () {
      setIsListSixInfoVisible(true);
      setListSixSelectedId(id);
    };
  }

  function handleListSixInfoDialogClose() {
    setIsListSixInfoVisible(false);
  }

  useEffect(() => {
    if (
      listFiveQuery.isError ||
      invoiceInfoQuery.isError ||
      listSixQuery.isError ||
      listFiveMutation.isError ||
      updateInvoiceStatusMutation.isError
    ) {
      const errorMessage = extractErrorMessage(
        listFiveQuery.error ||
          invoiceInfoQuery.error ||
          listSixQuery.error ||
          listFiveMutation.error ||
          updateInvoiceStatusMutation.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [
    listFiveQuery.isError,
    invoiceInfoQuery.isError,
    listSixQuery.isError,
    listFiveMutation.isError,
    updateInvoiceStatusMutation.isError,
  ]);

  const isLoading =
    (listFiveQuery.isLoading && listFiveQuery.isInitialLoading) ||
    updateInvoiceStatusMutation.isLoading;

  const options = {
    exceptions:
      endorsementConfig.data?.data.exceptions
        .find(({ list }) => list === 5)
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
    rejectionReasons:
      endorsementConfig.data?.data.rejections
        .find(({ list }) => list === 6)
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
    countries:
      endorsementConfig.data?.data.countries.map(({ _id, value }) => ({
        key: _id,
        label: value,
        value: value,
      })) || [],
  };

  const data = {
    listSixSelectedId,
    list: listFiveQuery.data?.data.data || [],
    listSixData: listSixQuery.data?.data.data || [],
    selectedInvoice,
    rejectionInfo,
    selectedEmployees,
    invoiceInfo: invoiceInfoQuery.data?.data.data[0] || null,
  };

  const handlers = {
    handleClearFilter,
    handleDownload,
    handleInputChange,
    handleSubmitSearch,
    handleExceptionChange,
    handleApprove,
    handleConfirmApprove,
    handleShowRejectionModal,
    handleConfirmReject,
    handlePageChange,
    handleShowListFiveRowDetails,
    handleHideListFiveRowDetails,
    handleCancelApprove,
    handleCloseRejectionModal,
    handleRejectionDescriptionChange,
    handleRejectionReasonChange,
    handleSelectionChange,
    handleRowExpand,
    handleRowCollapse,
    handleRowToggle,
    handleListSixPageChange,
    handleListSixInfoDialogShow,
    handleListSixInfoDialogClose,
    handleCountryChange,
    handleDateChange,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListFiveNeedForApprovalListingUi
        isLoading={isLoading}
        isListSixLoading={listSixQuery.isLoading}
        isExporting={listFiveMutation.isLoading}
        isApproveModalVisible={isApproveModalVisible}
        isRejectionDialogVisible={isRejectionDialogVisible}
        isInvoiceInfoVisible={isInvoiceInfoVisible}
        isListSixInfoVisible={isListSixInfoVisible}
        totalRecords={listFiveQuery.data?.data.total || 0}
        listSixTotalRecords={listSixQuery.data?.data.total || 0}
        expandedRows={expandedRows}
        filter={filter}
        listSixFilter={listSixFilter}
        options={options}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
