import { api } from 'utils';

import {
  TFetchListOneHistoryLogsApi,
  TFetchListTwoHistoryLogsApi,
} from './api.types';

export const fetchListOneHistoryLogsApi: TFetchListOneHistoryLogsApi = (
  filter
) =>
  api().get('/tickets/history', {
    params: {
      page: filter.page,
      limit: filter.rows,
      searchString: filter.employeeId ? filter.employeeId : undefined,
      exportable: filter.exportable,
      employeeId: filter.exportable ? filter.employeeId : undefined,
      minDate: filter.startDate ? filter.startDate : undefined,
      maxDate: filter.endDate ? filter.endDate : undefined,
    },
  });

export const fetchListTwoHistoryLogsApi: TFetchListTwoHistoryLogsApi = (
  filter
) =>
  api().get('/tickets/list/two/history', {
    params: {
      page: filter.page,
      limit: filter.rows,
      searchString: filter.employeeId ? filter.employeeId : undefined,
      exportable: filter.exportable,
      employeeId: filter.exportable ? filter.employeeId : undefined,
      minDate: filter.startDate ? filter.startDate : undefined,
      maxDate: filter.endDate ? filter.endDate : undefined,
    },
  });
