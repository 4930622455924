import LocalizedStrings from 'react-localization';

const locales = new LocalizedStrings({
  en: {
    general: {
      close: 'close',
      login: 'Login',
      username: 'Username',
      phoneNumber: 'Phone number',
      password: 'Password',
      email: 'Email',
      mobileNumber: 'Mobile number',
      firstName: 'First Name',
      lastName: 'Last Name',
      yearOfBirth: 'Year of birth',
      continue: 'Continue',
      footer: '© All Rights Reserved to Clover Brokers',
      details: 'Details',
      dashboard: 'Dashboard',
      ok: 'Ok',
      fail: 'Fail',
      list: 'List',
      search: 'Search',
      history: 'History',
      logs: 'Logs',
      employeeID: 'Employee ID',
      studyID: 'Study ID',
      filterByStatus: 'Ticket status',
      filterByStatusCode: 'Status code',
      filterByType: 'Ticket type',
      clearFilter: 'Clear filter',
      success: 'Success',
      unsuccessful: 'Unsuccessful',
      tickets: 'Tickets',
      download: 'Download',
      logout: 'Logout',
      resetPin: 'Reset Pin',
      approve: 'Approve',
      reject: 'Reject',
      changeUserType: 'Change user type',
      requestOtp: 'Request OTP',
      admin: 'Admin',
      blocked: 'Blocked',
      errors: {
        required: 'Field required',
        numeric: 'Numeric values are not allowed',
        special: 'Special characters are not allowed',
        invalidEmail: 'Invalid email',
      },
    },
    errors: {
      title: 'Something went wrong!',
      generalDescription: 'Please contact the system administrator.',
      refreshPage: 'Refresh Page',
      forbidden: 'You are not allowed to view this page.',
      notFound: 'Record not found.',
      conflict: 'You are not allowed to view this page.',
    },
    azadea: {
      dashboard: {
        currentSystemStatus: 'Current System Status',
        serverStatus: 'Server Status',
        apiStatus: 'API Status',
        latestList0ErrorsStatus: 'Latest List0 Errors Status',
        latestList0ReceivedDate: 'Last List0 Received Date',
        latestErrorFreeDate: 'Latest Error Free List0 Date',
        currentlyOpenedTickets: 'Currently Opened Tickets by Type',
        total: 'Total',
        numberOfList0APIEvents: 'Number of List0 API Events',
        successfullyProcessedEvents: 'Successfully Processed Events',
        unsuccessfullyProcessedEvents: 'Unsuccessfully Processed Events',
      },
      historyTickets: {
        title: 'Tickets Logs',
      },
      apiProcessedEvents: {
        title: 'API Processed Events',
      },
      ticketDetails: {
        title: 'Ticket Details',
      },
    },
    role: {
      title: 'Select Role',
      error: 'This field is required.',
    },
    tables: {
      historyTickets: {
        ticketId: 'Ticket ID',
        type: 'Type',
        employeeId: 'Employee ID',
        createdDate: 'Created Date',
      },
      list1Tickets: {
        employeeId: 'Employee ID',
        type: 'Type',
        endorsementId: 'Endorsement ID',
        status: 'Status',
        statusDescription: 'Status Description',
        createdDate: 'Created Date',
        ticketId: 'Ticket ID',
        id: 'Ticket ID',
        studyId: 'Study ID',
        affectedDate: 'Affected Date',
      },
      list2Tickets: {
        employeeId: 'Employee ID',
        type: 'Type',
        endorsementId: 'Endorsement ID',
        status: 'Status',
        statusDescription: 'Status Description',
        createdDate: 'Created Date',
        ticketId: 'Ticket ID',
        id: 'Ticket ID',
        studyId: 'Study ID',
        affectedDate: 'Affected Date',
        policy: 'Policy ID',
      },
      studies: {
        type: 'Type',
        status: 'Status',
        statusDescription: 'Status Description',
        createdDate: 'Created Date',
        studyId: 'Study ID',
      },
      apiProcessedEvents: {
        studyId: 'Study ID',
        status: 'Status',
        statusCode: 'Status code',
        startTime: 'Start time',
        endTime: 'End time',
        message: 'Message',
        duplicatedEmployees: 'Duplicated employees',
        deletedEmployees: 'Deleted employees',
        createdDate: 'Created Date',
      },
      fields: {
        field: 'Field',
        type: 'Type',
        oldValue: 'Old value',
        newValue: 'New value',
      },
      list3ReducedTable: {
        employee_staff_id: 'Azadea ID',
        first_name: 'Name',
        company_name: 'Company',
        country: 'Country',
        class: 'Class',
        policy: 'Policy',
        detailedInfo: 'Detailed Info​',
        historyTickets: 'Tickets​ Logs',
      },
    },
    listSix: {
      id: 'ID',
      list2_id: 'List Two ID',
      bu: 'BU',
      azadea_validationresult_oneach_employee_id:
        'Azadea Validation Result On Each Employee ID',
      endorsementtype: 'Endorsement Type',
      vatrate: 'Vat Rate',
      relation: 'Relation',
      azadeaemployee_id: 'Azadea Employee ID',
      invoicetype: 'Invoice Type',
      clover_invoice: 'Clover Invoice',
      endorsement_lob: 'Endorsement LOB',
      azadea_hrms_companycode: 'Azadea HRMS Company Code',
      insuranceenddate: 'Insurance End Date',
      endorsement_id: 'Endorsement ID',
      plan: 'Plan',
      azadea_jde_companycode: 'Azadea JDE Company Code',
      updated: 'Updated',
      proratedamount: 'Pro Rated Amount',
      company: 'Company',
      supplier_invoice: 'Supplier Invoice',
      total: 'Total',
      paymentdestination: 'Payment Destination',
      insurancestartdate: 'Insurance Start Date',
      azadea_addressnumber: 'Azadea Address Number',
      created: 'Created',
      invoicedate: 'Invoice Date',
      currency: 'Currency',
      azadea_validationresult_oneach_employee_text:
        'Azadea Validation Result On Each Employee Text',
      vatamount: 'Vat Amount',
      insurancecompany: 'Insurance Company',
      active: 'Active',
      name: 'Name',
      _fivetran_deleted: 'Fivetran Deleted',
      _fivetran_synced: 'Fivetran Synced',
      gender: 'Gender',
      maritalstatus: 'Marital Status',
      personid: 'Person ID',
      clover_exceptionid: 'Clover Exception ID',
      clover_exceptiondescription: 'Clover Exception Description',
      country: 'Country',
      color_difference: 'Color Difference',
      azadea_forceapprovaldate_list6: 'Azadea Force Approval Date List6',
      azadea_forceapprovaluser_list6: 'Azadea Force Approval User List6',
      azadea_rejectionid_list6: 'Azadea Rejection ID List6',
      total_pricetable: 'Total Price Table',
      difference_total: 'Difference Total',
      azadea_rejectiondescription_list6: 'Azadea Rejection Description List6',
      azadea_forceapproval_list6: 'Azadea Force Approval List6',
      status_manual_approval: 'Status Manual Approval',
      azadea_updater: 'Azadea Updater',
      azadea_rejectioniddescription_list6: 'List 6 rejection description',
      uytag: 'UY Tag',
    },
    header: {
      links: {
        dashboard: 'Dashboard',
        reducedTable: 'List 3',
        ticketLogs: 'Logs',
        directory: 'Directory',
        calls: 'Call History',
      },
    },
    login: {
      title: 'Login',
      forgotPassword: 'Forgot password?',
      rememberMe: 'Remember me on this computer',
      intranetSignup: 'Extranet Sign Up',
      userSignUp: 'User Sign Up',
      reset: 'Reset',
      errors: {
        general: 'Something went wrong!. Please contact system administrator.',
        pinRequired: 'Pin is required.',
        otpRequired: 'OTP is required.',
        emptyNumber: 'Please enter a valid phone number.',
      },
    },
    OTP: {
      mobileTitle:
        'Please enter the 6-digit OTP you just received on your phone number {phoneNumber}',
      emailTitle:
        'Please check your email and enter below the code you have received to your {email}',
      inputLabel: 'Please enter the 6-digit OTP',
      resendPlatform: 'Resend OTP by {platform}',
      resend: 'Resend OTP',
      errors: {
        invalid: 'Please enter the 6-digit OTP.',
      },
    },
    dashboard: {
      title: 'Dashboard',
      totalOpenTickets: 'Total Open Tickets',
      totalTicketsToFollowUp: 'Total Tickets to Follow Up',
      byStatus: 'By Status',
      addition: 'Addition',
      deletion: 'Deletion',
      openPending: 'Open Pending',
      openClean: 'Open Clean',
      openError: 'Open Error',
      normalFollowUp: 'Normal Follow up',
      errorFollowUp: 'Error Follow up',
      byType: 'By Type',
      totalOpenAndClosedTickets:
        'Total Open and Closed Tickets in list 2 by week',
      livePopulation: 'Live Population',
      totalInsured: 'Total Insured',
      noLifeInsurance: 'No Life Insurance',
      invoiceStatus: 'Invoice Status',
      needApproval: 'Need Approval',
      underAzadeaReview: 'Under Azadea Review',
      dependents: 'Dependents',
      Principals: 'Principals',
      endorsementStatus: 'Endorsement Status',
      systemHealth: 'System Health',
      apiEvents: 'API Events',
      apiStatus: 'API Status',
      latestListZero: 'Latest List 0',
      errorStatus: 'Error Status',
      listZeroStatus: 'List 0 Status',
      recordsCount: 'Records Count',
      latestSuccessfulListZero: 'Latest successful List 0',
      dbDiscrepancies: 'DB Discrepancies',
      listZeroDiscrepancies: 'List 0 Discrepancies',
      listThreeDiscrepancies: 'List 3 Discrepancies',
      policiesDiscrepancy: 'Policies Discrepancies',
      rbacDiscrepancies: 'RBAC Discrepancies',
    },
    personalPin: {
      welcome: 'Welcome',
      title:
        'Please create your personal pin that will be used to access Clover apps',
      label: 'Personal pin',
      confirmation: 'Re-enter your personal pin',
      errors: {
        requiredPin: 'Please enter the 6-digit Pin.',
        requiredPinConfirmation: 'Please re-enter the 6-digit Pin.',
        notMatch: "Pin confirmation doesn't match personal pin.",
      },
    },
    resetPin: {
      title: 'Reset Your Pin',
      submit: 'Rest pin',
      newPin: 'Enter the your new pin',
      confirmNewPin: 'Confirm new pin',
    },
    userDetails: {
      title: 'Create your extranet account',
      placeholder: {
        firstName: 'John',
        lastName: 'Doe',
        email: 'email@email.com',
        yearOfBirth: 'E.g. 1990',
      },
    },
    table: {
      contactName: 'contact name',
      incomingCallsNT: {
        head: {
          callDate: 'call date',
          branch: 'branch',
        },
        body: {
          createTicket: 'Create Ticket',
        },
      },
      incomingCalls: {
        head: {
          ticketNb: 'ticket number',
          createdOn: 'created on',
          status: 'status',
        },
        body: {
          createTicket: 'Create Ticket',
        },
      },
    },
    tickets: {
      title: 'Tickets',
      menu: {
        sent: 'sent',
        pendingResponse: 'pending response',
        resolved: 'resolved',
      },
      table: {
        head: {
          ticketName: 'ticket name',
          ticketNumber: 'ticket number',
          createdOn: 'created on',
          country: 'country',
          department: 'department',
          assignedTo: 'assigned to',
          status: 'status',
        },
      },
    },
    users: {
      title: 'Users',
      table: {
        head: {
          fullName: 'full name',
          id: 'id',
          uidLegacy: 'UID LEGACY',
        },
      },
    },
  },
});

export default locales;
