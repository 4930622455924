import { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Joi from 'joi';

import { getPhoneNumber, Helpers, setUserId, getUserId } from 'utils';
import { routes, countries, locales, enums } from 'constants/index';
import {
  useExtractServerError,
  useValidate,
  useCleanedPhoneNumber,
} from 'hooks';

import { useRequestPinResetMutation } from '../mutations';

import ResetPinUi from './ResetPin.ui';

import { DropdownChangeEvent } from './ResetPin.types';

const validationSchema = {
  phoneNumber: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
};

export default function ResetPin() {
  const storePhoneNumber = getPhoneNumber();

  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const navigate = useNavigate();
  const cleanPhoneNumber = useCleanedPhoneNumber();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  //* Local State
  const [form, setForm] = useState({
    phoneNumber: storePhoneNumber.phoneNumber || '',
    countryCode: enums.countryCodes.lb,
    callingCode: enums.callingCodes.lb.toString(),
  });

  //* Queries & Mutations
  const requestPinResetMutation = useRequestPinResetMutation({
    onSuccess: handleRequestSuccess,
  });

  //* Handlers
  function handleInputChange({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  }

  function handleInputBlur({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    validate({
      data: {
        ...data,
        [name]: value,
      },
      validationSchema: validationSchema,
    });
  }

  function handleCountryChange(event: DropdownChangeEvent) {
    const { getCallingCode }: Helpers = new Helpers();

    setForm((prevState) => ({
      ...prevState,
      countryCode: String(event.value),
      callingCode: getCallingCode(event.value),
    }));
  }

  function handleSubmitClick() {
    const isFormValid = validate({
      data: { phoneNumber: form.phoneNumber },
      validationSchema: validationSchema,
    });

    if (!isFormValid) return;

    const cleanedPhoneNumber = cleanPhoneNumber(
      form.phoneNumber,
      form.callingCode
    );

    requestPinResetMutation.mutate(`${form.callingCode}${cleanedPhoneNumber}`);
  }

  function handleRequestSuccess({ data }: any) {
    const { requireSecurityQuestion } = data;

    if (!getUserId()) setUserId(data.userId);

    if (requireSecurityQuestion)
      navigate(
        `${routes.authentication.index}/${routes.authentication.newPinReset}`
      );
  }

  useEffect(() => {
    if (requestPinResetMutation.isError) {
      const errorMessage = extractErrorMessage(requestPinResetMutation.error);

      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [requestPinResetMutation.isError]);

  const options = {
    countries: countries.map(({ callingCodes, alpha3Code, alpha2Code }) => {
      let countryObj = {
        key: '',
        value: '',
        alpha2Code: '',
        label: '',
      };
      callingCodes.forEach((callingCode) => {
        countryObj = {
          key: alpha3Code,
          value: alpha3Code,
          alpha2Code,
          label: `+${callingCode}`,
        };
      });

      return countryObj;
    }),
  };

  const data = {
    phoneNumber: form.phoneNumber,
    countryCode: form.countryCode,
  };

  const handlers = {
    handleInputChange,
    handleInputBlur,
    handleCountryChange,
    handleSubmitClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ResetPinUi
        isLoading={requestPinResetMutation.isLoading}
        options={options}
        errors={errors}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
