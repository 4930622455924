import { useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes } from 'constants/index';
import { useExtractServerError } from 'hooks';

import { useListTwoTicketStudiesQuery } from '../queries';

import ListTwoTicketStudiesUi from './ListTwoTicketStudies.ui';

import { DataTableRowClickEvent } from './ListTwoTicketStudies.types';

export default function ListTwoStudies() {
  //* Hooks Init
  const navigate = useNavigate();
  const { ticketId = '' } = useParams();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  //* Queries
  const listTwoTicketStudiesQuery = useListTwoTicketStudiesQuery(ticketId, {
    enabled: ticketId.length > 0,
  });

  //* Handlers
  function handleRowClick(event: DataTableRowClickEvent) {
    navigate(
      `${routes.listTwo.index}/${ticketId}/${routes.studies}/${event.data._id}`
    );
  }

  useEffect(() => {
    if (listTwoTicketStudiesQuery.isError) {
      const errorMessage = extractErrorMessage(listTwoTicketStudiesQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listTwoTicketStudiesQuery.isError]);

  const data = {
    studies: listTwoTicketStudiesQuery.data?.data.data[0].studies || [],
  };

  const handlers = {
    handleRowClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListTwoTicketStudiesUi
        isLoading={listTwoTicketStudiesQuery.isLoading}
        totalRecords={
          listTwoTicketStudiesQuery.data?.data.data[0].studies.length || 0
        }
        data={data}
        handlers={handlers}
      />
    </>
  );
}
