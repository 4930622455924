import PhoneNumber from 'awesome-phonenumber';
import { FormEvent } from 'react';

import { YearsArray } from './types';
import { enums } from 'constants/index';
import { countries } from 'constants/index';

class Helpers {
  convertYearsArrayToObject = (years: string[]): YearsArray => {
    let result: YearsArray = [];
    years.forEach((year: string, index: number) => {
      result.push({
        key: index,
        value: year,
        label: year,
      });
    });
    return result;
  };

  formSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
  }

  getAlpha2CountryCode = (countryCode: string): string => {
    return (
      countries.find((country) => countryCode === country.alpha3Code)
        ?.alpha2Code || enums.countryCodes.lb
    );
  };

  getCallingCode = (countryCode: string): string => {
    return (
      countries.find((country) => countryCode === country.alpha3Code)
        ?.callingCodes[0] || enums.countryCodes.lb
    );
  };

  formatPhoneNumber = (number: string, countryCode: string): string => {
    const alpha2CountryCode: string = this.getAlpha2CountryCode(countryCode);
    const pn = new PhoneNumber(number, alpha2CountryCode);
    return pn.getNumber('national');
  };
}

export default Helpers;
