//@ts-nocheck
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const loadPieChart = ({
  id,
  data,
  options: { color, category, value },
}: any) => {
  const chart = am4core.create(id, am4charts.PieChart);
  chart.data = data || [];
  chart.scale = 1.12;
  chart.innerRadius = 60;
  chart.fontSize = '14px';

  am4core.useTheme(am4themes_animated);

  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = value;
  pieSeries.dataFields.category = category;
  pieSeries.slices.template.stroke = am4core.color('#fff');
  pieSeries.slices.template.strokeOpacity = 1;
  pieSeries.labels._template.fill = '#3F4D50';

  // This creates initial animation
  pieSeries.hiddenState.properties.opacity = 1;
  pieSeries.hiddenState.properties.endAngle = -90;
  pieSeries.hiddenState.properties.startAngle = -90;

  chart.hiddenState.properties.radius = am4core.percent(0);
};

export default loadPieChart;
