import { useQueries } from '@tanstack/react-query';

import { fetchAuthConfig, fetchEndorsementConfig } from '../api';

const useConfigQuery = () =>
  useQueries({
    queries: [
      {
        queryKey: ['auth', 'config'],
        queryFn: fetchAuthConfig,
        staleTime: Infinity,
      },
      {
        queryKey: ['endorsement', 'config'],
        queryFn: fetchEndorsementConfig,
        staleTime: Infinity,
        cacheTime: 999,
      },
    ],
  });

export default useConfigQuery;
