import { useQuery } from '@tanstack/react-query';

import { fetchListThreeApi } from '../api';

export default function useListThreeDataQuery(params: any, options?: any) {
  return useQuery(
    ['list', 'three', params],
    () => fetchListThreeApi(params),
    options
  );
}
