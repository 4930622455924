import { ReactNode, RefObject } from 'react';
import { Toast } from 'primereact/toast';

interface IToastOptions {
  title?: string;
  message: string;
  severity?: 'info' | 'success' | 'warn' | 'error';
  sticky?: boolean;
  content?: ReactNode;
}

export default function useToast(toast: RefObject<Toast>) {
  function show({
    severity = 'info',
    title,
    message,
    sticky,
    content,
  }: IToastOptions) {
    if (toast.current)
      toast.current.show({
        severity,
        summary: title,
        detail: message,
        sticky,
        content,
      });
  }

  return show;
}
