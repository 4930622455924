import { api } from 'utils';

import {
  FetchListFiveApiResponse,
  UpdateInvoiceStatusApiResponse,
  UpdateInvoiceStatusApiPayload,
  FetchInvoiceInfoApiResponse,
  ListFiveFilter,
  FetchListSixApiResponse,
  FetchListSixInfoApiResponse,
  ListSixFilter,
} from './api.types';

export function fetchListFiveApi(filter: ListFiveFilter) {
  return api().get<FetchListFiveApiResponse>('/list/five/fetch', {
    params: {
      page: filter.exportable ? undefined : filter.page,
      limit: filter.exportable ? undefined : filter.rows,
      invoice: filter.invoiceNumber,
      endorsementStatus: filter.endorsementStatus,
      azadeaStatus: filter.azadeaStatus,
      exceptions: filter.exceptions,
      exportable: filter.exportable,
      count: filter.count,
      exceptionId: filter.exceptionId,
      country: filter.country,
      minDate: filter.minDate,
      maxDate: filter.maxDate,
    },
  });
}

export function fetchInvoiceInfoApi({ queryKey }: any) {
  const [, , , , invoiceNumber] = queryKey;

  return api().get<FetchInvoiceInfoApiResponse>('/list/five/info', {
    params: { invoiceNumber },
  });
}

export function fetchListSixApi(filter: ListSixFilter) {
  return api().get<FetchListSixApiResponse>('/list/six/fetch', {
    params: {
      limit: filter.rows,
      page: filter.page,
      clover_invoice: filter.cloverInvoice,
    },
  });
}

export function fetchListSixInfoApi(id: string) {
  return api().get<FetchListSixInfoApiResponse>('/list/six/info', {
    params: { id },
  });
}

export function updateInvoiceStatusApi(payload: UpdateInvoiceStatusApiPayload) {
  return api().put<UpdateInvoiceStatusApiResponse>('/list/six/status', payload);
}
