import { Paginator as PrimeReactPaginator } from 'primereact/paginator';

import { IPaginatorProps } from './Paginator.types';

export default function Paginator({
  first,
  rows,
  totalRecords,
  rowsPerPageOptions,
  template = {
    layout:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
  },
  leftContent,
  rightContent,
  onPageChange,
}: IPaginatorProps) {
  return (
    <PrimeReactPaginator
      first={first}
      rows={rows}
      totalRecords={totalRecords}
      rowsPerPageOptions={rowsPerPageOptions}
      template={template}
      leftContent={leftContent}
      rightContent={rightContent}
      onPageChange={onPageChange}
    />
  );
}
