import { InputText, Button, Dropdown, Spinner } from 'components';

import { Helpers } from 'utils';
import { locales } from 'constants/index';

import { IPhoneRegisterUiProps } from './PhoneRegister.types';

import './phone-register.scss';

export default function PhoneRegisterUi({
  isLoading,
  errors,
  options,
  data: { phoneNumber, countryCode },
  handlers: { handleInputChange, handleSubmit, handleCountryChange },
}: IPhoneRegisterUiProps) {
  const { formatPhoneNumber }: Helpers = new Helpers();

  if (isLoading) return <Spinner />;

  return (
    <div className="phone-register">
      <div className="phone-register__description">{locales.login.title}</div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__field flex ">
          <Dropdown
            label={locales.general.mobileNumber}
            value={countryCode}
            name="countryCode"
            error={errors.countryCode}
            options={options.countries}
            onChange={handleCountryChange}
            filter
            showClear
          />
          <InputText
            name="phoneNumber"
            value={formatPhoneNumber(String(phoneNumber), countryCode)}
            placeholder={locales.general.phoneNumber}
            className="phone-register__mobile-number  primary-icon"
            onChange={handleInputChange}
            isError={errors.phoneNumber?.length > 0}
            // icon={{
            //   type: 'user circle',
            //   position: 'left',
            // }}
          />
        </div>

        {errors.phoneNumber?.length > 0 && (
          <div className="form__error">{locales.login.errors.emptyNumber}</div>
        )}

        <div className="form__field form__field--login-btn flex flex-justify-content-space-between flex-align-items-center">
          <Button
            className="phone-register__submit"
            disabled={isLoading}
            label={locales.login.title}
          />
        </div>
      </form>
    </div>
  );
}
