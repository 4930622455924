const columns = [
  {
    header: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    header: 'Ticket ID',
    field: 'ticketId',
    sortable: true,
  },
  {
    header: 'Employee ID',
    field: 'employeeId',
    sortable: true,
  },
  {
    header: 'Created Date',
    field: 'createdDate',
    sortable: true,
  },
  {
    header: 'Updated Date',
    field: 'updatedDate',
    sortable: true,
  },
];

export default columns;
