import { Routes, Route, Navigate } from 'react-router-dom';

import {
  PhoneRegister,
  PhoneOtp,
  EmailOtp,
  UserDetails,
  PersonalPin,
  Login,
  LoginOtpVerification,
  ResetPin,
  NewPinResetForm,
} from '.';

import { routes } from 'constants/index';

import AuthenticationUi from './Authentication.ui';

export default function Authentication() {
  const steps = (
    <Routes>
      <Route
        index
        path={routes.authentication.phoneRegister}
        element={<PhoneRegister />}
      />

      <Route path={routes.authentication.login} element={<Login />} />

      <Route
        path={routes.authentication.loginOtpVerification}
        element={<LoginOtpVerification />}
      />

      <Route
        path={routes.authentication.userDetails}
        element={<UserDetails />}
      />

      <Route path={routes.authentication.phoneOtp} element={<PhoneOtp />} />

      <Route path={routes.authentication.emailOtp} element={<EmailOtp />} />

      <Route
        path={routes.authentication.personalPin}
        element={<PersonalPin />}
      />

      <Route path={routes.authentication.resetPin} element={<ResetPin />} />

      <Route
        path={routes.authentication.newPinReset}
        element={<NewPinResetForm />}
      />

      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`${routes.authentication.index}/${routes.authentication.login}`}
          />
        }
      />
    </Routes>
  );

  return <AuthenticationUi steps={steps} />;
}
