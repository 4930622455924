import { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';
import { useListZeroStatusQuery } from 'modules/App/queries';

import AdminUi from './Admin.ui';

import { useRenewalListQuery } from './queries';
import { useBlockListZeroMutation } from './mutations';

import './admin.scss';

export default function Admin() {
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();
  const blockListZeroMutation = useBlockListZeroMutation({
    onSuccess: handleLoginSuccess,
  });
  const listZeroStatusQuery = useListZeroStatusQuery();

  const [isListZeroBlocked, setIsListZeroBlocked] = useState(
    !listZeroStatusQuery.data?.data.data.enabled
  );
  const [shouldFetchRenewalList, setShouldFetchRenewalList] = useState(false);

  const renewalListQuery = useRenewalListQuery({
    enabled: shouldFetchRenewalList,
  });

  // Handlers
  function handleBlockListZeroToggle() {
    blockListZeroMutation.mutate(!isListZeroBlocked);
  }

  function handleLoginSuccess() {
    setIsListZeroBlocked(!isListZeroBlocked);

    listZeroStatusQuery.refetch();
  }

  function handleFetchRenewalList() {
    setShouldFetchRenewalList(true);
  }

  useEffect(() => {
    if (blockListZeroMutation.isError) {
      const errorMessage = extractErrorMessage(blockListZeroMutation.error);
      toastRef.current?.show({ severity: 'error', detail: errorMessage });
    }
  }, [blockListZeroMutation.isError]);

  const handlers = {
    handleBlockListZeroToggle,
    handleFetchRenewalList,
  };

  return (
    <AdminUi
      isLoading={
        (renewalListQuery.isLoading && renewalListQuery.isInitialLoading) ||
        blockListZeroMutation.isLoading
      }
      isListZeroBlocked={isListZeroBlocked}
      renewalList={renewalListQuery.data?.data.data}
      handlers={handlers}
    />
  );
}
