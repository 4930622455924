import {
  InitAppFunction,
  AuthenticateUserFunction,
  UnAuthenticateUserFunction,
  AuthDataObject,
  InitAppInterface,
  AuthenticateUserInterface,
  UnAuthenticateUserInterface,
} from './store.types';
import {
  INIT_APP,
  AUTHENTICATE_USER,
  UNAUTHENTICATE_USER,
} from './action-types';

export const initApp: InitAppFunction = (locale: string): InitAppInterface => ({
  type: INIT_APP,
  locale,
});

export const authenticateUser: AuthenticateUserFunction = ({
  accessToken,
  refreshToken,
}: AuthDataObject): AuthenticateUserInterface => ({
  type: AUTHENTICATE_USER,
  accessToken,
  refreshToken,
});

export const unAuthenticateUser: UnAuthenticateUserFunction =
  (): UnAuthenticateUserInterface => ({
    type: UNAUTHENTICATE_USER,
  });
