import { Dialog } from 'components';

import { humanReadable } from 'utils';

import { InvoiceInfoDialogProps } from './InvoiceInfoDialog.types';

import './invoice-info-dialog.scss';

export default function InvoiceInfoDialog({
  onHide,
  footer,
  visible,
  invoiceInfo,
}: InvoiceInfoDialogProps) {
  const {
    azadea_rejectionid,
    azadea_rejectiondescription,
    azadea_rejectioniddescription,
    ...others
  } = invoiceInfo;
  return (
    <Dialog
      header="Invoice Details"
      footer={footer}
      onHide={onHide}
      visible={visible}
      maximizable
      className="invoice-info-dialog"
      style={{ width: '40vw' }}
    >
      {Object.keys(others).map((key) => {
        return (
          <div className="invoice-info-dialog__label-value-pairs flex flex-justify-content-space-between">
            <div className="invoice-info-dialog__label">{key}:</div>

            <div className="invoice-info-dialog__value">
              {key === 'updated' ||
              key === 'created' ||
              key === '_fivetran_synced'
                ? humanReadable(invoiceInfo[key])
                : //  @ts-ignore
                  invoiceInfo[key]}
            </div>
          </div>
        );
      })}
    </Dialog>
  );
}
