import axios from 'axios';

import { enums } from 'constants/index';

import {
  getAccessToken,
  getRefreshToken,
  getLocale,
  setAccessToken,
  clearSession,
  getUYTag,
} from './local-store';

import { Headers, IRefreshTokenApiResponse } from './types';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

let isTokenRefreshing = false;
let refreshSubscribers: any = [];

axiosInstance.interceptors.request.use(
  (request) => request,
  (error) => {
    return Promise.reject(new Error(error.response));
  }
);

function subscribeTokenRefresh(callback: any) {
  refreshSubscribers.push(callback);
}

function onTokenRefreshed(token: string) {
  refreshSubscribers.map((callback: any) => callback(token));
}

function api() {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const locale = getLocale();

  let headers: Headers = {
    'Content-Type': 'application/json',
    Locale: locale ? locale : enums.locales.en_US,
  };

  if (accessToken)
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
      uytag: getUYTag() || '',
    };

  if (refreshToken)
    headers = {
      ...headers,
      'session-user': refreshToken,
    };

  axiosInstance.defaults.headers.common = { ...headers };

  axiosInstance.interceptors.request.use(
    (request) => request,
    (error) => {
      return Promise.reject(new Error(error.response));
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;

          return refreshTokenApi(originalRequest);
        } else {
          return new Promise((resolve) => {
            subscribeTokenRefresh((token: string) => {
              originalRequest.headers['Authorization'] = `Bearer ${token}`;
              resolve(axiosInstance(originalRequest));
            });
          });
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

async function refreshTokenApi(originalRequest: any) {
  try {
    const refreshToken = getRefreshToken();

    const {
      data: {
        data: { token: newToken },
      },
    } = await axios.post<IRefreshTokenApiResponse>(
      `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
      {
        refresh_token: refreshToken,
      }
    );

    axiosInstance.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${newToken}`;

    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

    onTokenRefreshed(newToken);

    setAccessToken(newToken);

    return axiosInstance(originalRequest);
  } catch (error) {
    clearSession();

    window.location.href = '/logout';
  } finally {
    isTokenRefreshing = false;
  }
}

export default api;
