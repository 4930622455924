import Cookies from 'js-cookie';

export const setRole = (role: any) => {
  sessionStorage.setItem('role', JSON.stringify(role));
};

export const getRole = () => {
  return sessionStorage.getItem('role') || '';
};

export const getUserId = () => {
  const userId = sessionStorage.getItem('userId');
  return userId ? userId : '';
};

export const setUserId = (userId: string) => {
  sessionStorage.setItem('userId', userId);
};

export const getLocale = () => {
  return sessionStorage.getItem('locale');
};

export const setLocale = (locale: string) => {
  sessionStorage.setItem('locale', locale);
};

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};

export const setAccessToken = (accessToken: string, expires: number = 7) => {
  Cookies.set('accessToken', accessToken, { expires });
};

export const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};

export const setRefreshToken = (refreshToken: string, expires: number = 14) => {
  Cookies.set('refreshToken', refreshToken, { expires });
};

export const getPhoneNumber = () => {
  return {
    phoneNumber: Cookies.get('phoneNumber'),
    countryCode: Cookies.get('countryCode'),
    callingCode: Cookies.get('callingCode'),
  };
};

export const setPhoneNumber = (
  { phoneNumber, countryCode, callingCode }: any,
  expires: number
) => {
  Cookies.set('phoneNumber', phoneNumber, { expires });
  Cookies.set('countryCode', countryCode, { expires });
  Cookies.set('callingCode', callingCode, { expires });
};

export const clearPhoneNumber = () => {
  Cookies.remove('phoneNumber');
  Cookies.remove('countryCode');
  Cookies.remove('callingCode');
};

export const clearSession = () => {
  Cookies.remove('accessToken');
  sessionStorage.removeItem('uyTag');
};

export const getUYTag = () => sessionStorage.getItem('uyTag');

export const setUYTag = (uyTag: any) => sessionStorage.setItem('uyTag', uyTag);
