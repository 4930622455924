import { ChangeEvent, useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes } from 'constants/index';
import { useExtractServerError } from 'hooks';
import { getGermanDate } from 'utils';

import HistoryLogsUi from './HistoryLogs.ui';
import { useListOneLogsData, useListTwoLogsData } from './queries';
import { useListOneLogsMutation, useListTwoLogsMutation } from './mutations';
import {
  HistoryLogsFilter,
  DataTableRowClickEvent,
  CalendarChangeEvent,
  HistoryLogsParams,
  DropdownChangeEvent,
  PaginatorPageChangeEvent,
} from './HistoryLogs.types';

export default function HistoryLogs() {
  //* Hooks init
  const toastRef = useRef<Toast>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: HistoryLogsParams = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    list: searchParams.get('list') || '1',
    employeeId: searchParams.get('employeeId') || '',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
  };

  //* Local State
  const [filter, setFilter] = useState<HistoryLogsFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    list: parseInt(params.list),
    employeeId: params.employeeId,
    dates: [new Date(params.startDate), new Date(params.endDate)],
  });

  const listOneLogsData = useListOneLogsData(params, {
    enabled: filter.list === 1,
  });
  const listTwoLogsData = useListTwoLogsData(params, {
    enabled: filter.list === 2 || filter.list === 3,
  });
  const listOneLogsMutation = useListOneLogsMutation({
    onSuccess: handleDownloadSuccess,
  });
  const listTwoLogsMutation = useListTwoLogsMutation({
    onSuccess: handleDownloadSuccess,
  });

  //* Handlers
  function handleChange({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    setFilter((filter) => ({
      ...filter,
      [name]: value,
    }));
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.employeeId.value.length > 0) {
      setSearchParams({
        ...params,
        employeeId: event.target.elements.employeeId.value.trim(),
      });
      setFilter((filter) => ({ ...filter, page: 1, rows: 50, first: 0 }));
    } else {
      const { employeeId, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      employeeId: event.target.elements.employeeId.value.trim(),
    }));
  }

  function handleListNumberChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      page: '1',
      list: event.value,
    });

    setFilter((filter) => ({
      ...filter,
      page: 1,
      list: event.value,
    }));
  }

  function handleRowClick(event: DataTableRowClickEvent) {
    if (filter.list === 1)
      return navigate(
        `${routes.listOne.index}/${event.data.ticketId}/${routes.studies}`
      );

    navigate(
      `${routes.listTwo.index}/${event.data.ticketId}/${routes.studies}`
    );
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...params,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleDateChange(event: CalendarChangeEvent) {
    if (Array.isArray(event.value)) {
      const startDate = !!event.value[0]
        ? getGermanDate(event.value[0].toISOString())
        : '';
      const endDate = !!event.value[1]
        ? getGermanDate(event.value[1].toISOString())
        : '';

      setSearchParams({
        ...params,
        startDate,
        endDate,
      });

      setFilter((filter) => ({
        ...filter,
        dates: event.value,
        page: 1,
        first: 0,
      }));
    }
  }

  function handleFilterClear() {
    setSearchParams({
      page: '1',
      rows: '50',
      first: '0',
      list: '1',
    });

    setFilter({
      page: 1,
      rows: 50,
      first: 0,
      dates: null,
      list: 1,
    });
  }

  function handleDownload() {
    if (filter.list === 1)
      listOneLogsMutation.mutate({ ...filter, exportable: true });
    else
      listTwoLogsMutation.mutate({
        ...filter,
        exportable: true,
      });
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  useEffect(() => {
    if (
      listOneLogsData.isError ||
      listTwoLogsData.isError ||
      listOneLogsMutation.isError ||
      listTwoLogsMutation.isError
    ) {
      const errorMessage = extractErrorMessage(
        listOneLogsData.error ||
          listTwoLogsData.error ||
          listOneLogsMutation.error ||
          listTwoLogsMutation.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [
    listOneLogsData.isError,
    listTwoLogsData.isError,
    listOneLogsMutation.isError,
    listTwoLogsMutation.isError,
  ]);

  const options = {
    listNumber: [
      {
        label: 'List 1',
        value: 1,
      },
      {
        label: 'List 2',
        value: 2,
      },
    ],
  };

  const data = {
    logs:
      listOneLogsData.data?.data.data || listTwoLogsData.data?.data.data || [],
  };

  const handlers = {
    handleChange,
    handleSubmitSearch,
    handleRowClick,
    handlePageChange,
    handleDownload,
    handleListNumberChange,
    handleDateChange,
    handleFilterClear,
  };

  return (
    <HistoryLogsUi
      isLoading={
        (listOneLogsData.isLoading && listOneLogsData.isInitialLoading) ||
        (listOneLogsData.isLoading && listTwoLogsData.isInitialLoading)
      }
      isExporting={
        listOneLogsMutation.isLoading || listTwoLogsMutation.isLoading
      }
      totalRecords={
        listOneLogsData.data?.data.total ||
        listTwoLogsData.data?.data.total ||
        0
      }
      listNumber={filter.list || 1}
      options={options}
      filter={filter}
      data={data}
      handlers={handlers}
    />
  );
}
