const columns = [
  {
    header: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    header: 'ID',
    field: '_id',
    sortable: true,
  },
  {
    header: 'Study ID',
    field: 'studyId',
    sortable: true,
  },
  {
    header: 'Status Description',
    field: 'statusDescription',
    sortable: true,
  },
  {
    header: 'status',
    field: 'status',
    sortable: true,
  },
  {
    header: 'Created Date',
    field: 'createdDate',
    sortable: true,
  },
];

export default columns;
