import ReactLoadingSkeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { TSkeletonLoader } from './types';
import { skeletonLoaderStyle } from './style';

import './style.scss';

const SkeletonLoader: TSkeletonLoader = (props) => {
  const {
    count = 1,
    extraClasses = '',
    isCircle = false,
    height,
    width,
  } = props;

  return (
    <ReactLoadingSkeleton
      width={width}
      height={height}
      circle={isCircle}
      count={count}
      className="skeleton-loader__item"
      containerClassName={skeletonLoaderStyle({ extraClasses })}
    />
  );
};

export default SkeletonLoader;
