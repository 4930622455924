import { Dialog } from 'components';

import { InvoiceApprovalDialogProps } from './InvoiceApprovalDialog.types';

export default function InvoiceApprovalDialog({
  onHide,
  footer,
  visible,
  selectedInvoices,
}: InvoiceApprovalDialogProps) {
  return (
    <Dialog
      header="Confirm approval"
      footer={footer}
      onHide={onHide}
      visible={visible}
      className="invoice-info"
      style={{ width: '30vw' }}
      position="top"
    >
      Are you Sure you want to approve the invoice number for the following
      employees{' '}
      <span className="invoice-number">
        {selectedInvoices.length > 1
          ? selectedInvoices
              .map((invoice) => invoice?.azadeaemployee_id)
              .join(', ')
          : selectedInvoices[0]?.azadeaemployee_id}
      </span>
      ?
    </Dialog>
  );
}
