import { useQuery } from '@tanstack/react-query';

import { fetchListSixApi } from '../api';

export default function useListSixQuery(params: any, options?: any) {
  return useQuery(
    ['list', 'six', params],
    () => fetchListSixApi(params),
    options
  );
}
