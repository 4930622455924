const columns = [
  {
    header: 'Field',
    field: 'field',
    sortable: true,
  },
  {
    header: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    header: 'Old value',
    field: 'oldValue',
    sortable: true,
  },
  {
    header: 'New value',
    field: 'newValue',
    sortable: true,
  },
];

export default columns;
