import { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useConfigQueries } from 'modules/App/queries';
import { useExtractServerError } from 'hooks';
import { getGermanDate } from 'utils';

import { useListTwoTicketsQuery } from './queries';
import { useListTwoMutation } from './mutations';

import ListTwoUi from './ListTwo.ui';

import {
  ListTwoFilter,
  ListTwoParams,
  PaginatorPageChangeEvent,
  DropdownChangeEvent,
  CalendarChangeEvent,
} from './ListTwo.types';

export default function ListTwo() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: ListTwoParams = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    ticketStatus: searchParams.get('ticketStatus') || 'All',
    ticketType: searchParams.get('ticketType') || 'All',
    employeeId: searchParams.get('employeeId') || '',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
  };

  //* Local State
  const [filter, setFilter] = useState<ListTwoFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    employeeId: params.employeeId,
    ticketStatus: params.ticketStatus,
    ticketType: params.ticketType,
    dates: [new Date(params.startDate), new Date(params.endDate)],
  });

  //* Queries & Mutations
  const [, endorsementConfig] = useConfigQueries();
  const listTwoTicketsQuery = useListTwoTicketsQuery(params);
  const listTwoMutation = useListTwoMutation({
    onSuccess: handleDownloadSuccess,
  });

  //* Handlers
  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setFilter((filter) => ({
      ...filter,
      employeeId: value,
    }));
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.employeeId.value.length > 0) {
      setSearchParams({
        ...params,
        page: '1',
        rows: '50',
        first: '0',
        employeeId: event.target.elements.employeeId.value.trim(),
      });
      setFilter((filter) => ({
        ...filter,
        page: 1,
        rows: 50,
        first: 0,
      }));
    } else {
      const { employeeId, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      employeeId: event.target.elements.employeeId.value,
    }));
  }

  function handleClearAllFilters() {
    setSearchParams({
      page: '1',
      rows: '50',
      first: '0',
      ticketStatus: 'All',
      ticketType: 'All',
    });

    setFilter({
      page: 1,
      rows: 50,
      first: 0,
      ticketStatus: 'All',
      ticketType: 'All',
      dates: null,
      employeeId: '',
    });
  }

  function handleTicketTypeChange(events: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      ticketType: events.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      ticketType: events.value,
      page: 1,
      first: 0,
    }));
  }

  function handleTicketStatusChange(events: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      ticketStatus: events.value,
      page: '1',
    });

    setFilter((filter) => ({
      ...filter,
      ticketStatus: events.value,
      page: 1,
      first: 0,
    }));
  }

  function handleDateChange(event: CalendarChangeEvent) {
    if (Array.isArray(event.value)) {
      const startDate = !!event.value[0]
        ? getGermanDate(event.value[0].toISOString())
        : '';
      const endDate = !!event.value[1]
        ? getGermanDate(event.value[1].toISOString())
        : '';

      setSearchParams({
        ...params,
        startDate,
        endDate,
      });

      setFilter((filter) => ({
        ...filter,
        dates: event.value,
        page: 1,
        first: 0,
      }));
    }
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...params,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleDownload() {
    listTwoMutation.mutate({ ...filter, exportable: true });
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  useEffect(() => {
    if (listTwoTicketsQuery.isError || listTwoMutation.isError) {
      const errorMessage = extractErrorMessage(
        listTwoTicketsQuery.error || listTwoMutation.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listTwoTicketsQuery.isError, listTwoMutation.isError]);

  const options = {
    ticketStatuses:
      endorsementConfig.data?.data.statuses.map(({ key, value }) => {
        if (key === 'All') {
          return {
            key,
            label: key,
            value: key,
          };
        }
        return {
          key: value,
          label: key,
          value,
        };
      }) || [],
    ticketTypes:
      endorsementConfig.data?.data.ticketsType.map(({ key, value }) => {
        if (key === 'All') {
          return {
            key,
            label: key,
            value: key,
          };
        }
        return {
          key: value,
          label: key,
          value,
        };
      }) || [],
  };

  const data = {
    tickets: listTwoTicketsQuery.data?.data.data || [],
  };

  const handlers = {
    handleTicketStatusChange,
    handleTicketTypeChange,
    handleDateChange,
    handlePageChange,
    handleClearAllFilters,
    handleChange,
    handleSubmitSearch,
    handleDownload,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListTwoUi
        isLoading={listTwoTicketsQuery.isLoading}
        isExporting={listTwoMutation.isLoading}
        totalRecords={listTwoTicketsQuery.data?.data.total || 0}
        options={options}
        filter={filter}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
