import { ChangeEvent, useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useConfigQueries } from 'modules/App/queries';
import { useExtractServerError } from 'hooks';

import { useListThreeDataQuery } from './queries';
import { useListThreeMutation } from './mutations';

import ListThreeUi from './ListThree.ui';

import {
  ListThreeFilter,
  ListThreeParams,
  DropdownChangeEvent,
  PaginatorPageChangeEvent,
} from './ListThree.types';

export default function ListThree() {
  //* Hooks init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: ListThreeParams = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    azadeaId: searchParams.get('azadeaId') || '',
    country: searchParams.get('country') || '',
  };

  //* Queries & Mutations
  const [, endorsementConfig] = useConfigQueries();
  const listThreeDataQuery = useListThreeDataQuery(params);
  const listOneMutation = useListThreeMutation({
    onSuccess: handleDownloadSuccess,
  });

  //* Local state
  const [filter, setFilter] = useState<ListThreeFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    azadeaId: params.azadeaId,
    country: params.country,
  });

  //* Handlers
  function handleInputChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    setFilter((filter) => ({
      ...filter,
      azadeaId: value.trim(),
    }));
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.azadeaId.value.length > 0) {
      setSearchParams({
        ...params,
        page: '1',
        rows: '50',
        first: '0',
        azadeaId: event.target.elements.azadeaId.value.trim(),
      });
      setFilter((filter) => ({
        ...filter,
        page: 1,
        rows: 50,
        first: 0,
      }));
    } else {
      const { azadeaId, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      azadeaId: event.target.elements.azadeaId.value,
    }));
  }

  function handleClearAllFilters() {
    setSearchParams({
      page: '1',
      first: '0',
      rows: '50',
      azadeaId: '',
      country: '',
    });

    setFilter({
      page: 1,
      rows: 50,
      first: 0,
      azadeaId: '',
      country: '',
    });
  }

  function handleCountryChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      country: event.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      country: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...params,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleDownload() {
    listOneMutation.mutate({ ...filter, exportable: true });
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  useEffect(() => {
    if (listThreeDataQuery.isError || listOneMutation.isError) {
      const errorMessage = extractErrorMessage(
        listThreeDataQuery.error || listOneMutation.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listThreeDataQuery.isError || listOneMutation.isError]);

  const options = {
    countries:
      endorsementConfig.data?.data.countries.map(({ _id, value }) => ({
        key: _id,
        label: value,
        value: value,
      })) || [],
  };

  const data = {
    list: listThreeDataQuery.data?.data.data || [],
  };

  const handlers = {
    handleInputChange,
    handleCountryChange,
    handleSubmitSearch,
    handleClearAllFilters,
    handleDownload,
    handlePageChange,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListThreeUi
        isLoading={listThreeDataQuery.isLoading}
        isExporting={listOneMutation.isLoading}
        totalRecords={listThreeDataQuery.data?.data.total || 0}
        options={options}
        filter={filter}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
