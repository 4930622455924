import { Component } from 'react';

import loadLineChart from './config';

interface Props {
  id: string;
  extraClass?: string;
  data: {}[];
  options: {};
}

class LineChart extends Component<Props> {
  componentDidMount() {
    const { id, data, options } = this.props;
    loadLineChart({ id, data, options });
  }

  render() {
    const { id, extraClass } = this.props;
    return <div id={id} className={extraClass}></div>;
  }
}
export default LineChart;
