import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { getCurrentYear } from 'utils';
import { locales } from 'constants/index';

import { IAuthenticationUiProps } from './Authentication.types';

import './authentication.scss';

export default function AuthenticationUi({ steps }: IAuthenticationUiProps) {
  const location = useLocation();

  return (
    <div
      className={classNames({
        'authentication flex flex-justify-content-center flex-align-items-center':
          true,
        'flex-column': !location.pathname.includes('/phone-register'),
      })}
    >
      <div
        className={classNames({
          authentication__steps: true,
          'authentication__steps--other':
            !location.pathname.includes('/phone-register'),
        })}
      >
        <div
          className={classNames({
            'authentication__content flex flex-column': true,
            'authentication__content--login':
              location.pathname.includes('/phone-register'),
            'authentication__content--other':
              !location.pathname.includes('/phone-register'),
          })}
        >
          <div className="authentication__logo"></div>
          {steps}

          {location.pathname.includes('/phone-register') && (
            <div className="authentication__footer">
              {locales.general.footer} {getCurrentYear()}
            </div>
          )}
        </div>
      </div>
      {location.pathname.includes('/phone-register') && (
        <div className="authentication__image"></div>
      )}
    </div>
  );
}
