import { InputText, Dropdown, Button, Spinner } from 'components';

import { Helpers } from 'utils';
import { locales } from 'constants/index';

import { ResetPinUiProps } from './ResetPin.types';

import './reset-pin.scss';

export default function ResetPinUi({
  isLoading,
  options,
  errors,
  data,
  handlers,
}: ResetPinUiProps) {
  const { formSubmit, formatPhoneNumber }: Helpers = new Helpers();

  if (isLoading) return <Spinner />;

  return (
    <form
      className="reset-pin-form formgrid align-items-end"
      onSubmit={formSubmit}
    >
      <div className="formgrid grid align-items-end">
        <div className="field sm:col-12 md:col-4">
          <Dropdown
            filter
            value={data.countryCode}
            name="countryCode"
            label={locales.general.mobileNumber}
            options={options.countries}
            onChange={handlers.handleCountryChange}
          />
        </div>

        <div className="field sm:col-12 md:col-8">
          <InputText
            name="phoneNumber"
            value={formatPhoneNumber(
              String(data.phoneNumber),
              data.countryCode
            )}
            placeholder={locales.general.phoneNumber}
            onChange={handlers.handleInputChange}
            onBlur={handlers.handleInputBlur}
            isError={errors.phoneNumber?.length > 0}
            // icon={{
            //   type: 'user circle',
            //   position: 'left',
            // }}
          />
        </div>
      </div>

      {errors.phoneNumber?.length > 0 && (
        <div className="form__error">{errors.phoneNumber}</div>
      )}

      <Button
        onClick={handlers.handleSubmitClick}
        disabled={isLoading}
        label={locales.general.requestOtp}
      />
    </form>
  );
}
