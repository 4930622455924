import { Link } from 'react-router-dom';

import { ListSixInfoDialog } from 'modules/ListSix/components';

import {
  DataTable,
  Dropdown,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
  Calendar,
  Chip,
} from 'components';

import { locales, routes } from 'constants/index';
import { humanReadable } from 'utils';

import { InvoiceInfoDialog } from '../components';

import columns from './columns';
import listSixColumns from './listSixColumns';

import {
  ListFiveOtherInvoicesListingUiProps,
  ListSixTicket,
} from './ListFiveOtherInvoicesListing.types';

function MoreDetailsBodyTemplate(handleShowListFiveRowDetails: any) {
  return function (row: any) {
    return (
      <div
        className="az-tickets__ListFive-details"
        role="presentation"
        onClick={handleShowListFiveRowDetails(row.clover_invoice)}
      >
        Details
      </div>
    );
  };
}

function LinkSixBodyTemplate(row: ListSixTicket) {
  return (
    <Link to={`${routes.listSix.index}/${row.endorsement_id}`}>Read More</Link>
  );
}

function ListSixMoreDetailsBodyTemplate(handleListSixInfoDialogShow: any) {
  return function (row: ListSixTicket) {
    return (
      <div
        className="az-tickets__listFive-details"
        role="presentation"
        onClick={handleListSixInfoDialogShow(row.id)}
      >
        Details
      </div>
    );
  };
}

function DateBodyTemplate(field: 'invoicedate' | 'created' | 'updated') {
  return function (row: ListSixTicket) {
    return humanReadable(row[field]);
  };
}

export default function ListFiveOtherInvoicesListingUi({
  isLoading,
  isListSixLoading,
  isListSixInfoVisible,
  isExporting,
  isInvoiceInfoVisible,
  totalRecords,
  listSixTotalRecords,
  options,
  filter,
  listSixFilter,
  data,
  handlers,
  expandedRows,
}: ListFiveOtherInvoicesListingUiProps) {
  if (!columns[0]?.expander)
    columns.unshift({
      expander: true,
    });

  function listSixTable() {
    return isListSixLoading ? (
      <SkeletonLoader height="10rem" />
    ) : (
      <>
        <DataTable
          className="list-six-table"
          totalRecords={listSixTotalRecords}
          dataKey="id"
          columns={listSixColumns.map((column) => {
            if (column.field === 'invoicedate' || column.field === 'updated') {
              return {
                ...column,
                body: DateBodyTemplate(column.field),
              };
            }

            if (column.field === 'difference_total') {
              return {
                ...column,
                body: (row: any) => {
                  return (
                    <Chip
                      style={{ backgroundColor: row.color_difference }}
                      label={
                        row.difference_total ? row.difference_total : 'N/A'
                      }
                    />
                  );
                },
              };
            }
            if (column.field === 'react_status') {
              return {
                ...column,
                body: (row: any) => {
                  if (row.azadea_rejectiondescription_list6)
                    return row.azadea_rejectiondescription_list6;
                  else if (row.azadea_forceapprovaluser_list6)
                    return 'Approved';
                  else return 'N/A';
                },
              };
            }

            if (column.field === 'details_link') {
              return {
                ...column,
                body: ListSixMoreDetailsBodyTemplate(
                  handlers.handleListSixInfoDialogShow
                ),
              };
            }

            return {
              ...column,
              body: (row: any) =>
                column.field && !row[column.field]
                  ? 'N/A'
                  : column.field && row[column.field],
            };
          })}
          value={data.listSixData}
        />

        {data.listSixData?.length > 0 &&
          (isListSixLoading ? (
            <SkeletonLoader height="3rem" width="40rem" />
          ) : (
            <Paginator
              first={listSixFilter.first}
              rows={listSixFilter.rows}
              totalRecords={listSixTotalRecords}
              rowsPerPageOptions={[10, 50, 100]}
              onPageChange={handlers.handleListSixPageChange}
            />
          ))}
      </>
    );
  }

  return (
    <div className="az-tickets">
      <div className="formgrid grid justify-content-end">
        <div className="field sm:col-6 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleClearFilter}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              className="az-tickets__download"
              disabled={!data.list || data.list.length === 0}
              onClick={handlers.handleDownload}
              loading={isExporting}
              label={locales.general.download}
            />
          )}
        </div>
      </div>

      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-2">
            <form onSubmit={handlers.handleSubmitSearch}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="invoiceNumber"
                    name="invoiceNumber"
                    placeholder={'Invoice number'}
                    value={filter.invoiceNumber || ''}
                    onChange={handlers.handleInputChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="endorsementStatus"
              name="endorsementStatus"
              placeholder={'Filter by endorsement status'}
              value={filter.endorsementStatus || ''}
              onChange={handlers.handlerEndorsementStatuses}
              options={options.endorsementStatuses}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="azadeaStatus"
              name="azadeaStatus"
              placeholder={'Filter by azadea status'}
              value={filter.azadeaStatus || ''}
              onChange={handlers.handleAzadeaStatusChange}
              options={options.azadeaStatuses}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="exceptionDescription"
              name="exceptionDescription"
              placeholder={'Filter by exception'}
              value={filter.exceptionId || ''}
              onChange={handlers.handleExceptionChange}
              options={options.exceptions}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="country"
              name="country"
              placeholder="Filter by country"
              value={filter.country}
              onChange={handlers.handleCountryChange}
              options={options.countries}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="4.4rem" />
          ) : (
            <Calendar
              placeholder="Filter by date"
              selectionMode="range"
              onChange={handlers.handleDateChange}
              value={filter.dates}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          expandedRows={expandedRows}
          rowExpansionTemplate={listSixTable}
          onRowExpand={handlers.handleRowExpand}
          onRowCollapse={handlers.handleRowCollapse}
          onRowToggle={handlers.handleRowToggle}
          dataKey="clover_invoice"
          columns={columns.map((column) => {
            if (
              column.field === 'created' ||
              column.field === 'updated' ||
              column.field === 'azadea_forceapprovaldate'
            ) {
              return {
                ...column,
                body: (row: any) =>
                  column.field && !row[column.field]
                    ? 'N/A'
                    : humanReadable(column.field && row[column.field]),
              };
            }

            if (column.field === 'more_details') {
              return {
                ...column,
                body: MoreDetailsBodyTemplate(
                  handlers.handleShowListFiveRowDetails
                ),
              };
            }

            if (column.field === 'list_6_details') {
              return {
                ...column,
                body: LinkSixBodyTemplate,
              };
            }

            if (column.expander) {
              return column;
            }

            return {
              ...column,
              body: (row: any) =>
                column.field && !row[column.field]
                  ? 'N/A'
                  : column.field && row[column.field],
            };
          })}
          value={data.list}
        />
      )}

      {data.list?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.page}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}

      {data.invoiceInfo && (
        <InvoiceInfoDialog
          invoiceInfo={data.invoiceInfo}
          visible={isInvoiceInfoVisible}
          onHide={handlers.handleHideListFiveRowDetails}
          footer={
            <Button
              className="modal__action modal__action--cancel"
              onClick={handlers.handleHideListFiveRowDetails}
              outlined
              label={locales.general.close}
            />
          }
        />
      )}

      {isListSixInfoVisible && (
        <ListSixInfoDialog
          id={data.listSixSelectedId}
          onClose={handlers.handleListSixInfoDialogClose}
        />
      )}
    </div>
  );
}
