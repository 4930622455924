import { Link } from 'react-router-dom';

import {
  DataTable,
  Dropdown,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
  Calendar,
} from 'components';

import { humanReadable, humanReadableGermanDate } from 'utils';
import { locales, routes } from 'constants/index';

import columns from './columns';
import {
  ListOneDeleteOpenPendingTicketConfirmationDialog,
  ListOneTransferDeletionTicketConfirmationDialog,
} from './components';
import { ListOneUiProps, ListOneTicket } from './ListOne.types';

import './list-one.scss';

function TypeBodyTemplate(row: ListOneTicket) {
  return (
    <span
      className={`colored-circle colored-circle--${row.type.toLowerCase()}`}
    >
      {row.type}
    </span>
  );
}

function dateBodyTemplate(field: string) {
  return function (row: ListOneTicket) {
    if (row[field as keyof ListOneTicket])
      return humanReadable(row[field as keyof ListOneTicket] as string);

    return 'N/A';
  };
}

function affectedDateTemplate(row: ListOneTicket) {
  if (row.affectedDate) return row.affectedDate;

  return 'N/A';
}

function LinkBodyTemplate(row: ListOneTicket) {
  return (
    <Link to={`${routes.listOne.index}/${row.id}`}>
      <div className="az-tickets__icon az-tickets__icon--arrow" />
    </Link>
  );
}

function DeleteOpenPendingButtonTemplate(deleteOpenPendingTicket: any) {
  return (row: ListOneTicket) => (
    <Button onClick={deleteOpenPendingTicket(row._id)} text>
      Delete
    </Button>
  );
}

export default function ListOneUi({
  isLoading,
  isExporting,
  isTransferDeletionTicketsConfirmDialogVisible,
  isDeleteOpenPendingTicketButtonVisible,
  openPendingTicketId,
  totalRecords,
  options,
  filter,
  data,
  handlers,
}: ListOneUiProps) {
  const getMappedColumns = (column: any) => {
    if (column.field === 'type') {
      return {
        ...column,
        body: TypeBodyTemplate,
      };
    }

    if (column.field === 'createdDate' || column.field === 'updatedDate') {
      return {
        ...column,
        body: dateBodyTemplate(column.field),
      };
    }

    if (column.field === 'affectedDate')
      return {
        ...column,
        body: affectedDateTemplate,
      };

    if (column.field === 'link') {
      return {
        ...column,
        body: LinkBodyTemplate,
      };
    }

    if (
      column.field === 'deleteOpenPendingButton' &&
      isDeleteOpenPendingTicketButtonVisible
    ) {
      return {
        ...column,
        body: DeleteOpenPendingButtonTemplate(
          handlers.handleDeleteOpenPendingTicketConfirmationDialogVisible
        ),
      };
    }

    return {
      ...column,
      body: (row: ListOneTicket) =>
        // @ts-ignore
        !row[column.field] ? 'N/A' : row[column.field],
    };
  };

  return (
    <div className="az-tickets">
      <div className="formgrid grid justify-content-end">
        <div className="field sm:col-6 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              disabled={!data.tickets || data.tickets.length === 0}
              onClick={handlers.handleDownloadClick}
              loading={isExporting}
              label={locales.general.download}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleFilterClear}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>
      </div>

      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3">
            <form onSubmit={handlers.handleSearchClick}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="employeeId"
                    name="employeeId"
                    placeholder={locales.general.employeeID}
                    value={filter.employeeId}
                    onChange={handlers.handleInputChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="ticketType"
              name="ticketType"
              placeholder={locales.general.filterByType}
              value={filter.ticketType ? filter.ticketType : ''}
              onChange={handlers.handleTicketTypeChange}
              options={options.ticketTypes}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="status"
              name="status"
              placeholder={locales.general.filterByStatus}
              value={filter.ticketStatus ? filter.ticketStatus : ''}
              onChange={handlers.handleTicketStatusChange}
              options={options.ticketStatuses}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Calendar
              placeholder="Filter by date"
              selectionMode="range"
              onChange={handlers.handleDateChange}
              value={filter.dates}
            />
          )}
        </div>

        <div className="field sm:col-12">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="statusCode"
              name="statusCode"
              placeholder={locales.general.filterByStatusCode}
              value={filter.statusCodes ? filter.statusCodes : ''}
              onChange={handlers.handleStatusCodeChange}
              options={options.statusCodes}
              // isMultiple
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          selectionMode="checkbox"
          dataKey="id"
          totalRecords={totalRecords}
          onSelectionChange={handlers.handleSelectionChange}
          selection={data.selectedTickets}
          value={data.tickets.map((ticket) => ({
            ...ticket,
            affectedDate: humanReadableGermanDate(
              ticket.affectedDate as string,
              'DD/MM/YYYY'
            ),
          }))}
          header={
            filter.ticketType === 'DE' && (
              <div className="az-tickets__actions flex flex-align-items-center">
                <Button
                  onClick={handlers.handleTransferDeletionTicketsClick}
                  label="Transfer"
                  disabled={data.selectedTickets.length === 0}
                  outlined
                />
              </div>
            )
          }
          columns={
            filter.ticketType === 'AD' &&
            (filter.ticketStatus === 'open-pending' ||
              filter.ticketStatus === 'open-error')
              ? [
                  {
                    selectionMode: 'multiple',
                    headerStyle: { width: '5rem' },
                  },
                  ...columns,
                ].map(getMappedColumns)
              : [
                  {
                    selectionMode: 'multiple',
                    headerStyle: { width: '5rem' },
                  },
                  ...columns.filter(
                    (column) =>
                      column.field && column.field !== 'deleteOpenPendingButton'
                  ),
                ].map(getMappedColumns)
          }
        />
      )}

      {data.tickets?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}

      <ListOneDeleteOpenPendingTicketConfirmationDialog
        visible={openPendingTicketId.length > 0}
        ticketId={openPendingTicketId}
        onHide={handlers.handleDeleteOpenPendingTicketConfirmationDialogHide}
        footer={
          <>
            <Button
              onClick={
                handlers.handleDeleteOpenPendingTicketConfirmationDialogHide
              }
              outlined
              label={locales.general.close}
            />

            <Button
              onClick={handlers.handleDeleteOpenPendingTicketClick}
              label={'Confirm'}
            />
          </>
        }
      />

      <ListOneTransferDeletionTicketConfirmationDialog
        visible={isTransferDeletionTicketsConfirmDialogVisible}
        employeeIds={data.selectedTickets.map((ticket) => ticket.employeeId)}
        onHide={handlers.handleConfirmTransferDeletionTicketsDialogHide}
        footer={
          <>
            <Button
              onClick={handlers.handleConfirmTransferDeletionTicketsDialogHide}
              outlined
              label={locales.general.close}
            />

            <Button
              onClick={handlers.handleConfirmTransferDeletionTicketsClick}
              label={'Confirm'}
            />
          </>
        }
      />
    </div>
  );
}
