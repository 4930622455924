const enums = {
  locales: {
    en_US: 'en-US',
    ar_AE: 'ar-AE',
  },
  countryCodes: {
    lb: 'LBN',
  },
  callingCodes: {
    lb: 961,
  },
  app: {
    status: {
      none: 'NONE',
      loaded: 'LOADED',
      authenticated: 'AUTHENTICATED',
      unAuthenticated: 'UNAUTHENTICATED',
    },
  },
  statusCodes: {
    forbidden: 403,
    notFound: 404,
    conflict: 409,
  },
};

export default enums;
