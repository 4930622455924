import {
  InputSwitch as PrimeReactInputSwitch,
  InputSwitchProps,
} from 'primereact/inputswitch';

export default function InputSwitch({
  checked,
  onChange,
  className,
  disabled,
}: InputSwitchProps) {
  return (
    <PrimeReactInputSwitch
      className={className}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
