import { useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes } from 'constants/index';
import { useExtractServerError } from 'hooks';

import { useListOneTicketStudiesQuery } from '../queries';

import ListOneTicketStudiesUi from './ListOneTicketStudies.ui';
import { DataTableRowClickEvent } from './ListOneTicketStudies.types';

export default function ListOneStudies() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const navigate = useNavigate();
  const { ticketId = '' } = useParams();
  const { extractErrorMessage } = useExtractServerError();

  //* Queries
  const listOneTicketStudiesQuery = useListOneTicketStudiesQuery(ticketId, {
    enabled: ticketId.length > 0,
  });

  //* Handlers
  function handleRowClick(event: DataTableRowClickEvent) {
    navigate(
      `${routes.listOne.index}/${ticketId}/${routes.studies}/${event.data._id}`
    );
  }

  useEffect(() => {
    if (listOneTicketStudiesQuery.isError) {
      const errorMessage = extractErrorMessage(listOneTicketStudiesQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listOneTicketStudiesQuery.isError]);

  const data = {
    studies: listOneTicketStudiesQuery.data?.data.data[0].studies || [],
  };

  const handlers = {
    handleRowClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListOneTicketStudiesUi
        isLoading={listOneTicketStudiesQuery.isLoading}
        totalRecords={
          listOneTicketStudiesQuery.data?.data.data[0].studies.length || 0
        }
        data={data}
        handlers={handlers}
      />
    </>
  );
}
