// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/right-arrow-red.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-two-tickets__icon {\n  display: flex;\n  width: 1.6rem;\n  height: 1.6rem;\n  background-size: cover;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}", "",{"version":3,"sources":["webpack://./src/modules/ListTwo/list-two.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,yDAAA;AAFJ","sourcesContent":["@import '../../styles/theme';\n\n.list-two-tickets {\n  &__icon {\n    display: flex;\n    width: 1.6rem;\n    height: 1.6rem;\n    background-size: cover;\n    background-image: url('../../assets/icons/right-arrow-red.svg');\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
