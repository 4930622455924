import useListTwoTicketsQuery from './useListTwoTicketsQuery';
import useListTwoTicketQuery from './useListTwoTicketQuery';
import useListTwoTicketHistoryQuery from './useListTwoTicketHistoryQuery';
import useListTwoTicketStudiesQuery from './useListTwoTicketStudiesQuery';

export {
  useListTwoTicketsQuery,
  useListTwoTicketQuery,
  useListTwoTicketHistoryQuery,
  useListTwoTicketStudiesQuery,
};
