import classNames from 'classnames';

import { ISkeletonLoaderStyleOptions } from './types';

const skeletonLoaderStyle = ({ extraClasses }: ISkeletonLoaderStyleOptions) =>
  classNames({
    'skeleton-loader': true,
    [extraClasses]: extraClasses.length > 0,
  });

export { skeletonLoaderStyle };
