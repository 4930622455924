import { api } from 'utils';

export function generateRenewalList() {
  return api().get('/tickets/renewal');
}

export function blockListZeroSubmission(isBlocked: boolean) {
  return api().patch('/list/block', {
    block: isBlocked ? 1 : 0,
  });
}
