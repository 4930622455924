const countries = [
  {
    flag: 'https://restcountries.eu/data/afg.svg',
    name: 'Afghanistan',
    alpha2Code: 'AF',
    alpha3Code: 'AFG',
    callingCodes: ['93'],
  },
  {
    flag: 'https://restcountries.eu/data/ala.svg',
    name: 'Åland Islands',
    alpha2Code: 'AX',
    alpha3Code: 'ALA',
    callingCodes: ['358'],
  },
  {
    flag: 'https://restcountries.eu/data/alb.svg',
    name: 'Albania',
    alpha2Code: 'AL',
    alpha3Code: 'ALB',
    callingCodes: ['355'],
  },
  {
    flag: 'https://restcountries.eu/data/dza.svg',
    name: 'Algeria',
    alpha2Code: 'DZ',
    alpha3Code: 'DZA',
    callingCodes: ['213'],
  },
  {
    flag: 'https://restcountries.eu/data/asm.svg',
    name: 'American Samoa',
    alpha2Code: 'AS',
    alpha3Code: 'ASM',
    callingCodes: ['1684'],
  },
  {
    flag: 'https://restcountries.eu/data/and.svg',
    name: 'Andorra',
    alpha2Code: 'AD',
    alpha3Code: 'AND',
    callingCodes: ['376'],
  },
  {
    flag: 'https://restcountries.eu/data/ago.svg',
    name: 'Angola',
    alpha2Code: 'AO',
    alpha3Code: 'AGO',
    callingCodes: ['244'],
  },
  {
    flag: 'https://restcountries.eu/data/aia.svg',
    name: 'Anguilla',
    alpha2Code: 'AI',
    alpha3Code: 'AIA',
    callingCodes: ['1264'],
  },
  {
    flag: 'https://restcountries.eu/data/ata.svg',
    name: 'Antarctica',
    alpha2Code: 'AQ',
    alpha3Code: 'ATA',
    callingCodes: ['672'],
  },
  {
    flag: 'https://restcountries.eu/data/atg.svg',
    name: 'Antigua and Barbuda',
    alpha2Code: 'AG',
    alpha3Code: 'ATG',
    callingCodes: ['1268'],
  },
  {
    flag: 'https://restcountries.eu/data/arg.svg',
    name: 'Argentina',
    alpha2Code: 'AR',
    alpha3Code: 'ARG',
    callingCodes: ['54'],
  },
  {
    flag: 'https://restcountries.eu/data/arm.svg',
    name: 'Armenia',
    alpha2Code: 'AM',
    alpha3Code: 'ARM',
    callingCodes: ['374'],
  },
  {
    flag: 'https://restcountries.eu/data/abw.svg',
    name: 'Aruba',
    alpha2Code: 'AW',
    alpha3Code: 'ABW',
    callingCodes: ['297'],
  },
  {
    flag: 'https://restcountries.eu/data/aus.svg',
    name: 'Australia',
    alpha2Code: 'AU',
    alpha3Code: 'AUS',
    callingCodes: ['61'],
  },
  {
    flag: 'https://restcountries.eu/data/aut.svg',
    name: 'Austria',
    alpha2Code: 'AT',
    alpha3Code: 'AUT',
    callingCodes: ['43'],
  },
  {
    flag: 'https://restcountries.eu/data/aze.svg',
    name: 'Azerbaijan',
    alpha2Code: 'AZ',
    alpha3Code: 'AZE',
    callingCodes: ['994'],
  },
  {
    flag: 'https://restcountries.eu/data/bhs.svg',
    name: 'Bahamas',
    alpha2Code: 'BS',
    alpha3Code: 'BHS',
    callingCodes: ['1242'],
  },
  {
    flag: 'https://restcountries.eu/data/bhr.svg',
    name: 'Bahrain',
    alpha2Code: 'BH',
    alpha3Code: 'BHR',
    callingCodes: ['973'],
  },
  {
    flag: 'https://restcountries.eu/data/bgd.svg',
    name: 'Bangladesh',
    alpha2Code: 'BD',
    alpha3Code: 'BGD',
    callingCodes: ['880'],
  },
  {
    flag: 'https://restcountries.eu/data/brb.svg',
    name: 'Barbados',
    alpha2Code: 'BB',
    alpha3Code: 'BRB',
    callingCodes: ['1246'],
  },
  {
    flag: 'https://restcountries.eu/data/blr.svg',
    name: 'Belarus',
    alpha2Code: 'BY',
    alpha3Code: 'BLR',
    callingCodes: ['375'],
  },
  {
    flag: 'https://restcountries.eu/data/bel.svg',
    name: 'Belgium',
    alpha2Code: 'BE',
    alpha3Code: 'BEL',
    callingCodes: ['32'],
  },
  {
    flag: 'https://restcountries.eu/data/blz.svg',
    name: 'Belize',
    alpha2Code: 'BZ',
    alpha3Code: 'BLZ',
    callingCodes: ['501'],
  },
  {
    flag: 'https://restcountries.eu/data/ben.svg',
    name: 'Benin',
    alpha2Code: 'BJ',
    alpha3Code: 'BEN',
    callingCodes: ['229'],
  },
  {
    flag: 'https://restcountries.eu/data/bmu.svg',
    name: 'Bermuda',
    alpha2Code: 'BM',
    alpha3Code: 'BMU',
    callingCodes: ['1441'],
  },
  {
    flag: 'https://restcountries.eu/data/btn.svg',
    name: 'Bhutan',
    alpha2Code: 'BT',
    alpha3Code: 'BTN',
    callingCodes: ['975'],
  },
  {
    flag: 'https://restcountries.eu/data/bol.svg',
    name: 'Bolivia (Plurinational State of)',
    alpha2Code: 'BO',
    alpha3Code: 'BOL',
    callingCodes: ['591'],
  },
  {
    flag: 'https://restcountries.eu/data/bes.svg',
    name: 'Bonaire, Sint Eustatius and Saba',
    alpha2Code: 'BQ',
    alpha3Code: 'BES',
    callingCodes: ['5997'],
  },
  {
    flag: 'https://restcountries.eu/data/bih.svg',
    name: 'Bosnia and Herzegovina',
    alpha2Code: 'BA',
    alpha3Code: 'BIH',
    callingCodes: ['387'],
  },
  {
    flag: 'https://restcountries.eu/data/bwa.svg',
    name: 'Botswana',
    alpha2Code: 'BW',
    alpha3Code: 'BWA',
    callingCodes: ['267'],
  },
  {
    flag: 'https://restcountries.eu/data/bvt.svg',
    name: 'Bouvet Island',
    alpha2Code: 'BV',
    alpha3Code: 'BVT',
    callingCodes: [''],
  },
  {
    flag: 'https://restcountries.eu/data/bra.svg',
    name: 'Brazil',
    alpha2Code: 'BR',
    alpha3Code: 'BRA',
    callingCodes: ['55'],
  },
  {
    flag: 'https://restcountries.eu/data/iot.svg',
    name: 'British Indian Ocean Territory',
    alpha2Code: 'IO',
    alpha3Code: 'IOT',
    callingCodes: ['246'],
  },
  {
    flag: 'https://restcountries.eu/data/umi.svg',
    name: 'United States Minor Outlying Islands',
    alpha2Code: 'UM',
    alpha3Code: 'UMI',
    callingCodes: [''],
  },
  {
    flag: 'https://restcountries.eu/data/vgb.svg',
    name: 'Virgin Islands (British)',
    alpha2Code: 'VG',
    alpha3Code: 'VGB',
    callingCodes: ['1284'],
  },
  {
    flag: 'https://restcountries.eu/data/vir.svg',
    name: 'Virgin Islands (U.S.)',
    alpha2Code: 'VI',
    alpha3Code: 'VIR',
    callingCodes: ['1 340'],
  },
  {
    flag: 'https://restcountries.eu/data/brn.svg',
    name: 'Brunei Darussalam',
    alpha2Code: 'BN',
    alpha3Code: 'BRN',
    callingCodes: ['673'],
  },
  {
    flag: 'https://restcountries.eu/data/bgr.svg',
    name: 'Bulgaria',
    alpha2Code: 'BG',
    alpha3Code: 'BGR',
    callingCodes: ['359'],
  },
  {
    flag: 'https://restcountries.eu/data/bfa.svg',
    name: 'Burkina Faso',
    alpha2Code: 'BF',
    alpha3Code: 'BFA',
    callingCodes: ['226'],
  },
  {
    flag: 'https://restcountries.eu/data/bdi.svg',
    name: 'Burundi',
    alpha2Code: 'BI',
    alpha3Code: 'BDI',
    callingCodes: ['257'],
  },
  {
    flag: 'https://restcountries.eu/data/khm.svg',
    name: 'Cambodia',
    alpha2Code: 'KH',
    alpha3Code: 'KHM',
    callingCodes: ['855'],
  },
  {
    flag: 'https://restcountries.eu/data/cmr.svg',
    name: 'Cameroon',
    alpha2Code: 'CM',
    alpha3Code: 'CMR',
    callingCodes: ['237'],
  },
  {
    flag: 'https://restcountries.eu/data/can.svg',
    name: 'Canada',
    alpha2Code: 'CA',
    alpha3Code: 'CAN',
    callingCodes: ['1'],
  },
  {
    flag: 'https://restcountries.eu/data/cpv.svg',
    name: 'Cabo Verde',
    alpha2Code: 'CV',
    alpha3Code: 'CPV',
    callingCodes: ['238'],
  },
  {
    flag: 'https://restcountries.eu/data/cym.svg',
    name: 'Cayman Islands',
    alpha2Code: 'KY',
    alpha3Code: 'CYM',
    callingCodes: ['1345'],
  },
  {
    flag: 'https://restcountries.eu/data/caf.svg',
    name: 'Central African Republic',
    alpha2Code: 'CF',
    alpha3Code: 'CAF',
    callingCodes: ['236'],
  },
  {
    flag: 'https://restcountries.eu/data/tcd.svg',
    name: 'Chad',
    alpha2Code: 'TD',
    alpha3Code: 'TCD',
    callingCodes: ['235'],
  },
  {
    flag: 'https://restcountries.eu/data/chl.svg',
    name: 'Chile',
    alpha2Code: 'CL',
    alpha3Code: 'CHL',
    callingCodes: ['56'],
  },
  {
    flag: 'https://restcountries.eu/data/chn.svg',
    name: 'China',
    alpha2Code: 'CN',
    alpha3Code: 'CHN',
    callingCodes: ['86'],
  },
  {
    flag: 'https://restcountries.eu/data/cxr.svg',
    name: 'Christmas Island',
    alpha2Code: 'CX',
    alpha3Code: 'CXR',
    callingCodes: ['61'],
  },
  {
    flag: 'https://restcountries.eu/data/cck.svg',
    name: 'Cocos (Keeling) Islands',
    alpha2Code: 'CC',
    alpha3Code: 'CCK',
    callingCodes: ['61'],
  },
  {
    flag: 'https://restcountries.eu/data/col.svg',
    name: 'Colombia',
    alpha2Code: 'CO',
    alpha3Code: 'COL',
    callingCodes: ['57'],
  },
  {
    flag: 'https://restcountries.eu/data/com.svg',
    name: 'Comoros',
    alpha2Code: 'KM',
    alpha3Code: 'COM',
    callingCodes: ['269'],
  },
  {
    flag: 'https://restcountries.eu/data/cog.svg',
    name: 'Congo',
    alpha2Code: 'CG',
    alpha3Code: 'COG',
    callingCodes: ['242'],
  },
  {
    flag: 'https://restcountries.eu/data/cod.svg',
    name: 'Congo (Democratic Republic of the)',
    alpha2Code: 'CD',
    alpha3Code: 'COD',
    callingCodes: ['243'],
  },
  {
    flag: 'https://restcountries.eu/data/cok.svg',
    name: 'Cook Islands',
    alpha2Code: 'CK',
    alpha3Code: 'COK',
    callingCodes: ['682'],
  },
  {
    flag: 'https://restcountries.eu/data/cri.svg',
    name: 'Costa Rica',
    alpha2Code: 'CR',
    alpha3Code: 'CRI',
    callingCodes: ['506'],
  },
  {
    flag: 'https://restcountries.eu/data/hrv.svg',
    name: 'Croatia',
    alpha2Code: 'HR',
    alpha3Code: 'HRV',
    callingCodes: ['385'],
  },
  {
    flag: 'https://restcountries.eu/data/cub.svg',
    name: 'Cuba',
    alpha2Code: 'CU',
    alpha3Code: 'CUB',
    callingCodes: ['53'],
  },
  {
    flag: 'https://restcountries.eu/data/cuw.svg',
    name: 'Curaçao',
    alpha2Code: 'CW',
    alpha3Code: 'CUW',
    callingCodes: ['599'],
  },
  {
    flag: 'https://restcountries.eu/data/cyp.svg',
    name: 'Cyprus',
    alpha2Code: 'CY',
    alpha3Code: 'CYP',
    callingCodes: ['357'],
  },
  {
    flag: 'https://restcountries.eu/data/cze.svg',
    name: 'Czech Republic',
    alpha2Code: 'CZ',
    alpha3Code: 'CZE',
    callingCodes: ['420'],
  },
  {
    flag: 'https://restcountries.eu/data/dnk.svg',
    name: 'Denmark',
    alpha2Code: 'DK',
    alpha3Code: 'DNK',
    callingCodes: ['45'],
  },
  {
    flag: 'https://restcountries.eu/data/dji.svg',
    name: 'Djibouti',
    alpha2Code: 'DJ',
    alpha3Code: 'DJI',
    callingCodes: ['253'],
  },
  {
    flag: 'https://restcountries.eu/data/dma.svg',
    name: 'Dominica',
    alpha2Code: 'DM',
    alpha3Code: 'DMA',
    callingCodes: ['1767'],
  },
  {
    flag: 'https://restcountries.eu/data/dom.svg',
    name: 'Dominican Republic',
    alpha2Code: 'DO',
    alpha3Code: 'DOM',
    callingCodes: ['1809', '1829', '1849'],
  },
  {
    flag: 'https://restcountries.eu/data/ecu.svg',
    name: 'Ecuador',
    alpha2Code: 'EC',
    alpha3Code: 'ECU',
    callingCodes: ['593'],
  },
  {
    flag: 'https://restcountries.eu/data/egy.svg',
    name: 'Egypt',
    alpha2Code: 'EG',
    alpha3Code: 'EGY',
    callingCodes: ['20'],
  },
  {
    flag: 'https://restcountries.eu/data/slv.svg',
    name: 'El Salvador',
    alpha2Code: 'SV',
    alpha3Code: 'SLV',
    callingCodes: ['503'],
  },
  {
    flag: 'https://restcountries.eu/data/gnq.svg',
    name: 'Equatorial Guinea',
    alpha2Code: 'GQ',
    alpha3Code: 'GNQ',
    callingCodes: ['240'],
  },
  {
    flag: 'https://restcountries.eu/data/eri.svg',
    name: 'Eritrea',
    alpha2Code: 'ER',
    alpha3Code: 'ERI',
    callingCodes: ['291'],
  },
  {
    flag: 'https://restcountries.eu/data/est.svg',
    name: 'Estonia',
    alpha2Code: 'EE',
    alpha3Code: 'EST',
    callingCodes: ['372'],
  },
  {
    flag: 'https://restcountries.eu/data/eth.svg',
    name: 'Ethiopia',
    alpha2Code: 'ET',
    alpha3Code: 'ETH',
    callingCodes: ['251'],
  },
  {
    flag: 'https://restcountries.eu/data/flk.svg',
    name: 'Falkland Islands (Malvinas)',
    alpha2Code: 'FK',
    alpha3Code: 'FLK',
    callingCodes: ['500'],
  },
  {
    flag: 'https://restcountries.eu/data/fro.svg',
    name: 'Faroe Islands',
    alpha2Code: 'FO',
    alpha3Code: 'FRO',
    callingCodes: ['298'],
  },
  {
    flag: 'https://restcountries.eu/data/fji.svg',
    name: 'Fiji',
    alpha2Code: 'FJ',
    alpha3Code: 'FJI',
    callingCodes: ['679'],
  },
  {
    flag: 'https://restcountries.eu/data/fin.svg',
    name: 'Finland',
    alpha2Code: 'FI',
    alpha3Code: 'FIN',
    callingCodes: ['358'],
  },
  {
    flag: 'https://restcountries.eu/data/fra.svg',
    name: 'France',
    alpha2Code: 'FR',
    alpha3Code: 'FRA',
    callingCodes: ['33'],
  },
  {
    flag: 'https://restcountries.eu/data/guf.svg',
    name: 'French Guiana',
    alpha2Code: 'GF',
    alpha3Code: 'GUF',
    callingCodes: ['594'],
  },
  {
    flag: 'https://restcountries.eu/data/pyf.svg',
    name: 'French Polynesia',
    alpha2Code: 'PF',
    alpha3Code: 'PYF',
    callingCodes: ['689'],
  },
  {
    flag: 'https://restcountries.eu/data/atf.svg',
    name: 'French Southern Territories',
    alpha2Code: 'TF',
    alpha3Code: 'ATF',
    callingCodes: [''],
  },
  {
    flag: 'https://restcountries.eu/data/gab.svg',
    name: 'Gabon',
    alpha2Code: 'GA',
    alpha3Code: 'GAB',
    callingCodes: ['241'],
  },
  {
    flag: 'https://restcountries.eu/data/gmb.svg',
    name: 'Gambia',
    alpha2Code: 'GM',
    alpha3Code: 'GMB',
    callingCodes: ['220'],
  },
  {
    flag: 'https://restcountries.eu/data/geo.svg',
    name: 'Georgia',
    alpha2Code: 'GE',
    alpha3Code: 'GEO',
    callingCodes: ['995'],
  },
  {
    flag: 'https://restcountries.eu/data/deu.svg',
    name: 'Germany',
    alpha2Code: 'DE',
    alpha3Code: 'DEU',
    callingCodes: ['49'],
  },
  {
    flag: 'https://restcountries.eu/data/gha.svg',
    name: 'Ghana',
    alpha2Code: 'GH',
    alpha3Code: 'GHA',
    callingCodes: ['233'],
  },
  {
    flag: 'https://restcountries.eu/data/gib.svg',
    name: 'Gibraltar',
    alpha2Code: 'GI',
    alpha3Code: 'GIB',
    callingCodes: ['350'],
  },
  {
    flag: 'https://restcountries.eu/data/grc.svg',
    name: 'Greece',
    alpha2Code: 'GR',
    alpha3Code: 'GRC',
    callingCodes: ['30'],
  },
  {
    flag: 'https://restcountries.eu/data/grl.svg',
    name: 'Greenland',
    alpha2Code: 'GL',
    alpha3Code: 'GRL',
    callingCodes: ['299'],
  },
  {
    flag: 'https://restcountries.eu/data/grd.svg',
    name: 'Grenada',
    alpha2Code: 'GD',
    alpha3Code: 'GRD',
    callingCodes: ['1473'],
  },
  {
    flag: 'https://restcountries.eu/data/glp.svg',
    name: 'Guadeloupe',
    alpha2Code: 'GP',
    alpha3Code: 'GLP',
    callingCodes: ['590'],
  },
  {
    flag: 'https://restcountries.eu/data/gum.svg',
    name: 'Guam',
    alpha2Code: 'GU',
    alpha3Code: 'GUM',
    callingCodes: ['1671'],
  },
  {
    flag: 'https://restcountries.eu/data/gtm.svg',
    name: 'Guatemala',
    alpha2Code: 'GT',
    alpha3Code: 'GTM',
    callingCodes: ['502'],
  },
  {
    flag: 'https://restcountries.eu/data/ggy.svg',
    name: 'Guernsey',
    alpha2Code: 'GG',
    alpha3Code: 'GGY',
    callingCodes: ['44'],
  },
  {
    flag: 'https://restcountries.eu/data/gin.svg',
    name: 'Guinea',
    alpha2Code: 'GN',
    alpha3Code: 'GIN',
    callingCodes: ['224'],
  },
  {
    flag: 'https://restcountries.eu/data/gnb.svg',
    name: 'Guinea-Bissau',
    alpha2Code: 'GW',
    alpha3Code: 'GNB',
    callingCodes: ['245'],
  },
  {
    flag: 'https://restcountries.eu/data/guy.svg',
    name: 'Guyana',
    alpha2Code: 'GY',
    alpha3Code: 'GUY',
    callingCodes: ['592'],
  },
  {
    flag: 'https://restcountries.eu/data/hti.svg',
    name: 'Haiti',
    alpha2Code: 'HT',
    alpha3Code: 'HTI',
    callingCodes: ['509'],
  },
  {
    flag: 'https://restcountries.eu/data/hmd.svg',
    name: 'Heard Island and McDonald Islands',
    alpha2Code: 'HM',
    alpha3Code: 'HMD',
    callingCodes: [''],
  },
  {
    flag: 'https://restcountries.eu/data/vat.svg',
    name: 'Holy See',
    alpha2Code: 'VA',
    alpha3Code: 'VAT',
    callingCodes: ['379'],
  },
  {
    flag: 'https://restcountries.eu/data/hnd.svg',
    name: 'Honduras',
    alpha2Code: 'HN',
    alpha3Code: 'HND',
    callingCodes: ['504'],
  },
  {
    flag: 'https://restcountries.eu/data/hkg.svg',
    name: 'Hong Kong',
    alpha2Code: 'HK',
    alpha3Code: 'HKG',
    callingCodes: ['852'],
  },
  {
    flag: 'https://restcountries.eu/data/hun.svg',
    name: 'Hungary',
    alpha2Code: 'HU',
    alpha3Code: 'HUN',
    callingCodes: ['36'],
  },
  {
    flag: 'https://restcountries.eu/data/isl.svg',
    name: 'Iceland',
    alpha2Code: 'IS',
    alpha3Code: 'ISL',
    callingCodes: ['354'],
  },
  {
    flag: 'https://restcountries.eu/data/ind.svg',
    name: 'India',
    alpha2Code: 'IN',
    alpha3Code: 'IND',
    callingCodes: ['91'],
  },
  {
    flag: 'https://restcountries.eu/data/idn.svg',
    name: 'Indonesia',
    alpha2Code: 'ID',
    alpha3Code: 'IDN',
    callingCodes: ['62'],
  },
  {
    flag: 'https://restcountries.eu/data/civ.svg',
    name: "Côte d'Ivoire",
    alpha2Code: 'CI',
    alpha3Code: 'CIV',
    callingCodes: ['225'],
  },
  {
    flag: 'https://restcountries.eu/data/irn.svg',
    name: 'Iran (Islamic Republic of)',
    alpha2Code: 'IR',
    alpha3Code: 'IRN',
    callingCodes: ['98'],
  },
  {
    flag: 'https://restcountries.eu/data/irq.svg',
    name: 'Iraq',
    alpha2Code: 'IQ',
    alpha3Code: 'IRQ',
    callingCodes: ['964'],
  },
  {
    flag: 'https://restcountries.eu/data/irl.svg',
    name: 'Ireland',
    alpha2Code: 'IE',
    alpha3Code: 'IRL',
    callingCodes: ['353'],
  },
  {
    flag: 'https://restcountries.eu/data/imn.svg',
    name: 'Isle of Man',
    alpha2Code: 'IM',
    alpha3Code: 'IMN',
    callingCodes: ['44'],
  },
  {
    flag: 'https://restcountries.eu/data/isr.svg',
    name: 'Israel',
    alpha2Code: 'IL',
    alpha3Code: 'ISR',
    callingCodes: ['972'],
  },
  {
    flag: 'https://restcountries.eu/data/ita.svg',
    name: 'Italy',
    alpha2Code: 'IT',
    alpha3Code: 'ITA',
    callingCodes: ['39'],
  },
  {
    flag: 'https://restcountries.eu/data/jam.svg',
    name: 'Jamaica',
    alpha2Code: 'JM',
    alpha3Code: 'JAM',
    callingCodes: ['1876'],
  },
  {
    flag: 'https://restcountries.eu/data/jpn.svg',
    name: 'Japan',
    alpha2Code: 'JP',
    alpha3Code: 'JPN',
    callingCodes: ['81'],
  },
  {
    flag: 'https://restcountries.eu/data/jey.svg',
    name: 'Jersey',
    alpha2Code: 'JE',
    alpha3Code: 'JEY',
    callingCodes: ['44'],
  },
  {
    flag: 'https://restcountries.eu/data/jor.svg',
    name: 'Jordan',
    alpha2Code: 'JO',
    alpha3Code: 'JOR',
    callingCodes: ['962'],
  },
  {
    flag: 'https://restcountries.eu/data/kaz.svg',
    name: 'Kazakhstan',
    alpha2Code: 'KZ',
    alpha3Code: 'KAZ',
    callingCodes: ['76', '77'],
  },
  {
    flag: 'https://restcountries.eu/data/ken.svg',
    name: 'Kenya',
    alpha2Code: 'KE',
    alpha3Code: 'KEN',
    callingCodes: ['254'],
  },
  {
    flag: 'https://restcountries.eu/data/kir.svg',
    name: 'Kiribati',
    alpha2Code: 'KI',
    alpha3Code: 'KIR',
    callingCodes: ['686'],
  },
  {
    flag: 'https://restcountries.eu/data/kwt.svg',
    name: 'Kuwait',
    alpha2Code: 'KW',
    alpha3Code: 'KWT',
    callingCodes: ['965'],
  },
  {
    flag: 'https://restcountries.eu/data/kgz.svg',
    name: 'Kyrgyzstan',
    alpha2Code: 'KG',
    alpha3Code: 'KGZ',
    callingCodes: ['996'],
  },
  {
    flag: 'https://restcountries.eu/data/lao.svg',
    name: "Lao People's Democratic Republic",
    alpha2Code: 'LA',
    alpha3Code: 'LAO',
    callingCodes: ['856'],
  },
  {
    flag: 'https://restcountries.eu/data/lva.svg',
    name: 'Latvia',
    alpha2Code: 'LV',
    alpha3Code: 'LVA',
    callingCodes: ['371'],
  },
  {
    flag: 'https://restcountries.eu/data/lbn.svg',
    name: 'Lebanon',
    alpha2Code: 'LB',
    alpha3Code: 'LBN',
    callingCodes: ['961'],
  },
  {
    flag: 'https://restcountries.eu/data/lso.svg',
    name: 'Lesotho',
    alpha2Code: 'LS',
    alpha3Code: 'LSO',
    callingCodes: ['266'],
  },
  {
    flag: 'https://restcountries.eu/data/lbr.svg',
    name: 'Liberia',
    alpha2Code: 'LR',
    alpha3Code: 'LBR',
    callingCodes: ['231'],
  },
  {
    flag: 'https://restcountries.eu/data/lby.svg',
    name: 'Libya',
    alpha2Code: 'LY',
    alpha3Code: 'LBY',
    callingCodes: ['218'],
  },
  {
    flag: 'https://restcountries.eu/data/lie.svg',
    name: 'Liechtenstein',
    alpha2Code: 'LI',
    alpha3Code: 'LIE',
    callingCodes: ['423'],
  },
  {
    flag: 'https://restcountries.eu/data/ltu.svg',
    name: 'Lithuania',
    alpha2Code: 'LT',
    alpha3Code: 'LTU',
    callingCodes: ['370'],
  },
  {
    flag: 'https://restcountries.eu/data/lux.svg',
    name: 'Luxembourg',
    alpha2Code: 'LU',
    alpha3Code: 'LUX',
    callingCodes: ['352'],
  },
  {
    flag: 'https://restcountries.eu/data/mac.svg',
    name: 'Macao',
    alpha2Code: 'MO',
    alpha3Code: 'MAC',
    callingCodes: ['853'],
  },
  {
    flag: 'https://restcountries.eu/data/mkd.svg',
    name: 'Macedonia (the former Yugoslav Republic of)',
    alpha2Code: 'MK',
    alpha3Code: 'MKD',
    callingCodes: ['389'],
  },
  {
    flag: 'https://restcountries.eu/data/mdg.svg',
    name: 'Madagascar',
    alpha2Code: 'MG',
    alpha3Code: 'MDG',
    callingCodes: ['261'],
  },
  {
    flag: 'https://restcountries.eu/data/mwi.svg',
    name: 'Malawi',
    alpha2Code: 'MW',
    alpha3Code: 'MWI',
    callingCodes: ['265'],
  },
  {
    flag: 'https://restcountries.eu/data/mys.svg',
    name: 'Malaysia',
    alpha2Code: 'MY',
    alpha3Code: 'MYS',
    callingCodes: ['60'],
  },
  {
    flag: 'https://restcountries.eu/data/mdv.svg',
    name: 'Maldives',
    alpha2Code: 'MV',
    alpha3Code: 'MDV',
    callingCodes: ['960'],
  },
  {
    flag: 'https://restcountries.eu/data/mli.svg',
    name: 'Mali',
    alpha2Code: 'ML',
    alpha3Code: 'MLI',
    callingCodes: ['223'],
  },
  {
    flag: 'https://restcountries.eu/data/mlt.svg',
    name: 'Malta',
    alpha2Code: 'MT',
    alpha3Code: 'MLT',
    callingCodes: ['356'],
  },
  {
    flag: 'https://restcountries.eu/data/mhl.svg',
    name: 'Marshall Islands',
    alpha2Code: 'MH',
    alpha3Code: 'MHL',
    callingCodes: ['692'],
  },
  {
    flag: 'https://restcountries.eu/data/mtq.svg',
    name: 'Martinique',
    alpha2Code: 'MQ',
    alpha3Code: 'MTQ',
    callingCodes: ['596'],
  },
  {
    flag: 'https://restcountries.eu/data/mrt.svg',
    name: 'Mauritania',
    alpha2Code: 'MR',
    alpha3Code: 'MRT',
    callingCodes: ['222'],
  },
  {
    flag: 'https://restcountries.eu/data/mus.svg',
    name: 'Mauritius',
    alpha2Code: 'MU',
    alpha3Code: 'MUS',
    callingCodes: ['230'],
  },
  {
    flag: 'https://restcountries.eu/data/myt.svg',
    name: 'Mayotte',
    alpha2Code: 'YT',
    alpha3Code: 'MYT',
    callingCodes: ['262'],
  },
  {
    flag: 'https://restcountries.eu/data/mex.svg',
    name: 'Mexico',
    alpha2Code: 'MX',
    alpha3Code: 'MEX',
    callingCodes: ['52'],
  },
  {
    flag: 'https://restcountries.eu/data/fsm.svg',
    name: 'Micronesia (Federated States of)',
    alpha2Code: 'FM',
    alpha3Code: 'FSM',
    callingCodes: ['691'],
  },
  {
    flag: 'https://restcountries.eu/data/mda.svg',
    name: 'Moldova (Republic of)',
    alpha2Code: 'MD',
    alpha3Code: 'MDA',
    callingCodes: ['373'],
  },
  {
    flag: 'https://restcountries.eu/data/mco.svg',
    name: 'Monaco',
    alpha2Code: 'MC',
    alpha3Code: 'MCO',
    callingCodes: ['377'],
  },
  {
    flag: 'https://restcountries.eu/data/mng.svg',
    name: 'Mongolia',
    alpha2Code: 'MN',
    alpha3Code: 'MNG',
    callingCodes: ['976'],
  },
  {
    flag: 'https://restcountries.eu/data/mne.svg',
    name: 'Montenegro',
    alpha2Code: 'ME',
    alpha3Code: 'MNE',
    callingCodes: ['382'],
  },
  {
    flag: 'https://restcountries.eu/data/msr.svg',
    name: 'Montserrat',
    alpha2Code: 'MS',
    alpha3Code: 'MSR',
    callingCodes: ['1664'],
  },
  {
    flag: 'https://restcountries.eu/data/mar.svg',
    name: 'Morocco',
    alpha2Code: 'MA',
    alpha3Code: 'MAR',
    callingCodes: ['212'],
  },
  {
    flag: 'https://restcountries.eu/data/moz.svg',
    name: 'Mozambique',
    alpha2Code: 'MZ',
    alpha3Code: 'MOZ',
    callingCodes: ['258'],
  },
  {
    flag: 'https://restcountries.eu/data/mmr.svg',
    name: 'Myanmar',
    alpha2Code: 'MM',
    alpha3Code: 'MMR',
    callingCodes: ['95'],
  },
  {
    flag: 'https://restcountries.eu/data/nam.svg',
    name: 'Namibia',
    alpha2Code: 'NA',
    alpha3Code: 'NAM',
    callingCodes: ['264'],
  },
  {
    flag: 'https://restcountries.eu/data/nru.svg',
    name: 'Nauru',
    alpha2Code: 'NR',
    alpha3Code: 'NRU',
    callingCodes: ['674'],
  },
  {
    flag: 'https://restcountries.eu/data/npl.svg',
    name: 'Nepal',
    alpha2Code: 'NP',
    alpha3Code: 'NPL',
    callingCodes: ['977'],
  },
  {
    flag: 'https://restcountries.eu/data/nld.svg',
    name: 'Netherlands',
    alpha2Code: 'NL',
    alpha3Code: 'NLD',
    callingCodes: ['31'],
  },
  {
    flag: 'https://restcountries.eu/data/ncl.svg',
    name: 'New Caledonia',
    alpha2Code: 'NC',
    alpha3Code: 'NCL',
    callingCodes: ['687'],
  },
  {
    flag: 'https://restcountries.eu/data/nzl.svg',
    name: 'New Zealand',
    alpha2Code: 'NZ',
    alpha3Code: 'NZL',
    callingCodes: ['64'],
  },
  {
    flag: 'https://restcountries.eu/data/nic.svg',
    name: 'Nicaragua',
    alpha2Code: 'NI',
    alpha3Code: 'NIC',
    callingCodes: ['505'],
  },
  {
    flag: 'https://restcountries.eu/data/ner.svg',
    name: 'Niger',
    alpha2Code: 'NE',
    alpha3Code: 'NER',
    callingCodes: ['227'],
  },
  {
    flag: 'https://restcountries.eu/data/nga.svg',
    name: 'Nigeria',
    alpha2Code: 'NG',
    alpha3Code: 'NGA',
    callingCodes: ['234'],
  },
  {
    flag: 'https://restcountries.eu/data/niu.svg',
    name: 'Niue',
    alpha2Code: 'NU',
    alpha3Code: 'NIU',
    callingCodes: ['683'],
  },
  {
    flag: 'https://restcountries.eu/data/nfk.svg',
    name: 'Norfolk Island',
    alpha2Code: 'NF',
    alpha3Code: 'NFK',
    callingCodes: ['672'],
  },
  {
    flag: 'https://restcountries.eu/data/prk.svg',
    name: "Korea (Democratic People's Republic of)",
    alpha2Code: 'KP',
    alpha3Code: 'PRK',
    callingCodes: ['850'],
  },
  {
    flag: 'https://restcountries.eu/data/mnp.svg',
    name: 'Northern Mariana Islands',
    alpha2Code: 'MP',
    alpha3Code: 'MNP',
    callingCodes: ['1670'],
  },
  {
    flag: 'https://restcountries.eu/data/nor.svg',
    name: 'Norway',
    alpha2Code: 'NO',
    alpha3Code: 'NOR',
    callingCodes: ['47'],
  },
  {
    flag: 'https://restcountries.eu/data/omn.svg',
    name: 'Oman',
    alpha2Code: 'OM',
    alpha3Code: 'OMN',
    callingCodes: ['968'],
  },
  {
    flag: 'https://restcountries.eu/data/pak.svg',
    name: 'Pakistan',
    alpha2Code: 'PK',
    alpha3Code: 'PAK',
    callingCodes: ['92'],
  },
  {
    flag: 'https://restcountries.eu/data/plw.svg',
    name: 'Palau',
    alpha2Code: 'PW',
    alpha3Code: 'PLW',
    callingCodes: ['680'],
  },
  {
    flag: 'https://restcountries.eu/data/pse.svg',
    name: 'Palestine, State of',
    alpha2Code: 'PS',
    alpha3Code: 'PSE',
    callingCodes: ['970'],
  },
  {
    flag: 'https://restcountries.eu/data/pan.svg',
    name: 'Panama',
    alpha2Code: 'PA',
    alpha3Code: 'PAN',
    callingCodes: ['507'],
  },
  {
    flag: 'https://restcountries.eu/data/png.svg',
    name: 'Papua New Guinea',
    alpha2Code: 'PG',
    alpha3Code: 'PNG',
    callingCodes: ['675'],
  },
  {
    flag: 'https://restcountries.eu/data/pry.svg',
    name: 'Paraguay',
    alpha2Code: 'PY',
    alpha3Code: 'PRY',
    callingCodes: ['595'],
  },
  {
    flag: 'https://restcountries.eu/data/per.svg',
    name: 'Peru',
    alpha2Code: 'PE',
    alpha3Code: 'PER',
    callingCodes: ['51'],
  },
  {
    flag: 'https://restcountries.eu/data/phl.svg',
    name: 'Philippines',
    alpha2Code: 'PH',
    alpha3Code: 'PHL',
    callingCodes: ['63'],
  },
  {
    flag: 'https://restcountries.eu/data/pcn.svg',
    name: 'Pitcairn',
    alpha2Code: 'PN',
    alpha3Code: 'PCN',
    callingCodes: ['64'],
  },
  {
    flag: 'https://restcountries.eu/data/pol.svg',
    name: 'Poland',
    alpha2Code: 'PL',
    alpha3Code: 'POL',
    callingCodes: ['48'],
  },
  {
    flag: 'https://restcountries.eu/data/prt.svg',
    name: 'Portugal',
    alpha2Code: 'PT',
    alpha3Code: 'PRT',
    callingCodes: ['351'],
  },
  {
    flag: 'https://restcountries.eu/data/pri.svg',
    name: 'Puerto Rico',
    alpha2Code: 'PR',
    alpha3Code: 'PRI',
    callingCodes: ['1787', '1939'],
  },
  {
    flag: 'https://restcountries.eu/data/qat.svg',
    name: 'Qatar',
    alpha2Code: 'QA',
    alpha3Code: 'QAT',
    callingCodes: ['974'],
  },
  {
    flag: 'https://restcountries.eu/data/kos.svg',
    name: 'Republic of Kosovo',
    alpha2Code: 'XK',
    alpha3Code: 'KOS',
    callingCodes: ['383'],
  },
  {
    flag: 'https://restcountries.eu/data/reu.svg',
    name: 'Réunion',
    alpha2Code: 'RE',
    alpha3Code: 'REU',
    callingCodes: ['262'],
  },
  {
    flag: 'https://restcountries.eu/data/rou.svg',
    name: 'Romania',
    alpha2Code: 'RO',
    alpha3Code: 'ROU',
    callingCodes: ['40'],
  },
  {
    flag: 'https://restcountries.eu/data/rus.svg',
    name: 'Russian Federation',
    alpha2Code: 'RU',
    alpha3Code: 'RUS',
    callingCodes: ['7'],
  },
  {
    flag: 'https://restcountries.eu/data/rwa.svg',
    name: 'Rwanda',
    alpha2Code: 'RW',
    alpha3Code: 'RWA',
    callingCodes: ['250'],
  },
  {
    flag: 'https://restcountries.eu/data/blm.svg',
    name: 'Saint Barthélemy',
    alpha2Code: 'BL',
    alpha3Code: 'BLM',
    callingCodes: ['590'],
  },
  {
    flag: 'https://restcountries.eu/data/shn.svg',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2Code: 'SH',
    alpha3Code: 'SHN',
    callingCodes: ['290'],
  },
  {
    flag: 'https://restcountries.eu/data/kna.svg',
    name: 'Saint Kitts and Nevis',
    alpha2Code: 'KN',
    alpha3Code: 'KNA',
    callingCodes: ['1869'],
  },
  {
    flag: 'https://restcountries.eu/data/lca.svg',
    name: 'Saint Lucia',
    alpha2Code: 'LC',
    alpha3Code: 'LCA',
    callingCodes: ['1758'],
  },
  {
    flag: 'https://restcountries.eu/data/maf.svg',
    name: 'Saint Martin (French part)',
    alpha2Code: 'MF',
    alpha3Code: 'MAF',
    callingCodes: ['590'],
  },
  {
    flag: 'https://restcountries.eu/data/spm.svg',
    name: 'Saint Pierre and Miquelon',
    alpha2Code: 'PM',
    alpha3Code: 'SPM',
    callingCodes: ['508'],
  },
  {
    flag: 'https://restcountries.eu/data/vct.svg',
    name: 'Saint Vincent and the Grenadines',
    alpha2Code: 'VC',
    alpha3Code: 'VCT',
    callingCodes: ['1784'],
  },
  {
    flag: 'https://restcountries.eu/data/wsm.svg',
    name: 'Samoa',
    alpha2Code: 'WS',
    alpha3Code: 'WSM',
    callingCodes: ['685'],
  },
  {
    flag: 'https://restcountries.eu/data/smr.svg',
    name: 'San Marino',
    alpha2Code: 'SM',
    alpha3Code: 'SMR',
    callingCodes: ['378'],
  },
  {
    flag: 'https://restcountries.eu/data/stp.svg',
    name: 'Sao Tome and Principe',
    alpha2Code: 'ST',
    alpha3Code: 'STP',
    callingCodes: ['239'],
  },
  {
    flag: 'https://restcountries.eu/data/sau.svg',
    name: 'Saudi Arabia',
    alpha2Code: 'SA',
    alpha3Code: 'SAU',
    callingCodes: ['966'],
  },
  {
    flag: 'https://restcountries.eu/data/sen.svg',
    name: 'Senegal',
    alpha2Code: 'SN',
    alpha3Code: 'SEN',
    callingCodes: ['221'],
  },
  {
    flag: 'https://restcountries.eu/data/srb.svg',
    name: 'Serbia',
    alpha2Code: 'RS',
    alpha3Code: 'SRB',
    callingCodes: ['381'],
  },
  {
    flag: 'https://restcountries.eu/data/syc.svg',
    name: 'Seychelles',
    alpha2Code: 'SC',
    alpha3Code: 'SYC',
    callingCodes: ['248'],
  },
  {
    flag: 'https://restcountries.eu/data/sle.svg',
    name: 'Sierra Leone',
    alpha2Code: 'SL',
    alpha3Code: 'SLE',
    callingCodes: ['232'],
  },
  {
    flag: 'https://restcountries.eu/data/sgp.svg',
    name: 'Singapore',
    alpha2Code: 'SG',
    alpha3Code: 'SGP',
    callingCodes: ['65'],
  },
  {
    flag: 'https://restcountries.eu/data/sxm.svg',
    name: 'Sint Maarten (Dutch part)',
    alpha2Code: 'SX',
    alpha3Code: 'SXM',
    callingCodes: ['1721'],
  },
  {
    flag: 'https://restcountries.eu/data/svk.svg',
    name: 'Slovakia',
    alpha2Code: 'SK',
    alpha3Code: 'SVK',
    callingCodes: ['421'],
  },
  {
    flag: 'https://restcountries.eu/data/svn.svg',
    name: 'Slovenia',
    alpha2Code: 'SI',
    alpha3Code: 'SVN',
    callingCodes: ['386'],
  },
  {
    flag: 'https://restcountries.eu/data/slb.svg',
    name: 'Solomon Islands',
    alpha2Code: 'SB',
    alpha3Code: 'SLB',
    callingCodes: ['677'],
  },
  {
    flag: 'https://restcountries.eu/data/som.svg',
    name: 'Somalia',
    alpha2Code: 'SO',
    alpha3Code: 'SOM',
    callingCodes: ['252'],
  },
  {
    flag: 'https://restcountries.eu/data/zaf.svg',
    name: 'South Africa',
    alpha2Code: 'ZA',
    alpha3Code: 'ZAF',
    callingCodes: ['27'],
  },
  {
    flag: 'https://restcountries.eu/data/sgs.svg',
    name: 'South Georgia and the South Sandwich Islands',
    alpha2Code: 'GS',
    alpha3Code: 'SGS',
    callingCodes: ['500'],
  },
  {
    flag: 'https://restcountries.eu/data/kor.svg',
    name: 'Korea (Republic of)',
    alpha2Code: 'KR',
    alpha3Code: 'KOR',
    callingCodes: ['82'],
  },
  {
    flag: 'https://restcountries.eu/data/ssd.svg',
    name: 'South Sudan',
    alpha2Code: 'SS',
    alpha3Code: 'SSD',
    callingCodes: ['211'],
  },
  {
    flag: 'https://restcountries.eu/data/esp.svg',
    name: 'Spain',
    alpha2Code: 'ES',
    alpha3Code: 'ESP',
    callingCodes: ['34'],
  },
  {
    flag: 'https://restcountries.eu/data/lka.svg',
    name: 'Sri Lanka',
    alpha2Code: 'LK',
    alpha3Code: 'LKA',
    callingCodes: ['94'],
  },
  {
    flag: 'https://restcountries.eu/data/sdn.svg',
    name: 'Sudan',
    alpha2Code: 'SD',
    alpha3Code: 'SDN',
    callingCodes: ['249'],
  },
  {
    flag: 'https://restcountries.eu/data/sur.svg',
    name: 'Suriname',
    alpha2Code: 'SR',
    alpha3Code: 'SUR',
    callingCodes: ['597'],
  },
  {
    flag: 'https://restcountries.eu/data/sjm.svg',
    name: 'Svalbard and Jan Mayen',
    alpha2Code: 'SJ',
    alpha3Code: 'SJM',
    callingCodes: ['4779'],
  },
  {
    flag: 'https://restcountries.eu/data/swz.svg',
    name: 'Swaziland',
    alpha2Code: 'SZ',
    alpha3Code: 'SWZ',
    callingCodes: ['268'],
  },
  {
    flag: 'https://restcountries.eu/data/swe.svg',
    name: 'Sweden',
    alpha2Code: 'SE',
    alpha3Code: 'SWE',
    callingCodes: ['46'],
  },
  {
    flag: 'https://restcountries.eu/data/che.svg',
    name: 'Switzerland',
    alpha2Code: 'CH',
    alpha3Code: 'CHE',
    callingCodes: ['41'],
  },
  {
    flag: 'https://restcountries.eu/data/syr.svg',
    name: 'Syrian Arab Republic',
    alpha2Code: 'SY',
    alpha3Code: 'SYR',
    callingCodes: ['963'],
  },
  {
    flag: 'https://restcountries.eu/data/twn.svg',
    name: 'Taiwan',
    alpha2Code: 'TW',
    alpha3Code: 'TWN',
    callingCodes: ['886'],
  },
  {
    flag: 'https://restcountries.eu/data/tjk.svg',
    name: 'Tajikistan',
    alpha2Code: 'TJ',
    alpha3Code: 'TJK',
    callingCodes: ['992'],
  },
  {
    flag: 'https://restcountries.eu/data/tza.svg',
    name: 'Tanzania, United Republic of',
    alpha2Code: 'TZ',
    alpha3Code: 'TZA',
    callingCodes: ['255'],
  },
  {
    flag: 'https://restcountries.eu/data/tha.svg',
    name: 'Thailand',
    alpha2Code: 'TH',
    alpha3Code: 'THA',
    callingCodes: ['66'],
  },
  {
    flag: 'https://restcountries.eu/data/tls.svg',
    name: 'Timor-Leste',
    alpha2Code: 'TL',
    alpha3Code: 'TLS',
    callingCodes: ['670'],
  },
  {
    flag: 'https://restcountries.eu/data/tgo.svg',
    name: 'Togo',
    alpha2Code: 'TG',
    alpha3Code: 'TGO',
    callingCodes: ['228'],
  },
  {
    flag: 'https://restcountries.eu/data/tkl.svg',
    name: 'Tokelau',
    alpha2Code: 'TK',
    alpha3Code: 'TKL',
    callingCodes: ['690'],
  },
  {
    flag: 'https://restcountries.eu/data/ton.svg',
    name: 'Tonga',
    alpha2Code: 'TO',
    alpha3Code: 'TON',
    callingCodes: ['676'],
  },
  {
    flag: 'https://restcountries.eu/data/tto.svg',
    name: 'Trinidad and Tobago',
    alpha2Code: 'TT',
    alpha3Code: 'TTO',
    callingCodes: ['1868'],
  },
  {
    flag: 'https://restcountries.eu/data/tun.svg',
    name: 'Tunisia',
    alpha2Code: 'TN',
    alpha3Code: 'TUN',
    callingCodes: ['216'],
  },
  {
    flag: 'https://restcountries.eu/data/tur.svg',
    name: 'Turkey',
    alpha2Code: 'TR',
    alpha3Code: 'TUR',
    callingCodes: ['90'],
  },
  {
    flag: 'https://restcountries.eu/data/tkm.svg',
    name: 'Turkmenistan',
    alpha2Code: 'TM',
    alpha3Code: 'TKM',
    callingCodes: ['993'],
  },
  {
    flag: 'https://restcountries.eu/data/tca.svg',
    name: 'Turks and Caicos Islands',
    alpha2Code: 'TC',
    alpha3Code: 'TCA',
    callingCodes: ['1649'],
  },
  {
    flag: 'https://restcountries.eu/data/tuv.svg',
    name: 'Tuvalu',
    alpha2Code: 'TV',
    alpha3Code: 'TUV',
    callingCodes: ['688'],
  },
  {
    flag: 'https://restcountries.eu/data/uga.svg',
    name: 'Uganda',
    alpha2Code: 'UG',
    alpha3Code: 'UGA',
    callingCodes: ['256'],
  },
  {
    flag: 'https://restcountries.eu/data/ukr.svg',
    name: 'Ukraine',
    alpha2Code: 'UA',
    alpha3Code: 'UKR',
    callingCodes: ['380'],
  },
  {
    flag: 'https://restcountries.eu/data/are.svg',
    name: 'United Arab Emirates',
    alpha2Code: 'AE',
    alpha3Code: 'ARE',
    callingCodes: ['971'],
  },
  {
    flag: 'https://restcountries.eu/data/gbr.svg',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2Code: 'GB',
    alpha3Code: 'GBR',
    callingCodes: ['44'],
  },
  {
    flag: 'https://restcountries.eu/data/usa.svg',
    name: 'United States of America',
    alpha2Code: 'US',
    alpha3Code: 'USA',
    callingCodes: ['1'],
  },
  {
    flag: 'https://restcountries.eu/data/ury.svg',
    name: 'Uruguay',
    alpha2Code: 'UY',
    alpha3Code: 'URY',
    callingCodes: ['598'],
  },
  {
    flag: 'https://restcountries.eu/data/uzb.svg',
    name: 'Uzbekistan',
    alpha2Code: 'UZ',
    alpha3Code: 'UZB',
    callingCodes: ['998'],
  },
  {
    flag: 'https://restcountries.eu/data/vut.svg',
    name: 'Vanuatu',
    alpha2Code: 'VU',
    alpha3Code: 'VUT',
    callingCodes: ['678'],
  },
  {
    flag: 'https://restcountries.eu/data/ven.svg',
    name: 'Venezuela (Bolivarian Republic of)',
    alpha2Code: 'VE',
    alpha3Code: 'VEN',
    callingCodes: ['58'],
  },
  {
    flag: 'https://restcountries.eu/data/vnm.svg',
    name: 'Viet Nam',
    alpha2Code: 'VN',
    alpha3Code: 'VNM',
    callingCodes: ['84'],
  },
  {
    flag: 'https://restcountries.eu/data/wlf.svg',
    name: 'Wallis and Futuna',
    alpha2Code: 'WF',
    alpha3Code: 'WLF',
    callingCodes: ['681'],
  },
  {
    flag: 'https://restcountries.eu/data/esh.svg',
    name: 'Western Sahara',
    alpha2Code: 'EH',
    alpha3Code: 'ESH',
    callingCodes: ['212'],
  },
  {
    flag: 'https://restcountries.eu/data/yem.svg',
    name: 'Yemen',
    alpha2Code: 'YE',
    alpha3Code: 'YEM',
    callingCodes: ['967'],
  },
  {
    flag: 'https://restcountries.eu/data/zmb.svg',
    name: 'Zambia',
    alpha2Code: 'ZM',
    alpha3Code: 'ZMB',
    callingCodes: ['260'],
  },
  {
    flag: 'https://restcountries.eu/data/zwe.svg',
    name: 'Zimbabwe',
    alpha2Code: 'ZW',
    alpha3Code: 'ZWE',
    callingCodes: ['263'],
  },
];

export default countries;
