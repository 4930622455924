import { useState, useEffect, ChangeEvent, FormEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { DropdownChangeEvent } from 'primereact/dropdown';

import {
  setUserId,
  setPhoneNumber as setPhoneNumberCookie,
  getPhoneNumber,
  Helpers,
} from 'utils';
import { routes, countries, enums } from 'constants/index';
import { useExtractServerError, useCleanedPhoneNumber } from 'hooks';

import { useRegisterPhoneMutation } from '../mutations';

import PhoneRegisterUi from './PhoneRegister.ui';
import Validator from './utils/validator';

export default function PhoneRegister() {
  const storePhoneNumber = getPhoneNumber();

  //* Hooks Init
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const cleanPhoneNumber = useCleanedPhoneNumber();
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [form, setForm] = useState({
    phoneNumber: '',
    countryCode: enums.countryCodes.lb,
    callingCode: enums.callingCodes.lb.toString(),
  });
  const [errors, setErrors] = useState({
    phoneNumber: '',
    countryCode: '',
    callingCode: '',
  });

  //* Handlers
  function handleInputChange({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  }

  function handleCountryChange(event: DropdownChangeEvent) {
    const { getCallingCode }: Helpers = new Helpers();

    setForm((prevState) => ({
      ...prevState,
      countryCode: event.value,
      callingCode: getCallingCode(event.value),
    }));
  }

  function onPhoneRegisterSuccess({ data }: any) {
    const cleanedPhoneNumber = cleanPhoneNumber(
      form.phoneNumber,
      form.callingCode
    );

    setUserId(data.id);
    setPhoneNumberCookie(
      {
        phoneNumber: cleanedPhoneNumber,
        callingCode: form.callingCode,
        countryCode: storePhoneNumber.countryCode || form.countryCode || '',
      },
      90
    );

    if (data.phoneValidationRequired)
      navigate(
        `${routes.authentication.index}/${routes.authentication.phoneOtp}`
      );

    if (data.userInfoRequired || data?.emailPinCodeRequired)
      navigate(
        `${routes.authentication.index}/${routes.authentication.userDetails}`
      );

    if (data.pinCodeRequired)
      navigate(`${routes.authentication.index}/${routes.authentication.login}`);
  }

  const registerPhoneMutation = useRegisterPhoneMutation({
    onSuccess: onPhoneRegisterSuccess,
  });

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!validateForm()) return;

    const cleanedPhoneNumber = cleanPhoneNumber(
      form.phoneNumber,
      form.callingCode
    );

    registerPhoneMutation.mutate({
      phoneNumber: parseInt(form.callingCode.concat(cleanedPhoneNumber)),
      countryCode: parseInt(form.callingCode),
    });
  }

  function validateForm() {
    const validator = new Validator();
    const errors = validator.validateLoginForm(
      [
        {
          name: 'phoneNumber',
          value: !form.phoneNumber ? '' : String(form.phoneNumber),
          rules: {
            isEmpty: true,
            isValid: true,
          },
        },
      ],
      form.countryCode
    );

    setErrors((prevState) => ({
      ...prevState,
      ...errors,
    }));

    return errors.phoneNumber?.length === 0;
  }

  useEffect(() => {
    if (registerPhoneMutation.isError) {
      const errorMessage = extractErrorMessage(registerPhoneMutation.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [registerPhoneMutation.isError]);

  useEffect(() => {
    if (
      !!storePhoneNumber?.phoneNumber &&
      !!storePhoneNumber?.callingCode &&
      !!storePhoneNumber?.countryCode
    ) {
      navigate(`${routes.authentication.index}/${routes.authentication.login}`);
    }
  }, []);

  const data = {
    ...form,
  };

  const options = {
    countries: countries.map(
      ({ callingCodes, flag, alpha3Code, alpha2Code }) => {
        let countryObj = {
          key: '',
          value: '',
          alpha2Code: '',
          label: '',
        };
        callingCodes.forEach((callingCode) => {
          countryObj = {
            key: alpha3Code,
            value: alpha3Code,
            alpha2Code,
            label: `+${callingCode}`,
          };
        });

        return countryObj;
      }
    ),
  };

  const handlers = {
    handleInputChange,
    handleCountryChange,
    handleSubmit,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <PhoneRegisterUi
        isLoading={registerPhoneMutation.isLoading}
        options={options}
        errors={errors}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
