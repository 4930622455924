import { useRef } from 'react';
import axios from 'axios';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';

import CampaignOptOutUi from './CampaignOptOut.ui';

export default function CampaignOptOut() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  // * Handlers
  function handleConfirmClick() {
    consent(false);
  }

  function handleCancelClick() {}

  //* Side effects
  async function consent(isConsent: boolean) {
    try {
      const { data } = await axios.post(
        `https://mabe-uat.clover-brokers.com/consent`,
        {
          email_address: 'hsjardaneh@blgx.net',
          hash_id: '1534b76d325a8f591b52d302e7181331',
          consent: isConsent,
        }
      );

      toastRef.current?.show({
        severity: 'success',
        summary: 'Success',
        detail: data.message,
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }

  const handlers = {
    handleConfirmClick,
    handleCancelClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <CampaignOptOutUi handlers={handlers} />
    </>
  );
}
