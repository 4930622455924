// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/right-arrow-red.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".az-tickets__icon {\n  display: flex;\n  width: 1.6rem;\n  height: 1.6rem;\n  background-size: cover;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.az-tickets__approve {\n  background-color: #35c8c8 !important;\n  border-color: #35c8c8 !important;\n}\n.az-tickets__listFive-details {\n  color: #b50000;\n  cursor: pointer;\n}\n.az-tickets__actions {\n  height: 100%;\n  width: 20rem;\n  align-items: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/modules/ListOne/list-one.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;EACA,yDAAA;AAFJ;AAKE;EACE,oCAAA;EACA,gCAAA;AAHJ;AAME;EACE,cCMY;EDLZ,eAAA;AAJJ;AAOE;EACE,YAAA;EACA,YAAA;EACA,qBAAA;AALJ","sourcesContent":["@import '../../styles/theme';\n\n.az-tickets {\n  &__icon {\n    display: flex;\n    width: 1.6rem;\n    height: 1.6rem;\n    background-size: cover;\n    background-image: url('../../assets/icons/right-arrow-red.svg');\n  }\n\n  &__approve {\n    background-color: $light-blue !important;\n    border-color: $light-blue !important;\n  }\n\n  &__listFive-details {\n    color: $primary-color;\n    cursor: pointer;\n  }\n\n  &__actions {\n    height: 100%;\n    width: 20rem;\n    align-items: flex-end;\n  }\n}\n","$white: #fff;\n$black: #000;\n$off-white-2: #f7e1e1;\n$off-white: #f7f7f7;\n\n$primary-font-color: #3f4d50;\n$secondary-font-color: #3f3e3e;\n$tertiary-font-color: #898989;\n$light-gray-font-color: #9fa6a7;\n$gray-font-color: #676666;\n$red: #ff0000;\n$light-blue: #35c8c8;\n$affair-8f: #8e6b91;\n$sapling-a8: #dfcba8;\n$gumbo-a1: #779fa1;\n$flush-orange-00: #f8aa65;\n$limed-spruce-50: #5f6e71;\n\n$error-font-color: #b50000;\n$error-input-font-color: #db6f69;\n$error-background-color: #fef6f6;\n$error-border-color: #e0b4b4;\n\n$primary-color: #b50000;\n\n$border-color: #c6c6c6;\n$border-color-light: #e6e6e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
