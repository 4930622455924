import useListOneTicketsQuery from './useListOneTicketsQuery';
import useListOneTicketQuery from './useListOneTicketQuery';
import useListOneTicketHistoryQuery from './useListOneTicketHistoryQuery';
import useListOneTicketStudiesQuery from './useListOneTicketStudiesQuery';
import useListOneStatusesQuery from './useListOneStatusesQuery';

export {
  useListOneTicketsQuery,
  useListOneTicketQuery,
  useListOneTicketHistoryQuery,
  useListOneTicketStudiesQuery,
  useListOneStatusesQuery,
};
