import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';
import { useConfigQueries } from 'modules/App/queries';
import { getGermanDate } from 'utils';

import {
  useListFiveQuery,
  useInvoiceInfoQuery,
  useListSixQuery,
} from '../queries';
import { useListFiveMutation } from '../mutations';

import {
  ListFiveFilter,
  ListSixFilter,
  DropdownChangeEvent,
  PaginatorPageChangeEvent,
  CalendarChangeEvent,
  DataTableRowEvent,
  DataTableExpandedRows,
} from './ListFiveOtherInvoicesListing.types';

import ListFiveOtherInvoicesListingUi from './ListFiveOtherInvoicesListing.ui';

export default function ListFiveOtherInvoicesListing() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: any = {
    page: searchParams.get('page') || '1',
    rows: searchParams.get('rows') || '50',
    invoiceNumber: searchParams.get('invoiceNumber') || '',
    endorsementStatus: searchParams.get('endorsementStatus') || '',
    azadeaStatus: searchParams.get('azadeaStatus') || '',
    exceptionId: searchParams.get('exceptionId') || '',
    country: searchParams.get('country') || '',
    minDate: searchParams.get('minDate') || '',
    maxDate: searchParams.get('maxDate') || '',
  };

  //* Local State
  const [isInvoiceInfoVisible, setIsInvoiceInfoVisible] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isListSixInfoVisible, setIsListSixInfoVisible] = useState(false);
  const [listSixSelectedId, setListSixSelectedId] = useState(0);
  const [selectedInvoice, setSelectedInvoice] = useState('');
  const [cloverInvoice, setCloverInvoice] = useState('');
  const [filter, setFilter] = useState<ListFiveFilter>({
    page: params.page,
    rows: params.rows,
    invoiceNumber: params.invoiceNumber,
    endorsementStatus: params.endorsementStatus,
    azadeaStatus: params.azadeaStatus,
    exceptionId: params.exceptionId,
    country: params.country,
    dates: [new Date(params.minDate), new Date(params.maxDate)],
  });
  const [listSixFilter, setListSixFilter] = useState<ListSixFilter>({
    page: params.page,
    first: parseInt(params.first),
    rows: parseInt(params.rows),
  });
  const [expandedRows, setExpandedRows] =
    useState<DataTableExpandedRows | null>(null);

  //* Queries & Mutations
  const [, endorsementConfig] = useConfigQueries();
  const listFiveQuery = useListFiveQuery(
    { ...params },
    {
      onSuccess: handleListFiveSuccess,
    }
  );
  const listSixQuery = useListSixQuery(
    { cloverInvoice, ...listSixFilter },
    { enabled: cloverInvoice.length > 0 }
  );
  const invoiceInfoQuery = useInvoiceInfoQuery(selectedInvoice, {
    enabled: selectedInvoice.length > 0,
  });
  const listFiveMutation = useListFiveMutation({
    onSuccess: handleDownloadSuccess,
  });

  //* Handlers
  function handleClearFilter() {
    setSearchParams({});

    setFilter({
      page: 1,
      rows: 50,
      invoiceNumber: '',
      endorsementStatus: '',
      azadeaStatus: '',
      exceptionId: 0,
      dates: null,
    });
  }

  const handleDownload = () => {
    listFiveMutation.mutate({ ...filter, exportable: true });
  };

  function handleInputChange({ target: { name, value } }: any) {
    setFilter({
      ...filter,
      invoiceNumber: value.trim(),
    });
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.invoiceNumber.value.length > 0) {
      setSearchParams({
        ...params,
        invoiceNumber: event.target.elements.invoiceNumber.value.trim(),
      });
    } else {
      const { invoiceNumber, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      invoiceNumber: event.target.elements.invoiceNumber.value,
    }));
  }

  function handleAzadeaStatusChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      azadeaStatus: event.value,
      page: 1,
    });

    setFilter({
      ...filter,
      azadeaStatus: event.value,
      page: 1,
    });
  }

  function handlerEndorsementStatuses(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      endorsementStatus: event.value,
      page: 1,
    });

    setFilter({
      ...filter,
      endorsementStatus: event.value,
      page: 1,
    });
  }

  function handleExceptionChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      exceptionId: event.value,
      page: 1,
    });

    setFilter({
      ...filter,
      exceptionId: event.value,
      page: 1,
    });
  }

  function handleCountryChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      country: event.value,
      page: 1,
      first: 0,
    });

    setFilter((filter) => ({
      ...filter,
      country: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handleDateChange(event: CalendarChangeEvent) {
    if (Array.isArray(event.value)) {
      const minDate = !!event.value[0]
        ? getGermanDate(event.value[0].toISOString())
        : '';
      const maxDate = !!event.value[1]
        ? getGermanDate(event.value[1].toISOString())
        : '';

      setSearchParams({
        ...params,
        minDate,
        maxDate,
      });

      setFilter((filter) => ({
        ...filter,
        dates: event.value,
        page: 1,
        first: 0,
      }));
    }
  }

  const handleShowListFiveRowDetails = (invoiceNumber: string) => () => {
    setIsInvoiceInfoVisible(true);
    setSelectedInvoice(invoiceNumber);
  };

  const handleHideListFiveRowDetails = () => {
    setIsInvoiceInfoVisible(false);
  };

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...searchParams,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleListFiveSuccess({ data }: any) {
    setTotalRecords(data.total);
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  function handleRowToggle(event: any) {
    /**
     *! This is a workaround to get the last key of the object
     *! because the event.data object has a dynamic key
     */
    const expandedRowsKeysArray = Object.keys(event.data);
    const expandedRowsKeysArrayLastIndex = expandedRowsKeysArray.length - 1;

    /**
     *! We need to check if the expandedRowsKeysArray has more than one key
     *! because if it has only one key, it means that the row is collapsed
     *! and we need to set the expandedRows state to an empty object
     *! otherwise, we need to set the expandedRows state to the object
     *
     *! This is due to the fact that listSixQuery is dependent on the expandedRows state
     *! and if we have multiple rows expanded, the listSixQuery will be called multiple times
     *! and the data will be overwritten
     */
    setExpandedRows(
      expandedRowsKeysArray.length > 1
        ? {
            [expandedRowsKeysArray[expandedRowsKeysArrayLastIndex]]:
              event.data[expandedRowsKeysArray[expandedRowsKeysArrayLastIndex]],
          }
        : event.data
    );
  }

  const handleRowExpand = (event: DataTableRowEvent) => {
    setCloverInvoice(event.data.clover_invoice);
  };

  const handleRowCollapse = () => {
    setCloverInvoice('');
  };

  function handleListSixPageChange(event: PaginatorPageChangeEvent) {
    setListSixFilter((filter) => ({
      ...filter,
      page: event.page + 1,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleListSixInfoDialogShow(id: number) {
    return function () {
      setIsListSixInfoVisible(true);
      setListSixSelectedId(id);
    };
  }

  function handleListSixInfoDialogClose() {
    setIsListSixInfoVisible(false);
  }

  useEffect(() => {
    if (
      listFiveQuery.isError ||
      invoiceInfoQuery.isError ||
      listFiveMutation.isError
    ) {
      const errorMessage = extractErrorMessage(
        listFiveQuery.error || invoiceInfoQuery.error || listFiveMutation.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [
    listFiveQuery.isError,
    invoiceInfoQuery.isError,
    listFiveMutation.isError,
  ]);

  const options = {
    endorsementStatuses:
      endorsementConfig.data?.data.listStatuses
        .find(({ list, belongsTo }) => list === 5 && belongsTo === 'clover')
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
    azadeaStatuses:
      endorsementConfig.data?.data.listStatuses
        .find(({ list, belongsTo }) => list === 5 && belongsTo === 'azadea')
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
    exceptions:
      endorsementConfig.data?.data.exceptions
        .find(({ list }) => list === 5)
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
    countries:
      endorsementConfig.data?.data.countries.map(({ _id, value }) => ({
        key: _id,
        label: value,
        value: value,
      })) || [],
  };

  const data = {
    listSixSelectedId,
    list: listFiveQuery.data?.data.data || [],
    selectedInvoice,
    invoiceInfo: invoiceInfoQuery.data?.data.data[0] || null,
    listSixData: listSixQuery.data?.data.data || [],
  };

  const handlers = {
    handleClearFilter,
    handleDownload,
    handleInputChange,
    handleSubmitSearch,
    handleExceptionChange,
    handlePageChange,
    handleShowListFiveRowDetails,
    handleHideListFiveRowDetails,
    handleAzadeaStatusChange,
    handlerEndorsementStatuses,
    handleCountryChange,
    handleDateChange,
    handleRowToggle,
    handleRowExpand,
    handleRowCollapse,
    handleListSixPageChange,
    handleListSixInfoDialogShow,
    handleListSixInfoDialogClose,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListFiveOtherInvoicesListingUi
        isLoading={listFiveQuery.isLoading}
        isListSixLoading={listSixQuery.isLoading}
        isExporting={listFiveMutation.isLoading}
        isInvoiceInfoVisible={isInvoiceInfoVisible}
        isListSixInfoVisible={isListSixInfoVisible}
        totalRecords={totalRecords}
        listSixTotalRecords={listSixQuery.data?.data.total || 0}
        expandedRows={expandedRows}
        filter={filter}
        listSixFilter={listSixFilter}
        options={options}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
