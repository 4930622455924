import classNames from 'classnames';
import { DataTable as PrimeReactDataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { IDataTableProps } from './DataTable.types';

import './data-table.scss';

export default function DataTable({
  value,
  totalRecords,
  dataKey,
  header,
  footer,
  size,
  showGridlines,
  stripedRows,
  paginator,
  rowsPerPageOptions,
  paginatorTemplate,
  currentPageReportTemplate,
  paginatorLeft,
  paginatorRight,
  rows,
  sortMode,
  sortField,
  sortOrder,
  removableSort,
  emptyMessage,
  selectionMode,
  selection,
  dragSelection,
  metaKeySelection,
  expandedRows,
  editMode,
  isDataSelectable,
  onSelectionChange,
  onRowSelect,
  onRowUnselect,
  onRowExpand,
  onRowCollapse,
  onRowToggle,
  rowClassName,
  rowExpansionTemplate,
  onRowEditComplete,
  frozenValue,
  scrollable,
  scrollHeight,
  resizableColumns,
  columnResizeMode,
  reorderableColumns,
  reorderableRows,
  onRowReorder,
  headerColumnGroup,
  tableStyle,
  columns,
  className,
  onRowClick,
}: IDataTableProps) {
  const tableHeader = (
    <>
      <div className="std-data-table__header">
        Total results: {totalRecords || 0}
      </div>

      {header}
    </>
  );
  return (
    <PrimeReactDataTable
      value={value}
      dataKey={dataKey}
      header={tableHeader}
      footer={footer}
      showGridlines={showGridlines}
      stripedRows={stripedRows}
      paginator={paginator}
      rowsPerPageOptions={rowsPerPageOptions}
      paginatorTemplate={paginatorTemplate}
      currentPageReportTemplate={currentPageReportTemplate}
      paginatorLeft={paginatorLeft}
      paginatorRight={paginatorRight}
      rows={rows}
      size={size}
      sortMode={sortMode}
      sortField={sortField}
      sortOrder={sortOrder}
      removableSort={removableSort}
      emptyMessage={emptyMessage}
      selectionMode={selectionMode}
      selection={selection}
      dragSelection={dragSelection}
      metaKeySelection={metaKeySelection}
      expandedRows={expandedRows}
      editMode={editMode}
      isDataSelectable={isDataSelectable}
      onSelectionChange={onSelectionChange}
      onRowSelect={onRowSelect}
      onRowUnselect={onRowUnselect}
      onRowExpand={onRowExpand}
      onRowCollapse={onRowCollapse}
      onRowToggle={onRowToggle}
      rowClassName={rowClassName}
      rowExpansionTemplate={rowExpansionTemplate}
      onRowEditComplete={onRowEditComplete}
      scrollable={scrollable}
      frozenValue={frozenValue}
      resizableColumns={resizableColumns}
      columnResizeMode={columnResizeMode}
      reorderableColumns={reorderableColumns}
      reorderableRows={reorderableRows}
      onRowReorder={onRowReorder}
      headerColumnGroup={headerColumnGroup}
      onRowClick={onRowClick}
      scrollHeight={scrollHeight}
      tableStyle={tableStyle}
      className={classNames('std-data-table', className)}
    >
      {columns.map((column) => (
        <Column key={column.field} {...column} />
      ))}
    </PrimeReactDataTable>
  );
}
