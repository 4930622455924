import { Link } from 'react-router-dom';

import { Otp } from 'components';

import { locales, routes } from 'constants/index';

import AuthenticationSkeleton from '../AuthenticationSkeleton';

import { ILoginUiProps } from './Login.types';

import './login.scss';

export default function LoginUi({
  isLoading,
  pinCodeError,
  data: { pinCode },
  handlers: { handlePinCodeChange, handleLogin },
}: ILoginUiProps) {
  return (
    <AuthenticationSkeleton
      title={locales.login.title}
      isLoading={isLoading}
      onContinue={handleLogin}
    >
      <div className="form__field">
        <div className="personal-pin__label">{locales.personalPin.label}</div>

        <Otp
          value={pinCode}
          numInput={6}
          onChange={handlePinCodeChange}
          error={pinCodeError}
          isInputSecure
        />
      </div>

      <Link
        to={`${routes.authentication.index}/${routes.authentication.resetPin}`}
        className="login__reset-pin"
      >
        {locales.general.resetPin}
      </Link>
    </AuthenticationSkeleton>
  );
}
