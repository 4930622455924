import { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';

import { useSearchListThreeQuery } from '../queries';

import ListThreeDetailsUi from './ListThreeDetails.ui';

export default function ListThreeDetails() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const { id = '' } = useParams();
  const { extractErrorMessage } = useExtractServerError();

  //* Queries & Mutations
  const searchListThreeQuery = useSearchListThreeQuery(id);

  useEffect(() => {
    if (searchListThreeQuery.isError) {
      const errorMessage = extractErrorMessage(searchListThreeQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [searchListThreeQuery.isError]);

  const data = { ticketDetails: searchListThreeQuery.data?.data.data[0] || {} };

  return (
    <>
      <Toast ref={toastRef} />

      <ListThreeDetailsUi
        isLoading={searchListThreeQuery.isLoading}
        data={data}
      />
    </>
  );
}
