import PhoneRegister from './PhoneRegister';
import Login from './Login';
import PhoneOtp from './PhoneOtp';
import EmailOtp from './EmailOtp';
import PersonalPin from './PersonalPin';
import UserDetails from './UserDetails';
import ResetPin from './ResetPin';
import NewPinResetForm from './NewPinResetForm';
import LoginOtpVerification from './LoginOtpVerification';

export { default } from './Authentication';
export {
  PhoneRegister,
  Login,
  LoginOtpVerification,
  PhoneOtp,
  EmailOtp,
  PersonalPin,
  UserDetails,
  ResetPin,
  NewPinResetForm,
};
