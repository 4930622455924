import {
  UserDetailsFunction,
  UserDetailsAction,
  UserData,
  TClearAuthStore,
} from './types';
import { USER_DETAILS_SUBMITTED, CLEAR_AUTH_STORE } from './action-types';

export * from 'modules/App/store/actions';

export const submitUserDetails: UserDetailsFunction = ({
  firstName,
  lastName,
  email,
  yearOfBirth,
}: UserData): UserDetailsAction => ({
  type: USER_DETAILS_SUBMITTED,
  firstName,
  lastName,
  email,
  yearOfBirth,
});

export const clearAuthStore: TClearAuthStore = () => ({
  type: CLEAR_AUTH_STORE,
});
