import enums from 'constants/enums';

import {
  TAction,
  IAppGlobalState,
  IAuthConfig,
  IUserInfo,
} from './store.types';
import {
  INIT_APP,
  AUTHENTICATE_USER,
  UNAUTHENTICATE_USER,
} from './action-types';

const initialState: IAppGlobalState = {
  accessToken: '',
  refreshToken: '',
  authStatus: enums.app.status.none,
  locale: enums.locales.en_US,
  userInfo: {} as IUserInfo,
};

const appReducer = (state: IAppGlobalState = initialState, action: TAction) => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        authStatus: enums.app.status.loaded,
        locale: action.locale,
      };
    case AUTHENTICATE_USER:
      return {
        ...state,
        authStatus: enums.app.status.authenticated,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };
    case UNAUTHENTICATE_USER:
      return {
        ...state,
        authStatus: enums.app.status.unAuthenticated,
        accessToken: '',
        refreshToken: '',
      };

    default:
      return state;
  }
};

export default appReducer;
