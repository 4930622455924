import { combineReducers } from 'redux';

import appReducer from 'modules/App/store/reducer';
import authenticationReducer from 'modules/Authentication/store/reducer';

const mainReducer = combineReducers({
  app: appReducer,
  auth: authenticationReducer,
});

export default mainReducer;
