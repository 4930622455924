import { api } from 'utils';

import {
  FetchListTwoApiResponse,
  FetchListTwoTicketDetailsApiResponse,
  FetchListTwoTicketStudiesApiResponse,
} from './api.types';

export function fetchListTwoApi(filter: any) {
  return api().get<FetchListTwoApiResponse>('/tickets/list/two/fetch', {
    params: {
      page: filter.exportable ? undefined : filter.page,
      limit: filter.exportable ? undefined : filter.rows,
      type: filter.ticketType === 'All' ? '' : filter.ticketType,
      status: filter.ticketStatus === 'All' ? '' : filter.ticketStatus,
      minDate: filter.startDate,
      maxDate: filter.endDate,
      searchString: filter.employeeId,
      exportable: filter.exportable,
    },
  });
}

export function fetchListTwoTicketDetailsApi({ queryKey }: any) {
  const [, , , , id] = queryKey;
  return api().get<FetchListTwoTicketDetailsApiResponse>(
    `/ticket/list/two/info`,
    {
      params: { id },
    }
  );
}

export function fetchListTwoTicketHistoryInfoApi({ queryKey }: any) {
  const [, , , , , params] = queryKey;
  return api().get(`/ticket/list/two/history/info`, {
    params: { id: params.id, ticketId: params.ticketId },
  });
}

export function fetchListTwoTicketStudiesApi({ queryKey }: any) {
  const [, , , , ticketId] = queryKey;

  return api().get<FetchListTwoTicketStudiesApiResponse>(
    '/tickets/list/two/history',
    {
      params: {
        ticketId,
      },
    }
  );
}
