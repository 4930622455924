import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes } from 'constants/index';
import { useExtractServerError } from 'hooks';

import CampaignConsentUi from './CampaignConsent.ui';

export default function CampaignConsent() {
  //* Hooks Init
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);
  const [message, setMessage] = useState('');

  //* Handlers
  function handleConfirmationDialogHide() {
    setIsConfirmationDialogVisible(false);

    navigate(routes.main);
  }

  const params = {
    email: searchParams.get('email') ?? '',
    hashId: searchParams.get('hashId') ?? '',
    isConsent: searchParams.get('isConsent') === 'true',
  };

  //* Side effects
  async function consent({
    email,
    hashId,
    isConsent,
  }: {
    email: string;
    hashId: string;
    isConsent: boolean;
  }) {
    try {
      setIsLoading(true);

      const { data } = await axios.post(
        `https://mabe-uat.clover-brokers.com/consent`,
        {
          email_address: email,
          hash_id: hashId,
          consent: isConsent,
        }
      );

      setIsConfirmationDialogVisible(true);
      setMessage(data.message);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (params.email && params.hashId && params.isConsent) consent(params);
    else navigate(routes.main);
  }, [params.email, params.hashId, params.isConsent]);

  const handlers = { handleConfirmationDialogHide };

  return (
    <>
      <Toast ref={toastRef} />

      <CampaignConsentUi
        isLoading={isLoading}
        isConfirmationDialogVisible={isConfirmationDialogVisible}
        message={message}
        handlers={handlers}
      />
    </>
  );
}
