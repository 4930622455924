import { Dialog as PrimeReactDialog, DialogProps } from 'primereact/dialog';

export default function Dialog({
  children,
  footer,
  header,
  onHide,
  visible,
  modal = true,
  position,
  draggable,
  resizable,
  maximizable,
  breakpoints,
  className,
  style,
}: DialogProps) {
  return (
    <PrimeReactDialog
      header={header}
      footer={footer}
      visible={visible}
      modal={modal}
      position={position}
      draggable={draggable}
      resizable={resizable}
      breakpoints={breakpoints}
      maximizable={maximizable}
      className={`std-dialog ${className}`}
      style={style}
      onHide={onHide}
    >
      {children}
    </PrimeReactDialog>
  );
}
