import classNames from 'classnames';
import { Dropdown as PrimeReactDropdown } from 'primereact/dropdown';

import { IDropdownProps } from './Dropdown.types';

import './style.scss';

export default function Dropdown({
  id,
  name,
  options,
  optionLabel,
  error = '',
  className = '',
  label = '',
  value,
  disabled = false,
  placeholder = '',
  onChange,
  editable = false,
  optionGroupLabel,
  optionGroupChildren,
  optionGroupTemplate,
  onBlur,
  itemTemplate,
  valueTemplate,
  showClear,
  filter,
}: IDropdownProps) {
  const classes = classNames({
    'std-dropdown flex flex-column': true,
    [className]: className.length > 0,
  });

  const dropdownClasses = classNames({
    'p-invalid': error.length > 0,
  });

  return (
    <div className={classes}>
      {label.length > 0 && (
        <label htmlFor={id} className="std-dropdown__label">
          {label}
        </label>
      )}
      <PrimeReactDropdown
        id={id}
        name={name}
        placeholder={placeholder}
        optionLabel={optionLabel}
        value={value}
        disabled={disabled}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        className={dropdownClasses}
        editable={editable}
        optionGroupLabel={optionGroupLabel}
        optionGroupChildren={optionGroupChildren}
        optionGroupTemplate={optionGroupTemplate}
        itemTemplate={itemTemplate}
        valueTemplate={valueTemplate}
        filter={filter}
        showClear={showClear}
      />
      {error.length > 0 && <div className="std-dropdown__error">{error}</div>}{' '}
    </div>
  );
}
