//@ts-nocheck
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4Themes from '@amcharts/amcharts4/themes/animated';

const loadLineChart = ({ id, data, options }: any) => {
  am4core.useTheme(am4Themes);

  const chart = am4core.create(id, am4charts.XYChart);

  chart.data = data || [];

  const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  xAxis.renderer.grid.template.location = 0;
  xAxis.dataFields.category = 'type';
  xAxis.renderer.minGridDistance = 40;
  xAxis.fontSize = 11;

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.labels.template.fill = '#9FA6A7';
  valueAxis.strictMinMax = true;
  valueAxis.renderer.minGridDistance = 30;

  chart.fontSize = 12;

  const series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = 'type';
  series.dataFields.valueY = 'count';
  series.columns.template.tooltipText = '{valueY.value}';
  series.columns.template.tooltipY = 0;
  series.columns.template.strokeOpacity = 0;
};

export default loadLineChart;
