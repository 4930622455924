import {
  DataTable,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
  Dropdown,
} from 'components';

import { locales } from 'constants/index';
import { humanReadable } from 'utils';

import columns from './columns';
import { ListSixInfoDialog } from './components';
import { ListSixUiProps, ListSixTicket } from './ListSix.types';

function MoreDetailsBodyTemplate(handleListSixInfoDialogShow: any) {
  return function (row: ListSixTicket) {
    return (
      <div
        className="az-tickets__listFive-details"
        role="presentation"
        onClick={handleListSixInfoDialogShow(row.id)}
      >
        Details
      </div>
    );
  };
}

export default function ListSixUi({
  isLoading,
  isListSixInfoVisible,
  totalRecords,
  filter,
  options,
  data: { selectedId, list },
  handlers,
}: ListSixUiProps) {
  return (
    <div className="az-tickets">
      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3">
            <form onSubmit={handlers.handleSubmitSearch}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="azadeaId"
                    name="azadeaId"
                    placeholder={'Azadea ID'}
                    value={filter.azadeaId || ''}
                    onChange={handlers.handleInputChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="endorsementStatus"
              name="endorsementStatus"
              placeholder={'Filter by endorsement status'}
              value={filter.endorsementStatus || ''}
              onChange={handlers.handlerEndorsementStatuses}
              options={options.endorsementStatuses}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="azadeaStatus"
              name="azadeaStatus"
              placeholder={'Filter by azadea status'}
              value={filter.azadeaStatus || ''}
              onChange={handlers.handleAzadeaStatusChange}
              options={options.azadeaStatuses}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handlerClearFilter}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          value={list}
          columns={columns.map((column) => {
            if (
              column.field === 'created' ||
              column.field === 'updated' ||
              column.field === 'sentdate' ||
              column.field === 'invoicedate'
            ) {
              return {
                ...column,
                body: (row: any) =>
                  !row[column.field] ? 'N/A' : humanReadable(row[column.field]),
              };
            }

            if (column.field === 'details_link') {
              return {
                ...column,
                body: MoreDetailsBodyTemplate(
                  handlers.handleListSixInfoDialogShow
                ),
              };
            }

            return {
              ...column,
              body: (row: any) =>
                !row[column.field] ? 'N/A' : row[column.field],
            };
          })}
        />
      )}

      {list?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="60rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}

      {isListSixInfoVisible && (
        <ListSixInfoDialog
          id={selectedId}
          onClose={handlers.handleListSixInfoDialogClose}
        />
      )}
    </div>
  );
}
