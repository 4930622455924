//@ts-nocheck
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

const loadLineChart = ({ id, data, options: { color } }: any) => {
  const chart = am4core.create(id, am4charts.XYChart);
  chart.data = data || [];
  chart.colors.list = [am4core.color(color)];
  chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

  const xAxis = chart.xAxes.push(new am4charts.DateAxis());
  xAxis.renderer.minGridDistance = 50;
  xAxis.renderer.line.strokeWidth = 0.5;
  xAxis.renderer.grid.template.location = 0.5;
  xAxis.startLocation = 0.5;
  xAxis.endLocation = 0.5;
  xAxis.renderer.gridContainer.__disabled = true;
  xAxis.renderer.labels.template.fill = '#9FA6A7';

  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis.tooltip.disabled = true;
  valueAxis.renderer.labels.template.fill = '#9FA6A7';

  chart.fontSize = 12;

  const series = chart.series.push(new am4charts.LineSeries());
  series.dataFields.valueY = 'value';
  series.dataFields.dateX = 'date';
  series.tooltipText = '{value}';
  series.strokeWidth = 2;
  series.minBulletDistance = 15;

  series.tooltip.getFillFromObject = true;
  series.tooltip.autoTextColor = false;
  series.segments.template.interactionsEnabled = true;

  chart.cursor = new am4charts.XYCursor();
  chart.cursor.lineY.strokeWidth = 0;
  chart.cursor.lineX.stroke = am4core.color(color);
  chart.cursor.lineX.strokeWidth = 0.5;
  chart.cursor.lineX.strokeOpacity = 1;
  chart.cursor.lineX.strokeDasharray = '';
  chart.cursor.zIndex = 0;
};

export default loadLineChart;
