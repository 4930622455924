import { useNavigate } from 'react-router-dom';

import { Button } from 'components';

import { Helpers, clearPhoneNumber } from 'utils';
import { locales, routes } from 'constants/index';

import { IAuthenticationSkeleton } from './AuthenticationSkeleton.types';

import './authentication-skeleton.scss';

export default function AuthenticationSkeleton({
  title = '',
  applyLabel = locales.login.title,
  isLoading,
  onContinue,
  children,
}: IAuthenticationSkeleton) {
  const navigate = useNavigate();

  const { formSubmit }: Helpers = new Helpers();

  function handleReset() {
    clearPhoneNumber();

    navigate(
      `${routes.authentication.index}/${routes.authentication.phoneRegister}`
    );
  }

  return (
    <div className="login">
      <div className="login__description">{title}</div>

      <form className="form" onSubmit={formSubmit}>
        {children}

        <div className="form__field form__field--login-btn flex flex-align-items-center">
          <div
            className="std-btn std-btn--clear-filter login__reset flex flex-align-items-center flex-justify-content-center"
            role="presentation"
            onClick={handleReset}
          >
            {locales.login.reset}
          </div>

          <Button
            onClick={onContinue}
            className="login__submit"
            disabled={isLoading}
            label={applyLabel}
          />
        </div>
      </form>
    </div>
  );
}
