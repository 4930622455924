import { useQuery } from '@tanstack/react-query';

import { fetchListFourApi } from '../api';

export default function useListFourQuery(params: any, options?: any) {
  return useQuery(
    ['list', 'four', params],
    () => fetchListFourApi(params),
    options
  );
}
