const columns = [
  {
    header: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    header: 'Follow Up Type',
    field: 'employeeId',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
  {
    header: 'Status Description',
    field: 'description',
    sortable: true,
  },
  {
    header: 'Status code',
    field: 'statusId',
    sortable: true,
  },
  {
    header: '< 1 Week',
    field: 'lessThanAWeek',
    sortable: true,
  },
  {
    header: '> 1 Week',
    field: 'moreThanAWeek',
    sortable: true,
  },
  {
    header: 'Total Count',
    field: 'total',
    sortable: true,
  },
];

export default columns;
