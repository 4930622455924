import { useQuery } from '@tanstack/react-query';

import { fetchListOneTicketDetailsApi } from '../api';

export default function useFetchListOneData(ticketId: string, options?: any) {
  return useQuery(
    ['ticket', 'fetch', 'info', ticketId],
    fetchListOneTicketDetailsApi,
    options
  );
}
