import { useQuery } from '@tanstack/react-query';

import { searchByAzadeaIdApi } from '../api';

export default function useSearchListThreeQuery(
  azadeaId: string,
  options?: any
) {
  return useQuery(
    ['list', 'three', azadeaId],
    () => searchByAzadeaIdApi(azadeaId),
    options
  );
}
