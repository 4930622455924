import { Dialog, Button, SkeletonLoader } from 'components';

import { humanReadable } from 'utils';
import { locales } from 'constants/index';

import { ListSixInfoDialogUiProps } from './ListSixInfoDialog.types';

export default function ListSixInfoDialog({
  isLoading,
  data: { invoiceInfo },
  onHide,
}: ListSixInfoDialogUiProps) {
  return (
    <Dialog
      visible
      maximizable
      header="Invoice Details"
      className="list-six-info-dialog"
      onHide={onHide}
      style={{ width: '40vw' }}
      footer={
        <Button outlined label={locales.general.close} onClick={onHide} />
      }
    >
      {isLoading ? (
        <>
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
          <SkeletonLoader height="2rem" />
        </>
      ) : (
        invoiceInfo &&
        Object.keys(invoiceInfo).map((key) => {
          return (
            <div
              key={key}
              className="invoice-info__label-value-pairs flex flex-justify-content-space-between"
            >
              {/* @ts-ignore */}
              <div className="invoice-info__label">{locales.listSix[key]}:</div>

              <div className="invoice-info__value">
                {key === 'updated' ||
                key === 'created' ||
                key === 'azadea_hrms_companycode' ||
                key === 'invoicedate' ||
                key === 'insurancestartdate' ||
                key === 'insuranceenddate'
                  ? humanReadable(invoiceInfo[key])
                  : //  @ts-ignore
                    invoiceInfo[key]}
              </div>
            </div>
          );
        })
      )}
    </Dialog>
  );
}
