import { useEffect } from 'react';

import ErrorFallbackUi from './ErrorFallback.ui';

import { enums, locales } from 'constants/index';

import './error-fallback.scss';
import { useState } from 'react';

const ErrorFallback = ({ error }: any) => {
  //* Local state
  const [errorMessage, setErrorMessage] = useState('');

  //* Handlers
  const handleRefreshClick = () => window.location.reload();

  const getErrorMessage = () => {
    switch (error.status) {
      case enums.statusCodes.forbidden:
        setErrorMessage(locales.errors.forbidden);
        break;
      case enums.statusCodes.notFound:
        setErrorMessage(locales.errors.notFound);
        break;
      case enums.statusCodes.conflict:
        setErrorMessage(locales.errors.conflict);
        break;
      default:
        setErrorMessage(locales.errors.generalDescription);
    }
  };

  useEffect(() => {
    getErrorMessage();
  }, []);

  return (
    <ErrorFallbackUi
      errorMessage={errorMessage}
      handleRefreshClick={handleRefreshClick}
    />
  );
};

export default ErrorFallback;
