import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError, useCountdownTimer } from 'hooks';
import { getPhoneNumber, setAccessToken, setRefreshToken } from 'utils';
import { routes, locales } from 'constants/index';

import { useConfigQueries } from 'modules/App/queries';

import { useLoginMutation, useResendOtpMutation } from '../mutations';

import LoginOtpVerificationUi from './LoginOtpVerification.ui';

export default function LoginOtpVerification() {
  //* Hooks Initialization
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();
  const {
    state: { pinCode },
  } = useLocation();
  const { countdownTimer, isResendOtpEnabled, startCountdownTimer } =
    useCountdownTimer();

  const [authConfig] = useConfigQueries();
  const loginMutation = useLoginMutation({
    onSuccess: handleVerifyOtpSuccess,
  });
  const resendOtpMutation = useResendOtpMutation();

  //* Local States
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');

  //* Handlers
  function handleOtpChange(otp: string) {
    setOtp(otp);

    setOtpError('');
  }

  function handleVerifyOtpSuccess({ data }: any) {
    if (data?.token && data?.refresh_token) {
      const { refresh_token: refreshToken, token: accessToken } = data;

      setAccessToken(accessToken, authConfig.data?.data.tokensVariables.token);
      setRefreshToken(
        refreshToken,
        authConfig.data?.data.tokensVariables.refreshToken || 0
      );

      if (data.user.userTypes.length > 1) return navigate(routes.role);

      return navigate(routes.main);
    }

    navigate(routes.authentication.index);
  }

  function handleVerifyOtp() {
    if (!otp) return setOtpError(locales.login.errors.otpRequired);

    loginMutation.mutate({
      phoneNumber: `${getPhoneNumber().callingCode}${
        getPhoneNumber().phoneNumber
      }`,
      pinCode,
      otp,
    });
  }

  function handleResendOtp() {
    setOtp('');
    startCountdownTimer();

    resendOtpMutation.mutate({
      phoneNumber: `${getPhoneNumber().callingCode}${
        getPhoneNumber().phoneNumber
      }`,
      pinCode,
    });
  }

  useEffect(() => {
    if (loginMutation.isError) {
      const errorMessage = extractErrorMessage(loginMutation.error);
      toastRef.current?.show({ severity: 'error', detail: errorMessage });
    }

    if (resendOtpMutation.isError) {
      const errorMessage = extractErrorMessage(resendOtpMutation.error);
      toastRef.current?.show({ severity: 'error', detail: errorMessage });
    }
  }, [loginMutation.isError, resendOtpMutation.isError]);

  const data = { otp };

  const handlers = {
    handleOtpChange,
    handleVerifyOtp,
    handleResendOtp,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <LoginOtpVerificationUi
        isLoading={loginMutation.isLoading || resendOtpMutation.isLoading}
        isResendOtpEnabled={isResendOtpEnabled}
        countdownTimer={countdownTimer}
        otpError={otpError}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
