import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Toast } from 'primereact/toast';

import { routes, locales } from 'constants/index';
import {
  getPhoneNumber,
  setAccessToken,
  setRefreshToken,
  setUserId,
} from 'utils';
import { useExtractServerError } from 'hooks';
import { authenticateUser } from 'modules/App/store/actions';
import { useConfigQueries, useUserQuery } from 'modules/App/queries';

import { useLoginMutation } from '../mutations';

import LoginUi from './Login.ui';

export default function Login() {
  //* Hooks Init
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  //* Queries
  const [authConfig] = useConfigQueries();
  const userQuery = useUserQuery();
  const loginMutation = useLoginMutation({ onSuccess: handleLoginSuccess });

  //* Local State
  const [pinCode, setPinCode] = useState('');
  const [pinCodeError, setPinCodeError] = useState('');

  function handlePinCodeChange(pinCode: string) {
    setPinCode(pinCode);
    setPinCodeError('');
  }

  function handleLogin() {
    if (!pinCode) return setPinCodeError(locales.login.errors.pinRequired);

    loginMutation.mutate({
      phoneNumber: `${getPhoneNumber().callingCode}${
        getPhoneNumber().phoneNumber
      }`,
      pinCode,
    });
  }

  function handleLoginSuccess({ data: { data } }: any) {
    if (data.pendingApproval && data.message) setPinCodeError(data.message);

    if (data.requireOTP && data.message) {
      navigate(
        `${routes.authentication.index}/${routes.authentication.loginOtpVerification}`,
        {
          state: {
            pinCode,
          },
        }
      );
      return;
    }

    if (data.token && data.refresh_token) {
      setAccessToken(data.token, authConfig.data?.data.tokensVariables.token);
      setRefreshToken(
        data.refresh_token,
        authConfig.data?.data.tokensVariables.refreshToken || 0
      );
      setUserId(data.user.userId);

      userQuery.refetch();

      dispatch(
        authenticateUser({
          accessToken: data.token,
          refreshToken: data.refresh_token,
        })
      );

      if (data.user.userTypes.length > 1) return navigate(routes.role);

      return navigate(routes.main);
    }

    navigate(routes.authentication.index);
  }

  useEffect(() => {
    if (loginMutation.isError) {
      const errorMessage = extractErrorMessage(loginMutation.error);
      toastRef.current?.show({ severity: 'error', detail: errorMessage });
    }
  }, [loginMutation.isError]);

  useEffect(() => {
    if (!getPhoneNumber().phoneNumber)
      navigate(
        `${routes.authentication.index}/${routes.authentication.phoneRegister}`
      );
  });

  const data = {
    pinCode,
    platform: locales.general.mobileNumber.toLowerCase(),
    phoneNumber: `${getPhoneNumber().phoneNumber}${
      getPhoneNumber().callingCode
    }`,
  };

  const handlers = {
    handlePinCodeChange,
    handleLogin,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <LoginUi
        isLoading={loginMutation.isLoading}
        pinCodeError={pinCodeError}
        handlers={handlers}
        data={data}
      />
    </>
  );
}
