import { useQuery } from '@tanstack/react-query';

import { fetchListOneTicketStudiesApi } from '../api';

export default function useFetchListOneData(ticketId: string, options?: any) {
  return useQuery(
    ['tickets', 'history', ticketId],
    fetchListOneTicketStudiesApi,
    options
  );
}
