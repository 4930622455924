import { Link } from 'react-router-dom';

import { ListSixInfoDialog } from 'modules/ListSix/components';
import {
  DataTable,
  Dropdown,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
  Chip,
  Calendar,
} from 'components';

import { locales, routes } from 'constants/index';
import { humanReadable } from 'utils';

import {
  InvoiceInfoDialog,
  InvoiceRejectionDialog,
  InvoiceApprovalDialog,
} from '../components';

import columns from './columns';
import listSixColumns from './listSixColumns';
import {
  NeedForApprovalListingUiProps,
  ListFiveTicket,
  ListSixTicket,
} from './ListFiveNeedForApprovalListing.types';

import './list-five-need-for-approval-listing.scss';

function MoreDetailsBodyTemplate(handleShowListFiveRowDetails: any) {
  return function (row: ListFiveTicket) {
    return (
      <div
        className="az-tickets__ListFive-details"
        role="presentation"
        onClick={handleShowListFiveRowDetails(row.clover_invoice)}
      >
        Details
      </div>
    );
  };
}

function LinkSixBodyTemplate(row: ListSixTicket) {
  return (
    <Link to={`${routes.listSix.index}/${row.endorsement_id}`}>Read More</Link>
  );
}

function ListSixMoreDetailsBodyTemplate(handleListSixInfoDialogShow: any) {
  return function (row: ListSixTicket) {
    return (
      <div
        className="az-tickets__listFive-details"
        role="presentation"
        onClick={handleListSixInfoDialogShow(row.id)}
      >
        Details
      </div>
    );
  };
}

function DateBodyTemplate(field: 'invoicedate' | 'created' | 'updated') {
  return function (row: ListSixTicket) {
    return humanReadable(row[field]);
  };
}

function ListSixStatusTemplate(row: ListSixTicket) {
  if (!!row.status_manual_approval) {
    const isApproved = row.status_manual_approval === 'Approved';

    return (
      <Chip
        label={row.status_manual_approval}
        color={isApproved ? 'success' : 'danger'}
      />
    );
  } else {
    return <Chip label="Pending" color="warning" />;
  }
}

export default function NeedForApprovalListingUi({
  isLoading,
  isListSixLoading,
  isExporting,
  isRejectionDialogVisible,
  isApproveModalVisible,
  isInvoiceInfoVisible,
  isListSixInfoVisible,
  totalRecords,
  listSixTotalRecords,
  options,
  expandedRows,
  filter,
  listSixFilter,
  data,
  handlers,
}: NeedForApprovalListingUiProps) {
  if (!columns[0]?.expander)
    columns.unshift({
      expander: true,
    });

  function listSixTable() {
    return isListSixLoading ? (
      <SkeletonLoader height="10rem" />
    ) : (
      <>
        <DataTable
          className="list-six-table"
          sortMode="multiple"
          header={
            <div className="az-tickets__actions flex flex-align-items-center">
              <Button
                className="az-tickets__approve"
                onClick={handlers.handleApprove}
                label="Approve"
                disabled={data.selectedEmployees.length === 0}
              />

              <Button
                onClick={handlers.handleShowRejectionModal}
                label="Reject"
                disabled={data.selectedEmployees.length === 0}
              />
            </div>
          }
          totalRecords={listSixTotalRecords}
          selectionMode="checkbox"
          onSelectionChange={handlers.handleSelectionChange}
          selection={data.selectedEmployees}
          dataKey="id"
          columns={[
            {
              selectionMode: 'multiple',
              headerStyle: { width: '5rem' },
            },
            ...listSixColumns.map((column) => {
              if (
                column.field === 'invoicedate' ||
                column.field === 'updated'
              ) {
                return {
                  ...column,
                  body: DateBodyTemplate(column.field),
                };
              }

              if (column.field === 'difference_total') {
                return {
                  ...column,
                  body: (row: any) => {
                    return (
                      <Chip
                        style={{ backgroundColor: row.color_difference }}
                        label={
                          row.difference_total ? row.difference_total : 'N/A'
                        }
                      />
                    );
                  },
                };
              }

              if (column.field === 'status_manual_approval') {
                return {
                  ...column,
                  body: ListSixStatusTemplate,
                };
              }

              if (column.field === 'details_link') {
                return {
                  ...column,
                  body: ListSixMoreDetailsBodyTemplate(
                    handlers.handleListSixInfoDialogShow
                  ),
                };
              }

              return {
                ...column,
                body: (row: any) =>
                  column.field && !row[column.field]
                    ? 'N/A'
                    : column.field && row[column.field],
              };
            }),
          ]}
          value={data.listSixData}
        />

        {data.listSixData?.length > 0 &&
          (isListSixLoading ? (
            <SkeletonLoader height="3rem" width="40rem" />
          ) : (
            <Paginator
              first={listSixFilter.first}
              rows={listSixFilter.rows}
              totalRecords={listSixTotalRecords}
              rowsPerPageOptions={[10, 50, 100]}
              onPageChange={handlers.handleListSixPageChange}
            />
          ))}
      </>
    );
  }

  return (
    <div className="list-five-need-for-approval-listing">
      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-4 lg:col-2">
            <form onSubmit={handlers.handleSubmitSearch}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="invoiceNumber"
                    name="invoiceNumber"
                    placeholder={'Invoice number'}
                    value={filter.invoiceNumber || ''}
                    onChange={handlers.handleInputChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-4 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="exceptionId"
              name="exceptionId"
              placeholder={'Filter by exception'}
              value={filter.exceptionId || ''}
              onChange={handlers.handleExceptionChange}
              options={options.exceptions}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-4 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="country"
              name="country"
              placeholder="Filter by country"
              value={filter.country}
              onChange={handlers.handleCountryChange}
              options={options.countries}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="4.4rem" />
          ) : (
            <Calendar
              placeholder="Filter by date"
              selectionMode="range"
              onChange={handlers.handleDateChange}
              value={filter.dates}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-4 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              className="az-tickets__download"
              disabled={!data.list || data.list.length === 0}
              onClick={handlers.handleDownload}
              loading={isExporting}
              label={locales.general.download}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleClearFilter}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>
      </div>
      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          dataKey="clover_invoice"
          totalRecords={totalRecords}
          expandedRows={expandedRows}
          rowExpansionTemplate={listSixTable}
          onRowExpand={handlers.handleRowExpand}
          onRowCollapse={handlers.handleRowCollapse}
          onRowToggle={handlers.handleRowToggle}
          columns={columns.map((column) => {
            if (
              column.field === 'created' ||
              column.field === 'updated' ||
              column.field === 'azadea_forceapprovaldate'
            ) {
              return {
                ...column,
                body: (row: any) =>
                  column.field && !row[column.field]
                    ? 'N/A'
                    : humanReadable(column.field && row[column.field]),
              };
            }

            if (column.field === 'more_details') {
              return {
                ...column,
                body: MoreDetailsBodyTemplate(
                  handlers.handleShowListFiveRowDetails
                ),
              };
            }

            if (column.field === 'list_6_details') {
              return {
                ...column,
                body: LinkSixBodyTemplate,
              };
            }

            if (column.expander) {
              return column;
            }

            return {
              ...column,
              body: (row: any) =>
                column.field && !row[column.field]
                  ? 'N/A'
                  : column.field && row[column.field],
            };
          })}
          value={data.list}
        />
      )}
      {data.list?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}
      <InvoiceApprovalDialog
        selectedInvoices={data.selectedEmployees}
        visible={isApproveModalVisible}
        onHide={handlers.handleCancelApprove}
        footer={
          <>
            <Button
              onClick={handlers.handleCancelApprove}
              outlined
              label={locales.general.close}
            />

            <Button onClick={handlers.handleConfirmApprove} label={'Confirm'} />
          </>
        }
      />
      {data.invoiceInfo && (
        <InvoiceInfoDialog
          invoiceInfo={data.invoiceInfo}
          visible={isInvoiceInfoVisible}
          onHide={handlers.handleHideListFiveRowDetails}
          footer={
            <Button
              onClick={handlers.handleHideListFiveRowDetails}
              outlined
              label={locales.general.close}
            />
          }
        />
      )}
      <InvoiceRejectionDialog
        onHide={handlers.handleCloseRejectionModal}
        visible={isRejectionDialogVisible}
        rejectionInfo={data.rejectionInfo}
        rejectionOptions={options.rejectionReasons}
        handleRejectionDescriptionChange={
          handlers.handleRejectionDescriptionChange
        }
        handleRejectionReasonChange={handlers.handleRejectionReasonChange}
        footer={
          <>
            <Button
              onClick={handlers.handleCloseRejectionModal}
              outlined
              label={locales.general.close}
            />

            <Button
              onClick={handlers.handleConfirmReject}
              disabled={
                data.rejectionInfo.id.length === 0 ||
                data.rejectionInfo.description.length === 0
              }
              label={'Confirm'}
            />
          </>
        }
      />

      {isListSixInfoVisible && (
        <ListSixInfoDialog
          id={data.listSixSelectedId}
          onClose={handlers.handleListSixInfoDialogClose}
        />
      )}
    </div>
  );
}
