import { Dialog, InputText, Dropdown } from 'components';

import { InvoiceRejectionDialogProps } from './InvoiceRejectionDialog.types';

export default function InvoiceRejectionDialog({
  onHide,
  footer,
  visible,
  rejectionInfo,
  rejectionOptions,
  handleRejectionDescriptionChange,
  handleRejectionReasonChange,
}: InvoiceRejectionDialogProps) {
  return (
    <Dialog
      header="Confirm rejection"
      footer={footer}
      onHide={onHide}
      visible={visible}
      className="invoice-info"
      style={{ width: '30vw' }}
      position="top"
    >
      <div className="list-five-ticket-rejection-modal__body flex flex-column">
        <div className="field">
          <InputText
            placeholder="Description"
            id="description"
            name="description"
            value={rejectionInfo.description}
            onChange={handleRejectionDescriptionChange}
          />
        </div>

        <div className="field">
          <Dropdown
            id="id"
            name="id"
            placeholder="Rejection Reason"
            value={rejectionInfo.id}
            options={rejectionOptions}
            onChange={handleRejectionReasonChange}
            filter
            showClear
          />
        </div>
      </div>
    </Dialog>
  );
}
