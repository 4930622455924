import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { getGermanDate } from 'utils';

import { DataTable, Dropdown, SkeletonLoader, Button } from 'components';

import { locales, routes } from 'constants/index';

import columns from './columns';

import {
  ListOneStatusesListingUiProps,
  ListOneTicketStatus,
} from './ListOneStatusesListing.types';

function TypeBodyTemplate(row: ListOneTicketStatus) {
  return (
    <span
      className={`colored-circle colored-circle--${row.type.toLowerCase()}`}
    >
      {row.type}
    </span>
  );
}

function LessThanAWeekLinkBodyTemplate(row: ListOneTicketStatus) {
  return (
    <Link
      to={`${routes.listOne.index}?statusCodes=${row.statusId}&ticketType=${
        row.type
      }&startDate=${getGermanDate(
        dayjs().subtract(7, 'days')
      )}&endDate=${getGermanDate(dayjs())}`}
    >
      {row.lessThanAWeek}
    </Link>
  );
}

function MoreThanAWeekLinkBodyTemplate(row: ListOneTicketStatus) {
  return (
    <Link
      to={`${routes.listOne.index}?statusCodes=${row.statusId}&ticketType=${
        row.type
      }&startDate=${getGermanDate(
        dayjs().subtract(1, 'year')
      )}&endDate=${getGermanDate(dayjs())}`}
    >
      {row.moreThanAWeek}
    </Link>
  );
}

function TotalLinkBodyTemplate(row: ListOneTicketStatus) {
  return (
    <Link
      to={`${routes.listOne.index}?statusCodes=${row.statusId}&ticketType=${row.type}`}
    >
      {row.total}
    </Link>
  );
}

export default function ListOneStatusesListingUi({
  isLoading,
  filter,
  options,
  data,
  handlers,
}: ListOneStatusesListingUiProps) {
  return (
    <>
      {isLoading ? (
        <SkeletonLoader height="3.5rem" />
      ) : (
        <div className="formgroup grid align-items-end">
          <div className="field sm:col-12 md:col-6 lg:col-2">
            <Dropdown
              id="ticketType"
              name="ticketType"
              placeholder={locales.general.filterByType}
              value={filter.ticketType ? filter.ticketType : ''}
              onChange={handlers.handleTicketTypeChange}
              options={options.ticketTypes}
            />
          </div>

          <div className="field sm:col-12 md:col-6 lg:col-2">
            {isLoading ? (
              <SkeletonLoader height="3.5rem" />
            ) : (
              <Button
                className="clear-filter-button"
                onClick={handlers.handleFilterClear}
                label={locales.general.clearFilter}
                outlined
              />
            )}
          </div>
        </div>
      )}

      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={data.tickets.length}
          columns={columns.map((column) => {
            if (column.field === 'type') {
              return {
                ...column,
                body: TypeBodyTemplate,
              };
            }

            if (column.field === 'lessThanAWeek') {
              return {
                ...column,
                body: LessThanAWeekLinkBodyTemplate,
              };
            }

            if (column.field === 'moreThanAWeek') {
              return {
                ...column,
                body: MoreThanAWeekLinkBodyTemplate,
              };
            }

            if (column.field === 'total') {
              return {
                ...column,
                body: TotalLinkBodyTemplate,
              };
            }

            return {
              ...column,
              body: (row: ListOneTicketStatus) =>
                // @ts-ignore
                !row[column.field] ? 'N/A' : row[column.field],
            };
          })}
          value={data.tickets}
        />
      )}
    </>
  );
}
