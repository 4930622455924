import { Dialog } from 'components';

import { ListOneDeleteOpenPendingTicketConfirmationDialogProps } from './ListOneDeleteOpenPendingTicketConfirmationDialog.types';

export default function ListOneDeleteOpenPendingTicketConfirmationDialog({
  onHide,
  footer,
  visible,
  ticketId,
}: ListOneDeleteOpenPendingTicketConfirmationDialogProps) {
  return (
    <Dialog
      header="Confirm deletion"
      footer={footer}
      onHide={onHide}
      visible={visible}
      style={{ width: '40vw' }}
      position="top"
    >
      Are you sure you want to delete the ticket with this ID{' '}
      <span style={{ color: '#b50000' }}>{ticketId}</span>?
    </Dialog>
  );
}
