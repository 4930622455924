import { DataTable, SkeletonLoader } from 'components';

import { humanReadable } from 'utils';

import columns from './columns';
import {
  ListTwoTicketStudiesUiProps,
  ListTwoTicketStudy,
} from './ListTwoTicketStudies.types';

function TypeBodyTemplate(row: ListTwoTicketStudy) {
  return (
    <span
      className={`colored-circle colored-circle--${row.type.toLowerCase()}`}
    >
      {row.type}
    </span>
  );
}

function CreatedDateBodyTemplate(row: ListTwoTicketStudy) {
  return humanReadable(row.createdDate);
}

export default function ListTwoTicketStudies({
  isLoading,
  totalRecords,
  data,
  handlers,
}: ListTwoTicketStudiesUiProps) {
  return isLoading ? (
    <SkeletonLoader height="4.4rem" width="8rem" />
  ) : (
    <DataTable
      sortMode="multiple"
      totalRecords={totalRecords}
      columns={columns.map((column) => {
        if (column.field === 'type') {
          return {
            ...column,
            body: TypeBodyTemplate,
          };
        }

        if (column.field === 'createdDate') {
          return {
            ...column,
            body: CreatedDateBodyTemplate,
          };
        }

        return column;
      })}
      value={data.studies}
      onRowClick={handlers.handleRowClick}
    />
  );
}
