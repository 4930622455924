import classNames from 'classnames';

import { InfoSideBar, SkeletonLoader, DataTable } from 'components';

import columns from './columns';

import { IListOneTicketDetailsProps } from './ListOneTicketDetails.types';

import './list-one-ticket-details.scss';

function TypeColumnTemplate(row: any) {
  return (
    <div
      className={classNames({
        'list-one-ticket-details__type': true,
        'list-one-ticket-details__type--red':
          row.type === 'format' ||
          row.type === 'mandatory' ||
          row.type === 'updated',
      })}
    >
      {row.type}
    </div>
  );
}

export default function ListOneTicketDetailsUi({
  isLoading,
  isInfoSideBarVisible,
  data,
  handlers,
}: IListOneTicketDetailsProps) {
  return (
    <div className="list-one-ticket-details">
      <div
        className="list-one-ticket-details__slide"
        role="presentation"
        onClick={handlers.handleRowClick}
      />

      <InfoSideBar
        isVisible={isInfoSideBarVisible}
        onClose={handlers.handleSideBarClose}
        ticketDetails={{
          id:
            data.ticketDetails?.id && data.ticketDetails?.id.length > 0
              ? data.ticketDetails?.id
              : data.ticketId,
          type: data.ticketDetails?.type,
          studyId: data.ticketDetails?.studyId,
          employeeId: data.ticketDetails?.employeeId,
          status: data.ticketDetails?.status,
          statusDescription: data.ticketDetails?.statusDescription,
          createdDate: data.ticketDetails?.createdDate,
          updatedDate: data.ticketDetails?.updatedDate,
        }}
      />

      {isLoading ? (
        <SkeletonLoader height="8rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={data.ticketDetails?.fields?.length}
          value={data.ticketDetails?.fields}
          columns={columns.map((column) => {
            if (column.field === 'type') {
              return {
                ...column,
                body: TypeColumnTemplate,
              };
            }

            return column;
          })}
        />
      )}
    </div>
  );
}
