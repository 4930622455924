import { Dialog, Button } from 'components';

import { CompanyCodeDiscrepanciesDialogProps } from './CompanyCodeDiscrepanciesDialog.types';

export default function CompanyCodeDiscrepanciesDialog({
  title,
  onHide,
  data,
}: CompanyCodeDiscrepanciesDialogProps) {
  return (
    <Dialog
      style={{ width: '40vw' }}
      header={title}
      visible={data.length > 0}
      onHide={onHide}
      footer={<Button onClick={onHide}>Close</Button>}
    >
      {data.map((item) => (
        <div
          key={item.companyCode}
          className="invoice-info-dialog__label-value-pairs flex flex-justify-content-space-between"
        >
          <div className="invoice-info-dialog__label">
            Code: <strong>{item.companyCode}</strong>
          </div>

          <div className="invoice-info-dialog__value">
            Missing from: <strong>{item.missingFromLists.join(', ')}</strong>
          </div>
        </div>
      ))}
    </Dialog>
  );
}
