import { api } from 'utils';

import {
  IFetchListZeroBriefApiResponse,
  IFetchListOneBriefApiResponse,
  IFetchListOneStatusesStatsApiResponse,
  IFetchListTwoBriefApiResponse,
  IFetchListFourBriefApiResponse,
  IFetchListFiveBriefApiResponse,
  IFetchStatusApiResponse,
  IFetchListThreeBriefApiResponse,
  IFetchCompanyCodeDiscrepancyResponse,
} from './api.types';

export function fetchListZeroBriefApi() {
  return api().get<IFetchListZeroBriefApiResponse>('/tickets/fetch/recent');
}

export function fetchListOneBriefApi(filter: any) {
  return api().get<IFetchListOneBriefApiResponse>('/tickets/brief', {
    params: filter,
  });
}

export function fetchTicketStatusStatsApi() {
  return api().get<IFetchListOneStatusesStatsApiResponse>(
    '/tickets/status/stats'
  );
}

export function fetchListTwoBriefApi(filter: any) {
  return api().get<IFetchListTwoBriefApiResponse>('/tickets/list/two/brief', {
    params: filter,
  });
}

export function fetchListThreeBriefApi() {
  return api().get<IFetchListThreeBriefApiResponse>('/list/three/statistics');
}

export function fetchListFourBriefApi() {
  return api().get<IFetchListFourBriefApiResponse>('/list/four/statistics');
}

export function fetchListFiveBriefApi() {
  return api().get<IFetchListFiveBriefApiResponse>('/list/five/statistics');
}

export function fetchStatusApi() {
  return api().get<IFetchStatusApiResponse>('/list/check');
}

export function fetchCompanyCodeDiscrepancy() {
  return api().get<IFetchCompanyCodeDiscrepancyResponse>(
    '/endorsement/health/companyCodeDiscrepancy'
  );
}
