import { useQuery } from '@tanstack/react-query';

import { fetchListTwoHistoryLogsApi } from '../api';

export default function useListTwoLogsData(filter: any, options?: any) {
  return useQuery(
    ['list', 'two', 'logs', filter],
    () => fetchListTwoHistoryLogsApi(filter),
    options
  );
}
