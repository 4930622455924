import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';

import { useConfigQueries } from 'modules/App/queries';

import { useListFourQuery } from './queries';
import { useListFourMutation } from './mutations';

import ListFourUi from './ListFour.ui';

import {
  ListFourFilter,
  ListFourParams,
  DropdownChangeEvent,
  PaginatorPageChangeEvent,
} from './ListFour.types';

const ListFour = () => {
  //* Hooks
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();
  const [searchParams, setSearchParams] = useSearchParams();

  let params: ListFourParams = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    endorsementId: searchParams.get('endorsementId') || '',
    endorsementType: searchParams.get('endorsementType') || '',
    status: searchParams.get('status') || '',
  };

  //* Queries & Mutations
  const [, endorsementConfig] = useConfigQueries();
  const listFourQuery = useListFourQuery(params);
  const listFourMutation = useListFourMutation({
    onSuccess: handleDownloadSuccess,
  });

  //* Local State
  const [filter, setFilter] = useState<ListFourFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    endorsementId: params.endorsementId,
    endorsementType: params.endorsementType,
    status: params.status,
  });

  //* Handlers
  function handleInputChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    setFilter((filter) => ({
      ...filter,
      endorsementId: value.trim(),
    }));
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.endorsementId.value.length > 0) {
      setSearchParams({
        ...params,
        page: '1',
        rows: '50',
        first: '0',
        endorsementId: event.target.elements.endorsementId.value.trim(),
      });
      setFilter((filter) => ({
        ...filter,
        page: 1,
        rows: 50,
        first: 0,
      }));
    } else {
      const { endorsementId, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      endorsementId: event.target.elements.endorsementId.value,
    }));
  }

  function handleClearAllFilters() {
    setSearchParams({
      page: '1',
      rows: '50',
      first: '0',
      endorsementId: '',
      endorsementType: '',
      status: '',
    });

    setFilter({
      page: 1,
      rows: 50,
      first: 0,
      endorsementId: '',
      endorsementType: '',
      status: '',
    });
  }

  function handleStatusChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      status: event.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      status: event.value,
      page: 1,
    }));
  }

  function handleEndorsementTypeChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      endorsementType: event.value,
      page: '1',
    });

    setFilter((filter) => ({
      ...filter,
      endorsementType: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...params,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleDownload() {
    listFourMutation.mutate({ ...filter, exportable: true });
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  useEffect(() => {
    if (listFourQuery.isError) {
      const errorMessage = extractErrorMessage(listFourQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listFourQuery.isError]);

  const options = {
    endorsementTypes:
      endorsementConfig.data?.data.ticketsType.map(({ key, value }) => {
        if (key === 'All') {
          return {
            key,
            label: key,
            value: key,
          };
        }
        return {
          key: value,
          label: key,
          value,
        };
      }) || [],
    endorsementStatuses:
      endorsementConfig.data?.data.listStatuses
        .find(({ list }) => list === 4)
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
  };

  const data = { list: listFourQuery.data?.data.data || [] };

  const handlers = {
    handleDownload,
    handleClearAllFilters,
    handlePageChange,
    handleInputChange,
    handleStatusChange,
    handleSubmitSearch,
    handleEndorsementTypeChange,
  };

  return (
    <ListFourUi
      isLoading={listFourQuery.isLoading}
      isExporting={listFourMutation.isLoading}
      totalRecords={listFourQuery.data?.data.total || 0}
      options={options}
      filter={filter}
      data={data}
      handlers={handlers}
    />
  );
};

export default ListFour;
