import { api } from 'utils';

import {
  FetchListThreeApiResponse,
  SearchByAzadeaIdApiResponse,
  FetchListThreeTicketDetailsResponse,
} from './api.types';

export function fetchListThreeApi(filter: any) {
  return api().get<FetchListThreeApiResponse>('/tickets/list/three/fetch', {
    params: {
      page: filter.exportable ? undefined : filter.page,
      limit: filter.exportable ? undefined : filter.rows,
      country: filter.country,
      searchString: filter.azadeaId,
      exportable: filter.exportable,
    },
  });
}

export function searchByAzadeaIdApi(azadeaId: string) {
  return api().get<SearchByAzadeaIdApiResponse>('/tickets/list/three/search', {
    params: { employeeId: azadeaId },
  });
}

export function fetchListThreeTicketDetails(employeeId: string) {
  return api().get<FetchListThreeTicketDetailsResponse>(
    '/tickets/list/three/search',
    {
      params: { employeeId },
    }
  );
}
