import { useEffect } from 'react';

import loadBarChart from './config';

import { TBarChart } from './BarChart.types';

const BarChart: TBarChart = ({ id, data, className = '', options }) => {
  useEffect(() => {
    loadBarChart({ id, data, options });
  }, [id, data, options]);

  return <div id={id} className={className}></div>;
};

export default BarChart;
