import { Otp, Button, Spinner, Dialog } from 'components';

import { Helpers } from 'utils';
import { locales } from 'constants/index';

import { IPersonalPinUiProps } from './PersonalPin.types';

import './personal-pin.scss';

export default function PersonalPinUi({
  isLoading,
  isConfirmationDialogOpen,
  errors,
  data: { pin, pinConfirmation, firstName, lastName },
  handlers,
}: IPersonalPinUiProps) {
  const { formSubmit }: Helpers = new Helpers();

  if (isLoading) return <Spinner />;

  return (
    <div className="personal-pin">
      <div className="personal-pin__description">
        <div className="personal-pin__title">
          {`${locales.personalPin.welcome} ${firstName} ${lastName},`}
        </div>

        {locales.personalPin.title}
      </div>

      <form className="form" onSubmit={formSubmit}>
        <div className="form__field">
          <div className="personal-pin__label">{locales.personalPin.label}</div>

          <Otp
            value={pin}
            numInput={6}
            onChange={handlers.handlePinChange}
            error={errors.pin}
          />
        </div>
        <div className="form__field">
          <div className="personal-pin__label">
            {locales.personalPin.confirmation}
          </div>

          <Otp
            value={pinConfirmation}
            numInput={6}
            onChange={handlers.handlePinConfirmationChange}
            error={errors.pinConfirmation}
          />
        </div>

        <div className="form__field flex">
          <Button
            onClick={handlers.handleSubmit}
            className="personal-pin__submit"
            label={locales.general.continue}
          />
        </div>
      </form>

      <Dialog
        visible={isConfirmationDialogOpen}
        header="Registration Successful"
        onHide={handlers.handleConfirmationDialogHide}
        style={{ width: '40vw' }}
        className="success-dialog"
        footer={
          <Button
            onClick={handlers.handleConfirmationDialogHide}
            outlined
            label={locales.general.ok}
          />
        }
      >
        <span className="success-dialog__image" />
        Congratulations! You have successfully registered your account. Your
        account will be approved by admin team soon.
      </Dialog>
    </div>
  );
}
