import { IAuthStore, Action } from './types';
import { USER_DETAILS_SUBMITTED, CLEAR_AUTH_STORE } from './action-types';

const initialState: IAuthStore = {
  firstName: '',
  lastName: '',
  email: '',
  yearOfBirth: '',
};

const reducer = (state: IAuthStore = initialState, action: Action) => {
  switch (action.type) {
    case USER_DETAILS_SUBMITTED:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        yearOfBirth: action.yearOfBirth,
      };

    case CLEAR_AUTH_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
