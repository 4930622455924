const columns = [
  {
    header: 'Azadea ID',
    field: 'employee_staff_id',
    sortable: true,
  },
  {
    header: 'Name',
    field: 'first_name',
    sortable: true,
  },

  {
    header: 'Company',
    field: 'company_name',
    sortable: true,
  },
  {
    header: 'Country',
    field: 'country',
    sortable: true,
  },
  {
    header: 'Class',
    field: 'class',
    sortable: true,
  },
  {
    header: 'Policy',
    field: 'policy',
    sortable: true,
  },
  {
    header: 'Life Category',
    field: 'life_category',
    sortable: true,
  },
  {
    header: 'Health Category',
    field: 'health_category',
    sortable: true,
  },
  {
    header: 'Detailed Info​',
    field: 'detailedInfo',
  },
  {
    header: 'History Tickets​',
    field: 'historyTickets',
  },
];

export default columns;
