import { useQuery } from '@tanstack/react-query';

import { fetchListOneStatusesBriefApi } from '../api';

export default function useListOneStatusesQuery(params: any, options?: any) {
  return useQuery(
    ['tickets', 'status', 'brief', params],
    fetchListOneStatusesBriefApi,
    options
  );
}
