// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/success.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".campaign-consent-dialog__success {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  width: 7rem;\n  height: 7rem;\n  margin-bottom: 3.6rem;\n  margin-inline: auto;\n}\n.campaign-consent-dialog__message {\n  font-size: 1.6rem;\n  font-weight: 500;\n  text-align: center;\n  margin-bottom: 3.2rem;\n}\n.campaign-consent-dialog .p-dialog-footer button {\n  width: 100%;\n  max-width: 8rem;\n}", "",{"version":3,"sources":["webpack://./src/modules/Campaign/CampaignConsent/campaign-consent.scss"],"names":[],"mappings":"AAGE;EACE,yDAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AAHJ;AAME;EACE,WAAA;EACA,eAAA;AAJJ","sourcesContent":["@import '../../../styles/theme';\n\n.campaign-consent-dialog {\n  &__success {\n    background-image: url('../../../assets/images/success.png');\n    background-size: cover;\n    width: 7rem;\n    height: 7rem;\n    margin-bottom: 3.6rem;\n    margin-inline: auto;\n  }\n\n  &__message {\n    font-size: 1.6rem;\n    font-weight: 500;\n    text-align: center;\n    margin-bottom: 3.2rem;\n  }\n\n  .p-dialog-footer button {\n    width: 100%;\n    max-width: 8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
