import classNames from 'classnames';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { TabView, TabPanel } from 'primereact/tabview';

import { routes } from 'constants/index';

import './list-five-layout.scss';

export default function ListFiveLayout() {
  const { pathname } = useLocation();

  return (
    <div className="list-five-layout">
      <TabView
        activeIndex={pathname.includes(routes.listFive.needApproval) ? 0 : 1}
      >
        <TabPanel
          header={
            <Link
              to={routes.listFive.needApproval}
              className={classNames({
                'list-five-layout__invoice-tabs-tab': true,
                'list-five-layout__invoice-tabs-tab--active': pathname.includes(
                  routes.listFive.needApproval
                ),
              })}
              role="presentation"
            >
              Need for approval
            </Link>
          }
        >
          <Outlet />
        </TabPanel>

        <TabPanel
          header={
            <Link
              to={routes.listFive.otherInvoices}
              className={classNames({
                'list-five-layout__invoice-tabs-tab': true,
                'list-five-layout__invoice-tabs-tab--active': pathname.includes(
                  routes.listFive.otherInvoices
                ),
              })}
              role="presentation"
            >
              Other invoices
            </Link>
          }
        >
          <Outlet />
        </TabPanel>
      </TabView>
    </div>
  );
}
