import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';

import {
  useListOneTicketQuery,
  useListOneTicketHistoryQuery,
} from '../queries';

import ListOneTicketDetailsUi from './ListOneTicketDetails.ui';

export default function ListOneTicketDetails() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const { ticketId = '', studyId = '' } = useParams();
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [isInfoSideBarVisible, setIsInfoSideBarVisible] = useState(false);

  //* Queries
  const listOneTicketQuery = useListOneTicketQuery(ticketId, {
    enabled: ticketId.length > 0 && studyId.length === 0,
  });
  const listOneTicketHistoryQuery = useListOneTicketHistoryQuery(
    { ticketId, studyId },
    {
      enabled: ticketId.length > 0 && studyId.length > 0,
    }
  );

  //* Handlers
  const handleSideBarClose = () => {
    setIsInfoSideBarVisible(false);
  };

  const handleRowClick = () => {
    setIsInfoSideBarVisible(true);
  };

  useEffect(() => {
    if (listOneTicketQuery.isError || listOneTicketHistoryQuery.isError) {
      const errorMessage = extractErrorMessage(
        listOneTicketQuery.error || listOneTicketHistoryQuery.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listOneTicketQuery.isError, listOneTicketHistoryQuery.isError]);

  const data = {
    ticketDetails:
      listOneTicketQuery.data?.data.data ||
      listOneTicketHistoryQuery.data?.data.data.study,
    ticketId,
  };

  const handler = {
    handleSideBarClose,
    handleRowClick,
  };

  const isLoading =
    (listOneTicketQuery.isLoading && listOneTicketQuery.isInitialLoading) ||
    (listOneTicketHistoryQuery.isLoading &&
      listOneTicketHistoryQuery.isInitialLoading);

  return (
    <>
      <Toast ref={toastRef} />

      <ListOneTicketDetailsUi
        isLoading={isLoading}
        isInfoSideBarVisible={isInfoSideBarVisible}
        data={data}
        handlers={handler}
      />
    </>
  );
}
