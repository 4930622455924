import { api } from 'utils';

import { FetchListFourApiResponse } from './api.types';

export function fetchListFourApi(filter: any) {
  return api().get<FetchListFourApiResponse>('/list/four/fetch', {
    params: {
      page: filter.exportable ? undefined : filter.page,
      limit: filter.exportable ? undefined : filter.rows,
      status: filter.status,
      endorsementType:
        filter.endorsementType === 'All' ? '' : filter.endorsementType,
      exportable: filter.exportable,
      endorsement_id: filter.endorsementId,
    },
  });
}
