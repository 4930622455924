const listSixColumns = [
  {
    header: 'Employee Id',
    field: 'azadeaemployee_id',
    sortable: true,
  },
  {
    header: 'Invoice date',
    field: 'invoicedate',
    sortable: true,
  },
  {
    header: 'Country',
    field: 'country',
    sortable: true,
  },
  {
    header: 'Name',
    field: 'name',
    sortable: true,
  },
  {
    header: 'Relation',
    field: 'relation',
    sortable: true,
  },
  {
    header: 'Endorsement type',
    field: 'endorsementtype',
    sortable: true,
  },
  {
    header: 'Endorsement LOB',
    field: 'endorsement_lob',
    sortable: true,
  },
  {
    header: 'Plan',
    field: 'plan',
    sortable: true,
  },
  {
    header: 'Total',
    field: 'total',
    sortable: true,
  },
  {
    header: 'Clover Exception Description',
    field: 'clover_exceptiondescription',
    sortable: true,
  },
  {
    header: 'Total price table',
    field: 'total_pricetable',
    sortable: true,
  },
  {
    header: 'Difference',
    field: 'difference_total',
    sortable: true,
  },
  {
    header: 'Updated At',
    field: 'updated',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status_manual_approval',
    sortable: true,
  },
  {
    header: 'More details',
    field: 'details_link',
  },
];

export default listSixColumns;
