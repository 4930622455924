import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

import { Dropdown } from 'components';
import { routes, locales } from 'constants/index';
import { useUserQuery } from 'modules/App/queries';
import { setUYTag, getUYTag } from 'utils';

import './header.scss';

interface HeaderProps {
  isRolePage: boolean;
}

export default function Header({ isRolePage }: HeaderProps) {
  const userQuery = useUserQuery();

  //* Local State
  const [isMenuOpened, setMenuVisibility] = useState(false);
  const [selectedUYTag, setSelectedUYTag] = useState(getUYTag());

  const userType = userQuery.data?.data.data.user.userTypes?.find(
    ({ id }) => id === userQuery.data?.data.data.user.userTypeId
  );

  const uyTagsOptions = userType?.uytags.map((item) => ({
    label: item.value,
    value: item.key,
  }));

  const storedUYTag = getUYTag() || '';

  if (storedUYTag.length === 0 && uyTagsOptions && uyTagsOptions?.length > 0) {
    const defaultTag = uyTagsOptions?.[0].value || '';

    setUYTag(defaultTag);
    setSelectedUYTag(defaultTag);
  }

  //* Handlers
  function onMenuClick() {
    setMenuVisibility(!isMenuOpened);
  }

  function handleYUTagsChange(event: any) {
    setUYTag(event.value);
    setSelectedUYTag(event.value);

    window.location.reload();
  }

  return (
    <div className="header flex flex-align-items-center">
      <NavLink
        to={isRolePage ? routes.role : routes.main}
        className="header__logo-link"
      >
        <div className="header__logo"></div>
      </NavLink>

      {!isRolePage && (
        <div className="header__links-wrapper flex flex-align-items-center">
          <NavLink
            className="header__link flex flex-align-items-center flex-justify-content-center"
            to={routes.main}
          >
            {locales.header.links.dashboard}
          </NavLink>

          {userQuery.data?.data.data.user.userTypes
            ?.find(({ id }) => id === userQuery.data?.data.data.user.userTypeId)
            ?.menu.map((menuItem) => {
              let route = '';

              if (menuItem.submenus.length > 0) {
                return (
                  <div
                    key={menuItem.identifier}
                    className="header__link flex flex-align-items-center flex-justify-content-center"
                  >
                    {menuItem.name}

                    <div className="dropdown dropdown--list-one flex flex-column">
                      {menuItem.submenus.map((subMenuItem) => {
                        let subMenuRoute = '';

                        switch (subMenuItem.identifier) {
                          case 'list1-summary':
                            subMenuRoute = `${routes.listOne.index}/${routes.listOne.statuses}`;
                            break;
                          default:
                            subMenuRoute =
                              routes.listOne.index +
                              '?page=1&rows=50&first=0&ticketStatus=All&ticketType=All';
                        }

                        return (
                          <Link to={subMenuRoute} className="dropdown__item">
                            {subMenuItem.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              }

              switch (menuItem.identifier) {
                case 'list2':
                  route = `${routes.listTwo.index}?page=1&rows=50&first=0&ticketStatus=All&ticketType=All`;
                  break;
                case 'list3':
                  route = `${routes.listThree.index}?page=1&rows=50&first=0`;
                  break;
                case 'list4':
                  route = `${routes.listFour.index}?page=1&rows=50&first=0`;
                  break;
                case 'list5':
                  route = `${routes.listFive.index}?page=1&rows=50&first=0`;
                  break;
                case 'list6':
                  route = `${routes.listSix.index}?page=1&rows=50&first=0`;
                  break;
                case 'logs':
                  route = `${routes.logs.index}?list=1&page=1&rows=50&first=0`;
                  break;
                default:
                  route = routes.main;
              }

              return (
                <NavLink
                  className="header__link flex flex-align-items-center flex-justify-content-center"
                  to={route}
                >
                  {menuItem.name}
                </NavLink>
              );
            })}
        </div>
      )}

      <div className="header__actions-wrapper flex flex-align-items-center">
        {!isRolePage && uyTagsOptions && uyTagsOptions.length > 0 && (
          <Dropdown
            id="uyTag"
            name="uyTag"
            value={selectedUYTag}
            onChange={handleYUTagsChange}
            options={uyTagsOptions}
            className="header__yu-tags"
          />
        )}

        <div
          className="header__action"
          role="presentation"
          onClick={onMenuClick}
        >
          <div className="header__menu-icon" role="presentation" />

          {isMenuOpened && (
            <div className="dropdown flex flex-column">
              <div className="dropdown__item flex flex-align-items-center">
                {!!userQuery.data?.data.data.user.name && (
                  <div className="header__role-name ">
                    <strong>{`${userQuery.data?.data.data.user.name?.firstname} ${userQuery.data?.data.data.user.name?.lastname}`}</strong>
                    , &nbsp;
                    <span>
                      {
                        userQuery.data?.data.data.user.userTypes?.find(
                          (userType) =>
                            userType.id ===
                            userQuery.data?.data.data.user.userTypeId
                        )?.name
                      }
                    </span>
                  </div>
                )}
              </div>

              {userQuery.data?.data.data.user.userTypes &&
                userQuery.data?.data.data.user.userTypes?.length > 1 && (
                  <Link to={`${routes.role}`} className="dropdown__item">
                    {locales.general.changeUserType}
                  </Link>
                )}

              <Link
                to={`${routes.authentication.index}/${routes.authentication.resetPin}`}
                className="dropdown__item"
              >
                {locales.general.resetPin}
              </Link>

              {!!userQuery.data?.data.data.user.userTypes
                ?.find(
                  ({ id }) => id === userQuery.data?.data.data.user.userTypeId
                )
                ?.buttons.find(
                  ({ identifier }) => identifier === 'redirect-to-admin'
                ) && (
                <Link to={routes.admin} className="dropdown__item">
                  {locales.general.admin}
                </Link>
              )}

              <Link
                to={`${routes.authentication.index}/${routes.authentication.logout}`}
                className="dropdown__item dropdown__item--logout"
              >
                {locales.general.logout}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
