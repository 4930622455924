import PhoneNumber from 'awesome-phonenumber';

import { Validator as BaseValidator, Helpers } from 'utils';

import { locales } from 'constants/index';

class Validator extends BaseValidator {
  validateLoginForm(data: any, countryCode: string) {
    let errors = {
      phoneNumber: '',
    };
    data.forEach(({ name, value, rules }: any) => {
      const { isEmpty, isValid }: any = {
        ...this.rules,
        ...rules,
      };
      const string = !value ? '' : value;
      if (isEmpty && this.isEmpty(string)) {
        errors = {
          ...errors,
          [name]: locales.general.errors.required,
        };
        return;
      }
      if (isValid) {
        const { getAlpha2CountryCode } = new Helpers();
        const alpha2CountryCode = getAlpha2CountryCode(countryCode);
        const pn = new PhoneNumber(string, alpha2CountryCode);

        errors = {
          ...errors,
          [name]: !pn.isValid() ? 'Invalid phone number' : '',
        };
        return;
      }
    });
    return errors;
  }
}

export default Validator;
