import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getCurrentYear = () => {
  return dayjs().year();
};

export const getDobYears = () => {
  let dobYears: string[] = [];
  let startYear = parseInt(dayjs().subtract(100, 'year').format('YYYY'));
  let currentYear = parseInt(dayjs().format('YYYY'));

  let yearsDiff = currentYear - startYear;

  while (yearsDiff >= 0) {
    dobYears.push((currentYear - 1).toString());

    currentYear -= 1;

    yearsDiff -= 1;
  }

  return dobYears;
};

export const humanReadableGermanDate = (date: string, format: string) => {
  return !!date ? dayjs(date, format).format('MM/DD/YYYY') : '';
};

export const humanReadable = (date: string) => {
  if (!!date)
    return dayjs(date)
      .tz(tz)
      .subtract(dayjs(date).utcOffset(), 'minutes')
      .format('dddd, MMM D, YYYY, h:mmA');

  return '';
};

export const getGermanDate = (date: any) => {
  return !!date ? dayjs(date).format('YYYY-MM-DD') : '';
};
