import { api } from 'utils';

import { FetchApiHistoryResponse } from './api.types';

export function fetchApisHistoryApi({ queryKey }: any) {
  const [, , params] = queryKey;

  return api().get<FetchApiHistoryResponse>('/apis/history/', {
    params: {
      page: params.page,
      limit: params.rows,
      status: params.status,
      studyId: params.studyId,
    },
  });
}
