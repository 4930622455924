import { Dialog } from 'components';

import { ListOneTransferDeletionTicketConfirmationDialogProps } from './ListOneTransferDeletionTicketConfirmationDialog.types';

export default function ListOneTransferDeletionTicketConfirmationDialog({
  onHide,
  footer,
  visible,
  employeeIds,
}: ListOneTransferDeletionTicketConfirmationDialogProps) {
  return (
    <Dialog
      header="Confirm deletion"
      footer={footer}
      onHide={onHide}
      visible={visible}
      style={{ width: '40vw' }}
      position="top"
    >
      Are you sure you want to transfer these ID{' '}
      <span style={{ color: '#b50000' }}>{employeeIds.join(', ')}</span>?
    </Dialog>
  );
}
