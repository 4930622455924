import App from './App';
import Authentication, {
  PhoneRegister,
  Login,
  PhoneOtp,
  PersonalPin,
  UserDetails,
  EmailOtp,
  ResetPin,
  NewPinResetForm,
} from './Authentication';
import Logout from './Logout';
import Role from './Role';
import Dashboard from './Dashboard';
import ListOne, {
  ListOneTicketDetails,
  ListOneTicketStudies,
  ListOneStatusesListing,
} from './ListOne';
import ListTwo, { ListTwoTicketDetails, ListTwoTicketStudies } from './ListTwo';
import ListThree, { ListThreeDetails } from './ListThree';
import ProcessedEvents from './ProcessedEvents';
import HistoryLogs from './HistoryLogs';
import ListFour from './ListFour';
import {
  ListFiveLayout,
  ListFiveNeedForApprovalListing,
  ListFiveOtherInvoicesListing,
} from './ListFive';
import ListSix from './ListSix';
import { CampaignConsent, CampaignOptOut } from './Campaign';

import Admin from './Admin';

export {
  App,
  Authentication,
  PhoneRegister,
  Login,
  PhoneOtp,
  PersonalPin,
  UserDetails,
  EmailOtp,
  Logout,
  ResetPin,
  NewPinResetForm,
  Dashboard,
  ProcessedEvents,
  Role,
  ListOne,
  ListOneTicketDetails,
  ListOneTicketStudies,
  ListOneStatusesListing,
  ListTwo,
  ListTwoTicketDetails,
  ListTwoTicketStudies,
  ListThree,
  ListThreeDetails,
  ListFour,
  ListFiveLayout,
  ListFiveNeedForApprovalListing,
  ListFiveOtherInvoicesListing,
  ListSix,
  HistoryLogs,
  CampaignConsent,
  CampaignOptOut,
  Admin,
};
