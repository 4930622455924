import './style.scss';

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner flex flex-align-items-center flex-justify-content-center">
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
