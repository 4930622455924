const columns = [
  {
    header: 'Study ID',
    field: 'studyId',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
  {
    header: 'Status Code',
    field: 'statusCode',
    sortable: true,
  },
  {
    header: 'Start Time',
    field: 'startTime',
    sortable: true,
  },
  {
    header: 'End Time',
    field: 'endTime',
    sortable: true,
  },
  {
    header: 'Message',
    field: 'message',
    sortable: true,
  },
  {
    header: 'Duplicated employees',
    field: 'duplicatedEmployees',
    sortable: true,
  },
  {
    header: 'Deleted Employees',
    field: 'deletedEmployees',
    sortable: true,
  },
  {
    header: 'Created Date',
    field: 'createdDate',
    sortable: true,
  },
];

export default columns;
