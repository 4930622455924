import { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';

import { useApisHistoryQuery } from './queries';
import ProcessedEventsUi from './ProcessedEvents.ui';
import {
  ProcessedEventsParams,
  ProcessedEventsFilter,
  PaginatorPageChangeEvent,
  DropdownChangeEvent,
} from './ProcessedEvents.types';

const ProcessedEvents = () => {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: ProcessedEventsParams = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    status: searchParams.get('status') || 'All',
    studyId: searchParams.get('studyId') || '',
  };

  const apisHistoryQuery = useApisHistoryQuery(params);

  //* Local State
  const [filter, setFilter] = useState<ProcessedEventsFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    status: params.status,
    studyId: params.studyId,
  });

  //* Handlers
  function handleChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setFilter((filter) => ({
      ...filter,
      studyId: value,
    }));
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...searchParams,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleTicketStatusChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...searchParams,
      ticketStatus: event.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      ticketStatus: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handleSearchClick(event: any) {
    event.preventDefault();

    const value = event.target.elements.studyId.value;

    if (value.length > 0) {
      setSearchParams({
        ...params,
        page: '1',
        rows: '50',
        first: '0',
        studyId: value.trim(),
      });

      setFilter((filter) => ({
        ...filter,
        page: 1,
        rows: 50,
        first: 0,
      }));
    } else {
      const { studyId, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      studyId: value,
    }));
  }

  useEffect(() => {
    if (apisHistoryQuery.isError) {
      const errorMessage = extractErrorMessage(apisHistoryQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [apisHistoryQuery.isError]);

  const options = {
    ticketStatuses: [
      { key: 'all', label: 'All', value: 'All' },
      { key: 'successful', label: 'Successful', value: 'success' },
      { key: 'failed', label: 'Unsuccessful', value: 'unsuccess' },
    ],
  };

  const data = {
    list: apisHistoryQuery.data?.data.data || [],
  };

  const handlers = {
    handleChange,
    handlePageChange,
    handleTicketStatusChange,
    handleSearchClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ProcessedEventsUi
        isLoading={apisHistoryQuery.isLoading}
        totalRecords={apisHistoryQuery.data?.data.total || 0}
        options={options}
        filter={filter}
        data={data}
        handlers={handlers}
      />
    </>
  );
};

export default ProcessedEvents;
