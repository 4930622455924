import classNames from 'classnames';
import OtpInput from 'react-otp-input';

import { OtpProps } from './Otp.types';

import './otp.scss';

const inputStyles = (error: string): string => {
  return classNames({
    otp__input: true,
    'otp__input--error': error.length > 0,
  });
};

export default function Otp({
  value,
  error = '',
  numInput = 5,
  onChange,
  isInputSecure = false,
}: OtpProps) {
  return (
    <div className="otp">
      <OtpInput
        inputStyle={inputStyles(error)}
        value={value}
        onChange={onChange}
        numInputs={numInput}
        // @ts-ignore
        renderInput={(inputProps: any) => (
          <input {...inputProps} type={isInputSecure ? 'password' : 'number'} />
        )}
      />
      {error.length > 0 && <div className="otp__error">{error}</div>}
    </div>
  );
}
