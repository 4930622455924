import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { clearSession } from 'utils';

import { revokeUserSession } from './store/actions';

export default function Logout() {
  //* Hooks Init
  const dispatch = useDispatch();

  function logout() {
    clearSession();
    dispatch(revokeUserSession());
  }

  useEffect(() => {
    logout();
  }, []);

  return null;
}
