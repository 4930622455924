import { useState } from 'react';

import dayjs from 'dayjs';

import { useExtractServerError } from 'hooks';
import { useListZeroStatusQuery } from 'modules/App/queries';

import { useDashboardData } from './queries';

import DashboardUi from './Dashboard.ui';

export default function Dashboard() {
  //* Hooks
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [dbDiscrepanciesListName, setDbDiscrepanciesListName] = useState<
    | 'policiesDiscrepancy'
    | 'list0Discrepancy'
    | 'list3Discrepancy'
    | 'rbacPolicyHolderDiscrepancy'
    | null
  >(null);

  const listZeroStatusQuery = useListZeroStatusQuery();
  const [
    listZeroQuery,
    listOneQuery,
    listTwoQuery,
    listThreeQuery,
    listFourQuery,
    listFiveQuery,
    listStatusQuery,
    ticketsStatus,
    companyCodeDiscrepancy,
  ] = useDashboardData(
    {
      startDate: dayjs().subtract(7, 'days'),
      endDate: dayjs(),
    },
    {
      startDate: dayjs().subtract(7, 'days'),
      endDate: dayjs(),
    }
  );

  //* Handlers
  function handleDbDiscrepancyClick(
    listName:
      | 'policiesDiscrepancy'
      | 'list0Discrepancy'
      | 'list3Discrepancy'
      | 'rbacPolicyHolderDiscrepancy'
  ) {
    return function () {
      setDbDiscrepanciesListName(listName);
    };
  }

  function handleDbDiscrepancyDialogClose() {
    setDbDiscrepanciesListName(null);
  }

  let errors = {
    listStatusError: '',
    listZeroError: '',
    listOneError: '',
    listTwoError: '',
    listThreeError: '',
    listFourError: '',
    listFiveError: '',
    ticketsStatusError: '',
    companyCodeDiscrepancyError: '',
  };

  if (listStatusQuery.isError)
    errors = {
      ...errors,
      listStatusError: extractErrorMessage(listStatusQuery.error),
    };

  if (listZeroQuery.isError)
    errors = {
      ...errors,
      listZeroError: extractErrorMessage(listZeroQuery.error),
    };

  if (listOneQuery.isError)
    errors = {
      ...errors,
      listOneError: extractErrorMessage(listOneQuery.error),
    };

  if (listTwoQuery.isError)
    errors = {
      ...errors,
      listTwoError: extractErrorMessage(listTwoQuery.error),
    };

  if (listThreeQuery.isError)
    errors = {
      ...errors,
      listThreeError: extractErrorMessage(listThreeQuery.error),
    };

  if (listFourQuery.isError)
    errors = {
      ...errors,
      listFourError: extractErrorMessage(listFourQuery.error),
    };

  if (listFiveQuery.isError)
    errors = {
      ...errors,
      listFiveError: extractErrorMessage(listFiveQuery.error),
    };

  if (ticketsStatus.isError)
    errors = {
      ...errors,
      ticketsStatusError: extractErrorMessage(ticketsStatus.error),
    };

  if (companyCodeDiscrepancy.isError)
    errors = {
      ...errors,
      companyCodeDiscrepancyError: extractErrorMessage(
        companyCodeDiscrepancy.error
      ),
    };

  const data = {
    apiStatus: listStatusQuery.data?.data.success || false,
    listZeroErrorStatus: listZeroQuery.data?.data.lastAPI.status || false,
    listZeroRecordsCount:
      listZeroQuery.data?.data.lastAPI.response.dataReceived || 0,
    studyId: listZeroQuery.data?.data.lastAPI.response.studyId || '',
    latestSuccessfulListZeroApiDate:
      listZeroQuery.data?.data.lastAPI.response.endTime || '',
    listOneTotalOpenTickets: listOneQuery.data?.data.total || 0,
    listOneByTypeChartData: listOneQuery.data?.data.data || [],
    listOneOpenTicketStatuses: {
      openClean: listOneQuery.data?.data.types['open-clean'] || 0,
      openPending: listOneQuery.data?.data.types['open-pending'] || 0,
      openError: listOneQuery.data?.data.types['open-error'] || 0,
    },
    listOneStatusesStats: ticketsStatus.data?.data.data,
    listTwoTotalOpenTickets: listTwoQuery.data?.data.total || 0,
    listTwoByTypeChartData: listTwoQuery.data?.data.data || [],
    listTwoOpenTicketStatuses: {
      openClean: listTwoQuery.data?.data.types['open-clean'] || 0,
      openPending: listTwoQuery.data?.data.types['open-pending'] || 0,
      openError: listTwoQuery.data?.data.types['open-error'] || 0,
    },
    listThreeLivePopulation: {
      totalInsured: listThreeQuery.data?.data.totalInsured || 0,
      noLifeInsurance: listThreeQuery.data?.data.totalLife || 0,
    },
    listFourChartData:
      listFourQuery.data?.data.data.map((item) => ({
        count: item.count,
        type: item.status,
      })) || [],
    listFiveStats: {
      needApproval: listFiveQuery.data?.data.needapproval || 0,
      needApprovalDependents:
        listFiveQuery.data?.data.needapprovalDependents || 0,
      needApprovalPrincipals:
        listFiveQuery.data?.data.needapprovalPrincipals || 0,
      underAzadeaReview: listFiveQuery.data?.data.underAzadeaReview || 0,
      underAzadeaReviewDependents:
        listFiveQuery.data?.data.underAzadeaReviewDependents || 0,
      underAzadeaReviewPrincipals:
        listFiveQuery.data?.data.underAzadeaReviewPrincipals || 0,
    },
    companyCodeDiscrepancy: {
      policiesDiscrepancy:
        companyCodeDiscrepancy.data?.data.policiesDiscrepancy || [],
      list0Discrepancy:
        companyCodeDiscrepancy.data?.data.list0Discrepancy || [],
      list3Discrepancy:
        companyCodeDiscrepancy.data?.data.list3Discrepancy || [],
      rbacPolicyHolderDiscrepancy:
        companyCodeDiscrepancy.data?.data.rbacPolicyHolderDiscrepancy || [],
    },
    dbDiscrepanciesListName,
    isListZeroEnabled: listZeroStatusQuery.data?.data.data.enabled,
  };

  const handlers = {
    handleDbDiscrepancyClick,
    handleDbDiscrepancyDialogClose,
  };

  return (
    <DashboardUi
      isListZeroLoading={listZeroQuery.isLoading}
      isListOneLoading={listOneQuery.isLoading}
      isListTwoLoading={listTwoQuery.isLoading}
      isListThreeLoading={listThreeQuery.isLoading}
      isListFourLoading={listFourQuery.isLoading}
      isListFiveLoading={listFiveQuery.isLoading}
      isCompanyCodeDiscrepancyLoading={companyCodeDiscrepancy.isLoading}
      errors={errors}
      data={data}
      handlers={handlers}
    />
  );
}
