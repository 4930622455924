import { ChangeEvent, useState, useRef, useEffect } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';

import { getUserId } from 'utils';
import { locales } from 'constants/index';
import { useExtractServerError, useValidate } from 'hooks';

import { useUpdateUserPinMutation } from '../mutations';

import NewPinResetUi from './NewPinResetForm.ui';

const validationSchema = {
  otp: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
  securityAnswer: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
  pinCode: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
};

export default function NewPinResetForm() {
  const userId = getUserId();

  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const navigate = useNavigate();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  //* Local State
  const [form, setForm] = useState({
    otp: '',
    securityAnswer: '',
    pinCode: '',
  });

  //* Queries & Mutations
  const updateUserPinMutation = useUpdateUserPinMutation({
    onSuccess: handleUpdateSuccess,
  });

  //* Handlers
  function handleInputChange({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  }

  function handleInputBlur({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    validate({
      data: {
        ...data,
        [name]: value,
      },
      validationSchema: validationSchema,
    });
  }

  function handleOtpChange(otp: string) {
    setForm((prevValue) => ({
      ...prevValue,
      otp,
    }));

    setErrors((prevState) => ({
      ...prevState,
      otp: '',
    }));
  }

  function handlePinChange(pinCode: string) {
    setForm((prevValue) => ({
      ...prevValue,
      pinCode,
    }));

    setErrors((prevState) => ({
      ...prevState,
      pinCode: '',
    }));
  }

  function handleSubmitClick(event: any) {
    event.preventDefault();

    const isFormValid = validate({
      data: form,
      validationSchema: validationSchema,
    });

    if (!isFormValid) return;

    updateUserPinMutation.mutate({
      userId: getUserId(),
      ...form,
    });
  }

  function handleUpdateSuccess() {
    toastRef.current?.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Your PIN has been updated successfully',
    });

    navigate('/');
  }

  useEffect(() => {
    if (!userId) navigate('/auth/reset-pin');
  }, []);

  useEffect(() => {
    if (updateUserPinMutation.isError) {
      const errorMessage = extractErrorMessage(updateUserPinMutation.error);

      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [updateUserPinMutation.isError]);

  const data = { ...form };

  const handlers = {
    handleInputChange,
    handleInputBlur,
    handleOtpChange,
    handlePinChange,
    handleSubmitClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <NewPinResetUi
        isLoading={updateUserPinMutation.isLoading}
        errors={errors}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
