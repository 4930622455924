export default function useCleanedPhoneNumber() {
  return function cleanPhoneNumber(phoneNumber: string, callingCode: string) {
    let cleanedPhoneNumber = '0';

    cleanedPhoneNumber = phoneNumber.replaceAll(/\s/g, '').replace(/^0+/, '');

    if (
      cleanedPhoneNumber.substring(0, callingCode.toString().length) ===
      callingCode.toString()
    )
      cleanedPhoneNumber = phoneNumber.replace(callingCode.toString(), '');

    return cleanedPhoneNumber;
  };
}
