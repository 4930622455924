import { Dropdown, Button, Spinner } from 'components';

import { locales } from 'constants/index';

import { RoleUiProps } from './Role.types';

import './role.scss';

export default function RoleUi({
  isLoading,
  data: { role },
  options,
  error,
  handlers,
}: RoleUiProps) {
  return isLoading ? (
    <Spinner />
  ) : (
    <div className="formgrid">
      <form
        className="role sm:col-12 md:col-6 lg:col-3"
        onSubmit={handlers.handleSubmitClick}
      >
        <div className="role__title">{locales.role.title}</div>

        <div className="field sm:col-12">
          <Dropdown
            name="role"
            value={role}
            options={options.userTypes}
            onChange={handlers.handleRoleChange}
            error={error}
          />
        </div>

        <div className="field sm:col-12">
          <Button label={locales.general.continue} />
        </div>
      </form>
    </div>
  );
}
