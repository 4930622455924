const columns = [
  {
    header: 'Endorsement Id',
    field: 'endorsement_id',
    sortable: true,
  },
  {
    header: 'Endorsement Type',
    field: 'endorsementtype',
    sortable: true,
  },
  {
    header: 'Endorsement LOB',
    field: 'endorsement_lob',
    sortable: true,
  },
  {
    header: 'Case Id',
    field: 'caseId',
    sortable: true,
  },
  {
    header: 'Status Id',
    field: 'statusid',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
  {
    header: 'Sent Date',
    field: 'sentdate',
    sortable: true,
  },
  {
    header: 'Created',
    field: 'created',
    sortable: true,
  },
  {
    header: 'Updated At',
    field: 'updated',
    sortable: true,
  },
];

export default columns;
