import { Dialog, Button, Spinner } from 'components';

import { locales } from 'constants/index';

import { CampaignConsentUiProps } from './CampaignConsent.types';

import './campaign-consent.scss';

export default function CampaignConsentUi({
  isLoading,
  isConfirmationDialogVisible,
  message,
  handlers,
}: CampaignConsentUiProps) {
  return (
    <>
      {isLoading && <Spinner />}

      <Dialog
        visible={isConfirmationDialogVisible}
        // header={locales.general.success}
        onHide={handlers.handleConfirmationDialogHide}
        style={{ maxWidth: '45rem', width: '100%' }}
        className="campaign-consent-dialog"
        footer={
          <Button
            onClick={handlers.handleConfirmationDialogHide}
            label={locales.general.ok}
          />
        }
      >
        <div className="campaign-consent-dialog__success" />

        <div className="campaign-consent-dialog__message">{message}</div>
      </Dialog>
    </>
  );
}
