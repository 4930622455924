import { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { useListSixInfoQuery } from 'modules/ListSix/queries';
import { useExtractServerError } from 'hooks';

import ListSixInfoDialogUi from './ListSixInfoDialog.ui';

import { ListSixInfoDialogProps } from './ListSixInfoDialog.types';

export default function ListSixInfoDialog({
  id,
  onClose,
}: ListSixInfoDialogProps) {
  //* Hooks
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  //* Queries & Mutations
  const listSixInfoQuery = useListSixInfoQuery(id);

  useEffect(() => {
    if (listSixInfoQuery.isError) {
      const errorMessage = extractErrorMessage(listSixInfoQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listSixInfoQuery.isError]);

  const data = {
    invoiceInfo: listSixInfoQuery.data?.data.data[0] || null,
  };

  return (
    <ListSixInfoDialogUi
      isLoading={listSixInfoQuery.isLoading}
      data={data}
      onHide={onClose}
    />
  );
}
