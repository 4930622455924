import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';

import { InputSwitch, Spinner, Button } from 'components';

import { AdminUiProps } from './Admin.types';

export default function AdminUi({
  isLoading,
  isListZeroBlocked,
  renewalList,
  handlers,
}: AdminUiProps) {
  return (
    <div className="admin">
      {isLoading && <Spinner />}

      <div className="admin__block-list-zero flex align-items-center">
        <div className="admin__block-list-zero__title">Block List Zero</div>

        <InputSwitch
          checked={isListZeroBlocked}
          onChange={handlers.handleBlockListZeroToggle}
        />
      </div>

      <Divider />

      <Button
        text
        label="Get Renewal List"
        onClick={handlers.handleFetchRenewalList}
      />

      {renewalList && (
        <Panel header="Renewal List">
          <ul className="admin__renewal-list">
            <li>
              <a href={renewalList.AD_exclusion}>
                <strong>AD Execution</strong>
              </a>
            </li>

            <li>
              <a href={renewalList.DE_exclusion}>
                <strong>DE Execution</strong>
              </a>
            </li>

            <li>
              <a href={renewalList.EC_exclusion}>
                <strong>EC Execution</strong>
              </a>
            </li>

            <li>
              <a href={renewalList.list_0}>
                <strong>List Zero</strong>
              </a>
            </li>

            <li>
              <a href={renewalList.renewal}>
                <strong>Renewal</strong>
              </a>
            </li>
          </ul>
        </Panel>
      )}
    </div>
  );
}
