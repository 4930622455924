import classNames from 'classnames';

import { TInfoSideBar } from './types';

import { humanReadable } from 'utils';

import './style.scss';

/**
 *
 * @param props
 * @returns InfoSideBar component
 */
const InfoSideBar: TInfoSideBar = (props): JSX.Element => {
  const {
    onClose,
    isVisible,
    ticketDetails: {
      id,
      type,
      studyId,
      employeeId,
      status,
      statusDescription,
      createdDate,
      updatedDate,
    },
  } = props;
  return (
    <>
      <div
        className={classNames({
          dimmer: true,
          'dimmer--opened': isVisible,
        })}
      ></div>
      <div
        className={classNames({
          'info-sidebar': true,
          'info-sidebar--opened': isVisible,
        })}
      >
        <div className="info-sidebar__header flex">
          <div
            className="info-sidebar__close-icon"
            role="presentation"
            onClick={onClose}
          ></div>
        </div>

        <div className="info-sidebar__user">
          <div className="info-sidebar__title">Ticket details</div>
          <div className="info-sidebar__details flex flex-column">
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">ticketId</div>
              <div className="info-sidebar__value">{id}</div>
            </div>
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">type</div>
              <div className="info-sidebar__value">{type}</div>
            </div>
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">studyId</div>
              <div className="info-sidebar__value">{studyId}</div>
            </div>
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">employeeId</div>
              <div className="info-sidebar__value">{employeeId}</div>
            </div>
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">status</div>
              <div className="info-sidebar__value">{status}</div>
            </div>
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">statusDescription</div>
              <div className="info-sidebar__value">{statusDescription}</div>
            </div>
            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">Created At</div>
              <div className="info-sidebar__value">
                {createdDate ? humanReadable(createdDate) : ''}
              </div>
            </div>

            <div className="info-sidebar__details-box flex flex-column">
              <div className="info-sidebar__label">Updated At</div>
              <div className="info-sidebar__value">
                {updatedDate ? humanReadable(updatedDate) : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoSideBar;
