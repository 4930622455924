import { api } from 'utils';

import {
  TFetchUserInfoApi,
  TFetchAuthInfoApi,
  TFetchEndorsementInfoApi,
} from './api.types';

export const fetchUserInfoApi: TFetchUserInfoApi = () =>
  api().get('/users/myInfo');

export const fetchAuthConfig: TFetchAuthInfoApi = () =>
  api().get('/auth/config');

export const fetchEndorsementConfig: TFetchEndorsementInfoApi = () =>
  api().get('/endorsement/config');

export function getListZeroStatus() {
  return api().get('/list/block');
}
