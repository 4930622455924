import {
  DataTable,
  Dropdown,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
} from 'components';

import { locales } from 'constants/index';
import { humanReadable } from 'utils';

import columns from './columns';
import { ListFourUiProps } from './ListFour.types';

function TypeBodyTemplate(row: any) {
  return (
    <span
      className={`colored-circle colored-circle--${row.type.toLowerCase()}`}
    >
      {row.type}
    </span>
  );
}

function SentDateBodyTemplate(row: any) {
  return humanReadable(row.updated);
}

function CreatedDateBodyTemplate(row: any) {
  return humanReadable(row.created);
}

function UpdatedDateBodyTemplate(row: any) {
  return humanReadable(row.updated);
}

export default function ListFourUi({
  isLoading,
  isExporting,
  totalRecords,
  filter,
  options,
  data,
  handlers,
}: ListFourUiProps) {
  return (
    <div className="az-tickets">
      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3">
            <form onSubmit={handlers.handleSubmitSearch}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="endorsementId"
                    name="endorsementId"
                    placeholder={'Endorsement ID'}
                    value={filter.endorsementId || ''}
                    onChange={handlers.handleInputChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="endorsementType"
              name="endorsementType"
              placeholder="Filter by endorsement type"
              value={filter.endorsementType || ''}
              onChange={handlers.handleEndorsementTypeChange}
              options={options.endorsementTypes}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="status"
              name="status"
              placeholder="Filter by endorsement status"
              value={filter.status || ''}
              onChange={handlers.handleStatusChange}
              options={options.endorsementStatuses}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              className="az-tickets__download"
              disabled={!data.list || data.list.length === 0}
              onClick={handlers.handleDownload}
              loading={isExporting}
              label={locales.general.download}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleClearAllFilters}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          columns={columns.map((column) => {
            if (column.field === 'type') {
              return {
                ...column,
                body: TypeBodyTemplate,
              };
            }

            if (column.field === 'sentdate') {
              return {
                ...column,
                body: SentDateBodyTemplate,
              };
            }

            if (column.field === 'created') {
              return {
                ...column,
                body: CreatedDateBodyTemplate,
              };
            }

            if (column.field === 'updated') {
              return {
                ...column,
                body: UpdatedDateBodyTemplate,
              };
            }

            return {
              ...column,
              body: (row: any) =>
                !row[column.field] ? 'N/A' : row[column.field],
            };
          })}
          value={data.list}
        />
      )}

      {data.list?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}
    </div>
  );
}
