import { useState, useEffect, useRef, FormEvent } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes, locales } from 'constants/index';
import { getUserId, getPhoneNumber } from 'utils';
import { useExtractServerError, useCountdownTimer } from 'hooks';

import {
  useValidateEmailMutation,
  useResendEmailOtpMutation,
} from '../mutations';

import OtpVerification from '../OtpVerification';

import { IStoreState } from './EmailOtp.types';

export default function EmailOtp() {
  const { phoneNumber = '' } = getPhoneNumber();

  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const navigate = useNavigate();
  const { extractErrorMessage } = useExtractServerError();
  const { countdownTimer, isResendOtpEnabled, startCountdownTimer } =
    useCountdownTimer();

  //* Mutations
  const validateEmailMutation = useValidateEmailMutation({
    onSuccess: handleVerifyOtpSuccess,
  });
  const resendEmailOtpMutation = useResendEmailOtpMutation();

  //* Redux State
  const authData = useSelector(({ auth }: IStoreState) => auth, shallowEqual);

  //* Local State
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');

  //* Handlers
  function handleChangeOtp(otp: string) {
    setOtp(otp);
    setOtpError('');
  }

  function handleVerifyOtp(event: FormEvent) {
    event.preventDefault();

    if (!otp) return setOtpError(locales.login.errors.otpRequired);

    validateEmailMutation.mutate({
      userId: getUserId(),
      otp,
    });
  }

  function handleVerifyOtpSuccess() {
    navigate(
      `${routes.authentication.index}/${routes.authentication.personalPin}`
    );
  }

  function handleResendOtp() {
    setOtp('');

    resendEmailOtpMutation.mutate(getUserId());
  }

  if (resendEmailOtpMutation.isSuccess) startCountdownTimer();

  if (resendEmailOtpMutation.isError) {
    const errorMessage = extractErrorMessage(resendEmailOtpMutation.error);
    toastRef.current?.show({
      severity: 'error',
      detail: errorMessage,
    });
  }

  useEffect(() => {
    if (!phoneNumber)
      navigate(
        `${routes.authentication.index}/${routes.authentication.phoneRegister}`
      );
  });

  const data = {
    title: locales.formatString(locales.OTP.emailTitle, {
      email: authData.email.toLowerCase(),
    }),
    platform: locales.general.email.toLowerCase(),
    otp,
    email: authData.email,
  };

  const handlers = {
    handleChangeOtp,
    handleVerifyOtp,
    handleResendOtp,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <OtpVerification
        isLoading={
          validateEmailMutation.isLoading || resendEmailOtpMutation.isLoading
        }
        isResendOtpEnabled={isResendOtpEnabled}
        countdownTimer={countdownTimer}
        otpError={otpError}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
