import { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';
import { useConfigQueries } from 'modules/App/queries';

import { useListOneStatusesQuery } from '../queries';

import ListOneStatusesListingUi from './ListOneStatusesListing.ui';
import {
  ListOneStatusesFilter,
  DropdownChangeEvent,
  PaginatorPageChangeEvent,
} from './ListOneStatusesListing.types';

export default function ListOneStatusesListing() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params = {
    page: searchParams.get('page') || '1',
    limit: searchParams.get('limit') || '50',
    ticketType: searchParams.get('ticketType') || 'All',
  };

  //* Queries
  const [, endorsementConfig] = useConfigQueries();
  const listOneStatusesTicketsQuery = useListOneStatusesQuery(params);

  //* Local state
  const [filter, setFilter] = useState<ListOneStatusesFilter>({
    page: parseInt(params.page),
    limit: parseInt(params.limit),
    ticketType: params.ticketType,
  });

  function handleTicketTypeChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...searchParams,
      page: '1',
      limit: '50',
    });

    setFilter((filter) => ({
      ...filter,
      ticketType: event.value,
      page: 1,
    }));
  }

  function handleFilterClear() {
    setSearchParams({
      page: '1',
      limit: '50',
    });

    setFilter({
      page: 1,
      limit: 50,
      ticketType: 'All',
    });
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...searchParams,
      page: `${event.page + 1}`,
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
    }));
  }

  useEffect(() => {
    if (listOneStatusesTicketsQuery.isError) {
      const errorMessage = extractErrorMessage(
        listOneStatusesTicketsQuery.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listOneStatusesTicketsQuery.isError]);

  const data = {
    tickets:
      (filter.ticketType === 'AD' || filter.ticketType === 'DE' || filter.ticketType === 'EC'
        ? listOneStatusesTicketsQuery.data?.data.data.statistics_array.filter(
            (item) => item.type === filter.ticketType && item.type !== 'All'
          )
        : listOneStatusesTicketsQuery.data?.data.data.statistics_array) || [],
  };

  const options = {
    ticketTypes:
      endorsementConfig.data?.data.ticketsType
        .filter(
          (type) =>
            type.value === 'AD' || type.value === 'DE' || type.value === 'EC' || type.key === 'All'
        )
        .map(({ key, value }) => {
          if (key === 'All') {
            return {
              key,
              label: key,
              value: key,
            };
          }
          return {
            key: value,
            label: key,
            value,
          };
        }) || [],
  };

  const handlers = {
    handleTicketTypeChange,
    handlePageChange,
    handleFilterClear,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListOneStatusesListingUi
        isLoading={listOneStatusesTicketsQuery.isLoading}
        filter={filter}
        options={options}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
