import { useQuery } from '@tanstack/react-query';

import { fetchListTwoApi } from '../api';

import { ListTwoParams } from '../ListTwo.types';

export default function useFetchListTwoData(
  params: ListTwoParams,
  options?: any
) {
  return useQuery(
    ['tickets', 'list', 'Two', 'fetch', params],
    () => fetchListTwoApi(params),
    options
  );
}
