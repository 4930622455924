import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Divider } from 'primereact/divider';

import { SkeletonLoader, BarChart } from 'components';

import { routes, locales } from 'constants/index';
import { humanReadable } from 'utils';

import { CompanyCodeDiscrepanciesDialog } from './components';
import { ITDashboardUiProps } from './Dashboard.types';

import './dashboard.scss';

export default function DashboardUi({
  isListZeroLoading,
  isListOneLoading,
  isListTwoLoading,
  isListThreeLoading,
  isListFourLoading,
  isListFiveLoading,
  errors,
  data: {
    latestSuccessfulListZeroApiDate,
    listOneTotalOpenTickets,
    listOneOpenTicketStatuses,
    listOneByTypeChartData,
    listOneStatusesStats,
    listTwoTotalOpenTickets,
    listTwoOpenTicketStatuses,
    listTwoByTypeChartData,
    listThreeLivePopulation,
    listFourChartData,
    listFiveStats,
    apiStatus,
    listZeroRecordsCount,
    listZeroErrorStatus,
    studyId,
    companyCodeDiscrepancy,
    dbDiscrepanciesListName,
    isListZeroEnabled,
  },
  handlers,
}: ITDashboardUiProps) {
  return (
    <div className="dashboard">
      <div className="grid">
        <div className="sm:col-12 lg:col-5">
          <div className="dashboard__list-info">
            <div className="dashboard__list-info-title">
              {locales.general.list} 1
            </div>

            {isListOneLoading ? (
              <>
                <SkeletonLoader height="3rem" />
                <SkeletonLoader height="3rem" />
                <SkeletonLoader height="3rem" />
              </>
            ) : (
              <>
                <div className="dashboard__list-info-total">
                  {locales.dashboard.totalOpenTickets}
                  <div className="dashboard__list-info-total-count">
                    {listOneTotalOpenTickets}
                  </div>
                </div>

                <div className="dashboard__list-info-content">
                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.byStatus}
                    </div>

                    {!!errors.listOneError ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listOneError}
                      </div>
                    ) : (
                      <div className="dashboard__list-info-statuses flex">
                        <Link
                          className="dashboard__list-info-status flex flex-column"
                          to={`${routes.listOne.index}?ticketStatus=open-pending`}
                        >
                          <div className="dashboard__list-info-status-type colored-label colored-label--warning">
                            {locales.dashboard.openPending}
                          </div>

                          <div className="dashboard__list-info-status-value dashboard__list-info-status-value--warning">
                            {listOneOpenTicketStatuses.openPending}
                          </div>
                        </Link>

                        <Link
                          className="dashboard__list-info-status flex flex-column"
                          to={`${routes.listOne.index}?ticketStatus=open-clean`}
                        >
                          <div className="dashboard__list-info-status-type colored-label colored-label--ok">
                            {locales.dashboard.openClean}
                          </div>

                          <div className="dashboard__list-info-status-value dashboard__list-info-status-value--ok">
                            {listOneOpenTicketStatuses.openClean}
                          </div>
                        </Link>

                        <Link
                          className="dashboard__list-info-status flex flex-column"
                          to={`${routes.listOne.index}?ticketStatus=open-error`}
                        >
                          <div className="dashboard__list-info-status-type colored-label colored-label--danger">
                            {locales.dashboard.openError}
                          </div>

                          <div className="dashboard__list-info-status-value dashboard__list-info-status-value--danger">
                            {listOneOpenTicketStatuses.openError}
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>

                  <Link
                    className="flex flex-column"
                    to={`${routes.listOne.index}/${routes.listOne.statuses}`}
                  >
                    <div className="dashboard__list-info-total">
                      {locales.dashboard.totalTicketsToFollowUp}

                      {!errors.ticketsStatusError && (
                        <div className="dashboard__list-info-total-count">
                          {listOneStatusesStats?.total}
                        </div>
                      )}
                    </div>
                  </Link>

                  {!!errors.ticketsStatusError ? (
                    <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                      <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                      {errors.listOneError}
                    </div>
                  ) : (
                    <>
                      <div className="dashboard__list-info-section">
                        <div className="dashboard__list-info-section-title">
                          {locales.dashboard.addition}
                        </div>

                        <div className="dashboard__list-info-statuses flex">
                          <Link
                            className="dashboard__list-info-status flex flex-column"
                            to={`${routes.listOne.index}/${routes.listOne.statuses}?ticketType=AD`}
                          >
                            <div className="dashboard__list-info-status-type colored-label colored-label--ok">
                              {locales.dashboard.normalFollowUp}
                            </div>

                            <div className="dashboard__list-info-status-value dashboard__list-info-status-value--ok">
                              {listOneStatusesStats?.AD.normal}
                            </div>
                          </Link>

                          <Link
                            className="dashboard__list-info-status flex flex-column"
                            to={`${routes.listOne.index}/${routes.listOne.statuses}?ticketType=AD`}
                          >
                            <div className="dashboard__list-info-status-type colored-label colored-label--danger">
                              {locales.dashboard.errorFollowUp}
                            </div>

                            <div className="dashboard__list-info-status-value dashboard__list-info-status-value--danger">
                              {listOneStatusesStats?.AD.error}
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="dashboard__list-info-section">
                        <div className="dashboard__list-info-section-title">
                          {locales.dashboard.deletion}
                        </div>

                        <div className="dashboard__list-info-statuses flex">
                          <Link
                            className="dashboard__list-info-status flex flex-column"
                            to={`${routes.listOne.index}/${routes.listOne.statuses}?ticketType=DE`}
                          >
                            <div className="dashboard__list-info-status-type colored-label colored-label--ok">
                              {locales.dashboard.normalFollowUp}
                            </div>

                            <div className="dashboard__list-info-status-value dashboard__list-info-status-value--ok">
                              {listOneStatusesStats?.DE.normal}
                            </div>
                          </Link>

                          <Link
                            className="dashboard__list-info-status flex flex-column"
                            to={`${routes.listOne.index}/${routes.listOne.statuses}?ticketType=DE`}
                          >
                            <div className="dashboard__list-info-status-type colored-label colored-label--danger">
                              {locales.dashboard.errorFollowUp}
                            </div>

                            <div className="dashboard__list-info-status-value dashboard__list-info-status-value--danger">
                              {listOneStatusesStats?.DE.error}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.byType}
                    </div>

                    {errors.listOneError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listOneError}
                      </div>
                    ) : (
                      <BarChart
                        id="list-one-by-type-chart"
                        data={listOneByTypeChartData}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="grid">
            <div className="sm:col-12 md:col-6 lg:col-4">
              <div className="dashboard__list-info dashboard__list-info--3">
                <div className="dashboard__list-info-title">
                  {locales.general.list} 3
                </div>

                {isListThreeLoading ? (
                  <>
                    <SkeletonLoader height="3rem" />
                    <SkeletonLoader height="3rem" />
                    <SkeletonLoader height="3rem" />
                  </>
                ) : (
                  <>
                    <div className="dashboard__list-info-content">
                      <div className="dashboard__list-info-section-title">
                        {locales.dashboard.livePopulation}
                      </div>

                      {errors.listThreeError.length > 0 ? (
                        <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                          <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                          {errors.listThreeError}
                        </div>
                      ) : (
                        <>
                          <div className="dashboard__list-info-total-insured">
                            {locales.dashboard.totalInsured}

                            <div className="dashboard__list-info-total-insured-value">
                              {listThreeLivePopulation.totalInsured}
                            </div>
                          </div>

                          <div className="dashboard__list-info-no-life-insurance">
                            <div className="flex flex-align-items-center flex-justify-content-center">
                              <div className="dashboard__icon dashboard__icon--warning" />
                              {locales.dashboard.noLifeInsurance}
                            </div>

                            <div className="dashboard__list-info-no-life-insurance-value">
                              {listThreeLivePopulation.totalInsured -
                                listThreeLivePopulation.noLifeInsurance}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="sm:col-12 md:col-6 lg:col-8">
              <div className="dashboard__list-info">
                <div className="dashboard__list-info-title">
                  {locales.general.list} 5
                </div>

                {isListFiveLoading ? (
                  <>
                    <SkeletonLoader height="3rem" />
                    <SkeletonLoader height="3rem" />
                    <SkeletonLoader height="3rem" />
                  </>
                ) : (
                  <div className="dashboard__list-info-content">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.invoiceStatus}
                    </div>

                    {errors.listFiveError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listFiveError}
                      </div>
                    ) : (
                      <>
                        <div className="dashboard__list-info-need-approval">
                          <div className="flex flex-align-items-center flex-justify-content-center">
                            <div className="dashboard__icon dashboard__icon--bell" />
                            {locales.dashboard.needApproval}

                            <div className="dashboard__list-info-need-approval-value">
                              {listFiveStats.needApproval}
                            </div>
                          </div>

                          <div className="flex flex-align-items-center flex-justify-content-center mt-5">
                            {locales.dashboard.Principals}

                            <div className="dashboard__list-info-need-approval-value">
                              {listFiveStats.needApprovalPrincipals}
                            </div>

                            <Divider layout="vertical" />

                            {locales.dashboard.dependents}

                            <div className="dashboard__list-info-need-approval-value">
                              {listFiveStats.needApprovalDependents}
                            </div>
                          </div>
                        </div>

                        <div className="dashboard__list-info-under-azadea">
                          <div className="flex flex-align-items-center flex-justify-content-center">
                            <div className="dashboard__icon dashboard__icon--clock" />
                            {locales.dashboard.underAzadeaReview}

                            <div className="dashboard__list-info-under-azadea-value">
                              {listFiveStats.underAzadeaReview}
                            </div>
                          </div>

                          <div className="flex flex-align-items-center flex-justify-content-center mt-5">
                            {locales.dashboard.Principals}

                            <div className="dashboard__list-info-need-approval-value">
                              {listFiveStats.underAzadeaReviewPrincipals}
                            </div>

                            <Divider layout="vertical" />
                            {locales.dashboard.dependents}

                            <div className="dashboard__list-info-need-approval-value">
                              {listFiveStats.underAzadeaReviewDependents}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="sm:col-12 lg:col-5">
          <div className="dashboard__list-info">
            <div className="dashboard__list-info-title">
              {locales.general.list} 2
            </div>

            {isListTwoLoading ? (
              <>
                <SkeletonLoader height="3rem" />
                <SkeletonLoader height="3rem" />
                <SkeletonLoader height="3rem" />
              </>
            ) : (
              <>
                <div className="dashboard__list-info-total">
                  {locales.dashboard.totalOpenTickets}

                  <div className="dashboard__list-info-total-count">
                    {listTwoTotalOpenTickets}
                  </div>
                </div>

                <div className="dashboard__list-info-content">
                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.byStatus}
                    </div>

                    {errors.listTwoError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listOneError}
                      </div>
                    ) : (
                      <div className="dashboard__list-info-statuses flex">
                        <Link
                          className="dashboard__list-info-status flex flex-column"
                          to={`${routes.listTwo.index}?ticketStatus=open-pending`}
                        >
                          <div className="dashboard__list-info-status-type colored-label colored-label--warning">
                            {locales.dashboard.openPending}
                          </div>

                          <div className="dashboard__list-info-status-value dashboard__list-info-status-value--warning">
                            {listTwoOpenTicketStatuses.openPending}
                          </div>
                        </Link>

                        <Link
                          className="dashboard__list-info-status flex flex-column"
                          to={`${routes.listTwo.index}?ticketStatus=open-clean`}
                        >
                          <div className="dashboard__list-info-status-type colored-label colored-label--ok">
                            {locales.dashboard.openClean}
                          </div>

                          <div className="dashboard__list-info-status-value dashboard__list-info-status-value--ok">
                            {listTwoOpenTicketStatuses.openClean}
                          </div>
                        </Link>

                        <Link
                          className="dashboard__list-info-status flex flex-column"
                          to={`${routes.listTwo.index}?ticketStatus=open-error`}
                        >
                          <div className="dashboard__list-info-status-type colored-label colored-label--danger">
                            {locales.dashboard.openError}
                          </div>

                          <div className="dashboard__list-info-status-value dashboard__list-info-status-value--danger">
                            {listTwoOpenTicketStatuses.openError}
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.byType}
                    </div>

                    {errors.listTwoError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listTwoError}
                      </div>
                    ) : (
                      <BarChart
                        id="list-two-by-type-chart"
                        data={listTwoByTypeChartData}
                      />
                    )}
                  </div>

                  {/* <div className="dashboard__list-info-section">
                      <div className="dashboard__list-info-section-title">
                        {locales.dashboard.totalOpenAndClosedTickets}
                      </div>

                      <LineChart id="total-open-and-closed-tickets" />
                    </div> */}
                </div>
              </>
            )}
          </div>

          <div className="dashboard__info-box flex">
            <div className="dashboard__list-info">
              <div className="dashboard__list-info-title">
                {locales.general.list} 4
              </div>

              {isListFourLoading ? (
                <SkeletonLoader height="10rem" />
              ) : (
                <div className="dashboard__list-info-content">
                  <div className="dashboard__list-info-section-title">
                    {locales.dashboard.endorsementStatus}
                  </div>

                  {errors.listFourError.length > 0 ? (
                    <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                      <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                      {errors.listTwoError}
                    </div>
                  ) : (
                    <BarChart id="list-four-chart" data={listFourChartData} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="sm:col-12 lg:col-2">
          <div className="dashboard__list-info">
            <div className="dashboard__list-info-title">
              {locales.dashboard.systemHealth}
            </div>

            <div className="dashboard__list-info-content">
              {isListZeroLoading ? (
                <>
                  <SkeletonLoader height="4rem" />

                  <SkeletonLoader height="4rem" />

                  <SkeletonLoader height="4rem" />
                </>
              ) : (
                <>
                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.apiEvents}
                    </div>

                    {errors.listStatusError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listTwoError}
                      </div>
                    ) : (
                      <div className="dashboard__list-zero-api-status flex flex-justify-content-space-between flex-align-items-center">
                        {locales.dashboard.apiStatus}
                        <div
                          className={classNames({
                            'colored-label': true,
                            'colored-label--ok': apiStatus,
                            'colored-label--danger': !apiStatus,
                          })}
                        >
                          {apiStatus ? 'Ok' : 'Fail'}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.latestListZero}
                    </div>

                    {errors.listZeroError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listTwoError}
                      </div>
                    ) : (
                      <>
                        <div className="dashboard__list-zero-error-status flex flex-justify-content-space-between flex-align-items-center">
                          {locales.dashboard.errorStatus}
                          <Link
                            to={`${routes.processedEvents.index}?studyId=${studyId}`}
                            className={classNames({
                              'dashboard__list-zero-error-status-link colored-label':
                                true,
                              'colored-label--ok': listZeroErrorStatus,
                              'colored-label--danger': !listZeroErrorStatus,
                            })}
                          >
                            {listZeroErrorStatus
                              ? locales.general.ok
                              : locales.general.fail}
                          </Link>
                        </div>

                        {!isListZeroEnabled && (
                          <div className="dashboard__list-zero-error-status flex flex-justify-content-space-between flex-align-items-center">
                            {locales.dashboard.listZeroStatus}
                            <Link
                              to={`${routes.processedEvents.index}?studyId=${studyId}`}
                              className="dashboard__list-zero-error-status-link colored-label colored-label--danger"
                            >
                              {locales.general.blocked}
                            </Link>
                          </div>
                        )}

                        <div className="dashboard__list-zero-records-count flex flex-justify-content-space-between flex-align-items-center">
                          {locales.dashboard.recordsCount}
                          <div>{listZeroRecordsCount}</div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="dashboard__list-info-section">
                    <div className="dashboard__list-info-section-title">
                      {locales.dashboard.dbDiscrepancies}
                    </div>

                    {errors.companyCodeDiscrepancyError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.companyCodeDiscrepancyError}
                      </div>
                    ) : (
                      <>
                        <div className="dashboard__list-zero-error-status flex flex-justify-content-space-between flex-align-items-center">
                          {locales.dashboard.listZeroDiscrepancies}

                          <div
                            className={classNames({
                              'dashboard__list-zero-error-status-link colored-label':
                                true,
                              'colored-label--ok':
                                companyCodeDiscrepancy.list0Discrepancy
                                  .length === 0,
                              'colored-label--danger':
                                companyCodeDiscrepancy.list0Discrepancy.length >
                                0,
                            })}
                            onClick={handlers.handleDbDiscrepancyClick(
                              'list0Discrepancy'
                            )}
                          >
                            {companyCodeDiscrepancy.list0Discrepancy.length}
                          </div>
                        </div>

                        <div className="dashboard__list-zero-error-status flex flex-justify-content-space-between flex-align-items-center">
                          {locales.dashboard.listThreeDiscrepancies}

                          <div
                            className={classNames({
                              'dashboard__list-zero-error-status-link colored-label':
                                true,
                              'colored-label--ok':
                                companyCodeDiscrepancy.list3Discrepancy
                                  .length === 0,
                              'colored-label--danger':
                                companyCodeDiscrepancy.list3Discrepancy.length >
                                0,
                            })}
                            onClick={handlers.handleDbDiscrepancyClick(
                              'list3Discrepancy'
                            )}
                          >
                            {companyCodeDiscrepancy.list3Discrepancy.length}
                          </div>
                        </div>

                        <div className="dashboard__list-zero-error-status flex flex-justify-content-space-between flex-align-items-center">
                          {locales.dashboard.policiesDiscrepancy}

                          <div
                            className={classNames({
                              'dashboard__list-zero-error-status-link colored-label':
                                true,
                              'colored-label--ok':
                                companyCodeDiscrepancy.policiesDiscrepancy
                                  .length === 0,
                              'colored-label--danger':
                                companyCodeDiscrepancy.policiesDiscrepancy
                                  .length > 0,
                            })}
                            onClick={handlers.handleDbDiscrepancyClick(
                              'policiesDiscrepancy'
                            )}
                          >
                            {companyCodeDiscrepancy.policiesDiscrepancy.length}
                          </div>
                        </div>

                        <div className="dashboard__list-zero-error-status flex flex-justify-content-space-between flex-align-items-center">
                          {locales.dashboard.rbacDiscrepancies}

                          <div
                            className={classNames({
                              'dashboard__list-zero-error-status-link colored-label':
                                true,
                              'colored-label--ok':
                                companyCodeDiscrepancy
                                  .rbacPolicyHolderDiscrepancy.length === 0,
                              'colored-label--danger':
                                companyCodeDiscrepancy
                                  .rbacPolicyHolderDiscrepancy.length > 0,
                            })}
                            onClick={handlers.handleDbDiscrepancyClick(
                              'rbacPolicyHolderDiscrepancy'
                            )}
                          >
                            {
                              companyCodeDiscrepancy.rbacPolicyHolderDiscrepancy
                                .length
                            }
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="dashboard__list-info-section">
                    <Link
                      to={`${routes.processedEvents.index}?studyId=${studyId}`}
                      className="dashboard__list-info-section-title"
                    >
                      {locales.dashboard.latestSuccessfulListZero}
                    </Link>

                    {errors.listZeroError.length > 0 ? (
                      <div className="dashboard__error flex align-center justify-content-center px-3 py-5">
                        <span className="pi pi-exclamation-triangle dashboard__error-icon" />
                        {errors.listTwoError}
                      </div>
                    ) : (
                      <div className="dashboard__list-info-section-value">
                        {humanReadable(latestSuccessfulListZeroApiDate)}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <CompanyCodeDiscrepanciesDialog
        title={locales.dashboard.dbDiscrepancies}
        onHide={handlers.handleDbDiscrepancyDialogClose}
        data={
          dbDiscrepanciesListName
            ? companyCodeDiscrepancy[dbDiscrepanciesListName]
            : []
        }
      />
    </div>
  );
}
