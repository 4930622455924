import {
  Button,
  InputText,
  SkeletonLoader,
  Paginator,
  DataTable,
  Dropdown,
  Calendar,
} from 'components';

import { humanReadable } from 'utils';
import { locales } from 'constants/index';
import { HistoryLogsProps, HistoryLog } from './HistoryLogs.types';
import columns from './columns';

import './history-logs.scss';

function TypeBodyTemplate(row: HistoryLog) {
  return (
    <span
      className={`colored-circle colored-circle--${row.type.toLowerCase()}`}
    >
      {row.type}
    </span>
  );
}

function dateBodyTemplate(field: string) {
  return function (row: HistoryLog) {
    if (row[field as keyof HistoryLog])
      return humanReadable(row[field as keyof HistoryLog] as string);

    return 'N/A';
  };
}

export default function HistoryLogsUi({
  isLoading,
  isExporting,
  totalRecords,
  listNumber,
  filter,
  options,
  data,
  handlers,
}: HistoryLogsProps) {
  return (
    <div className="history-logs">
      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="4.4rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3 ">
            <form
              onSubmit={handlers.handleSubmitSearch}
              className="flex"
              style={{ alignItems: 'flex-end' }}
            >
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="employeeId"
                    name="employeeId"
                    placeholder={locales.general.employeeID}
                    value={filter.employeeId || ''}
                    onChange={handlers.handleChange}
                  />
                </div>

                <div className="col-4">
                  <Button label={locales.general.search} />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="4.4rem" />
          ) : (
            <Dropdown
              id="list"
              name="list"
              placeholder="Select List"
              value={listNumber}
              onChange={handlers.handleListNumberChange}
              options={options.listNumber}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="4.4rem" />
          ) : (
            <Calendar
              placeholder="Filter by date"
              selectionMode="range"
              onChange={handlers.handleDateChange}
              value={filter.dates}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              disabled={!data.logs || data.logs.length === 0}
              onClick={handlers.handleDownload}
              loading={isExporting}
              label={locales.general.download}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleFilterClear}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="4.4rem" width="8rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          onRowClick={handlers.handleRowClick}
          value={data.logs}
          columns={columns.map((column) => {
            if (column.field === 'type') {
              return {
                ...column,
                body: TypeBodyTemplate,
              };
            }

            if (
              column.field === 'createdDate' ||
              column.field === 'updatedDate'
            ) {
              return {
                ...column,
                body: dateBodyTemplate(column.field),
              };
            }

            return column;
          })}
        />
      )}

      {data.logs.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}
    </div>
  );
}
