import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';
import { Toast } from 'primereact/toast';
import { DropdownChangeEvent } from 'primereact/dropdown';

import { routes, locales } from 'constants/index';
import { Helpers, getDobYears, getUserId, getPhoneNumber } from 'utils';
import { useExtractServerError, useValidate } from 'hooks';

import { submitUserDetails } from '../store/actions';
import { useUserDetailsMutation } from '../mutations';

import { IStoreState } from './UserDetails.types';

import UserDetailsUi from './UserDetails.ui';

const validationSchema = {
  firstName: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
  lastName: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
  email: Joi.string()
    .email({
      tlds: false,
    })
    .required()
    .messages({
      'string.empty': locales.general.errors.required,
      'string.email': locales.general.errors.invalidEmail,
    }),
  yearOfBirth: Joi.string().required().messages({
    'string.empty': locales.general.errors.required,
  }),
};

export default function UserDetails() {
  const helpers: Helpers = new Helpers();

  //* Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  const userDetailsMutation = useUserDetailsMutation({
    onSuccess: handleUserDetailsSuccess,
  });

  //* Redux State
  const authData = useSelector(({ auth }: IStoreState) => auth, shallowEqual);

  //* Local State
  const [form, setForm] = useState({
    firstName: authData.firstName,
    lastName: authData.lastName,
    email: authData.email,
    yearOfBirth: authData.yearOfBirth,
  });

  //* Handlers
  function handleInputChange({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    setForm((prevState) => ({
      ...prevState,
      [name]: value.charAt(0).toUpperCase() + value.slice(1),
    }));

    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  }

  function handleInputBlur({
    target: { name, value },
  }: ChangeEvent<HTMLInputElement>) {
    validate({
      data: {
        [name]: value,
      },
      validationSchema: {
        // @ts-ignore
        [name]: validationSchema[name],
      },
    });
  }

  function handleYearOfBirthChange(event: DropdownChangeEvent) {
    setForm((prevState) => ({
      ...prevState,
      yearOfBirth: String(event.value),
    }));

    setErrors((prevState) => ({
      ...prevState,
      yearOfBirth: '',
    }));
  }

  function handleSubmit() {
    const isFormValid = validate({
      data: form,
      validationSchema: validationSchema,
    });

    if (!isFormValid) return;

    dispatch(submitUserDetails(form));

    userDetailsMutation.mutate({
      userId: getUserId(),
      firstname: form.firstName,
      lastname: form.lastName,
      email: form.email.toLowerCase(),
      yob: form.yearOfBirth,
    });
  }

  function handleUserDetailsSuccess() {
    navigate(
      `${routes.authentication.index}/${routes.authentication.emailOtp}`
    );
  }

  useEffect(() => {
    if (userDetailsMutation.isError) {
      const errorMessage = extractErrorMessage(userDetailsMutation.error);
      toastRef.current?.show({ severity: 'error', detail: errorMessage });
    }
  }, [userDetailsMutation.isError]);

  useEffect(() => {
    if (!getPhoneNumber().phoneNumber)
      navigate(
        `${routes.authentication.index}/${routes.authentication.phoneRegister}`
      );
  });

  const options = {
    years: helpers.convertYearsArrayToObject(getDobYears()),
  };

  const data = {
    ...form,
  };

  const handlers = {
    handleInputChange,
    handleInputBlur,
    handleYearOfBirthChange,
    handleSubmit,
  };

  return (
    <>
      <Toast />

      <UserDetailsUi
        isLoading={userDetailsMutation.isLoading}
        options={options}
        errors={errors}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
