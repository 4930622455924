import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes, locales } from 'constants/index';
import { authenticateUser } from 'modules/App/store/actions';
import { useConfigQueries, useUserQuery } from 'modules/App/queries';
import { setAccessToken, setRefreshToken } from 'utils';
import { useExtractServerError } from 'hooks';

import RoleUi from './Role.ui';

import { switchType } from './api';

import { DropdownChangeEvent } from './Role.types';

export const Role = () => {
  //* Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  const [authConfig] = useConfigQueries();
  const userQuery = useUserQuery();

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [role, setRole] = useState('');
  const [error, setError] = useState('');

  function handleRoleChange(event: DropdownChangeEvent) {
    if (event.value) setRole(event.value?.toString());
    setError('');
  }

  function handleSubmitClick(event: any) {
    event.preventDefault();

    if (role.length === 0) return setError(locales.role.error);

    getUserRoleBasedToken(
      userQuery.data?.data.data.user.userTypes?.find(
        (userType) => userType.id === parseInt(role, 10)
      )
    );
  }

  const getUserRoleBasedToken = async (userType: any) => {
    try {
      setIsLoading(true);

      setRole(userType);

      const {
        data: {
          data: { token, refresh_token },
        },
      } = await switchType(userType.id);

      setAccessToken(token, authConfig.data?.data.tokensVariables.token);
      setRefreshToken(
        refresh_token,
        authConfig.data?.data.tokensVariables.refreshToken
      );

      dispatch(
        authenticateUser({ accessToken: token, refreshToken: refresh_token })
      );

      setIsLoading(false);

      navigate(routes.main);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
      setIsLoading(false);
    }
  };

  const data = { role };

  const options = {
    userTypes:
      userQuery.data?.data.data.user.userTypes?.map((option) => ({
        key: option.id.toString(),
        label: option.name,
        value: option.id.toString(),
      })) || [],
  };

  const handlers = { handleRoleChange, handleSubmitClick };

  return (
    <>
      <Toast ref={toastRef} />

      <RoleUi
        isLoading={isLoading}
        data={data}
        options={options}
        error={error}
        handlers={handlers}
      />
    </>
  );
};

export default Role;
