import { InputText, Dropdown } from 'components';

import { locales } from 'constants/index';

import AuthenticationSkeleton from '../AuthenticationSkeleton';

import { IUserDetailsUiProps } from './UserDetails.types';

export default function UserDetailsUi(props: IUserDetailsUiProps) {
  const {
    isLoading,
    options,
    errors,
    data: { firstName, lastName, email, yearOfBirth },
    handlers: {
      handleInputChange,
      handleYearOfBirthChange,
      handleSubmit,
      handleInputBlur,
    },
  } = props;

  return (
    <AuthenticationSkeleton
      title={locales.userDetails.title}
      isLoading={isLoading}
      onContinue={handleSubmit}
      applyLabel={locales.general.continue}
    >
      <div className="form__field">
        <InputText
          id="firstName"
          name="firstName"
          placeholder={locales.general.firstName}
          value={firstName}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          isError={errors.firstName?.length > 0}
        />
      </div>

      <div className="form__field">
        <InputText
          id="lastName"
          name="lastName"
          placeholder={locales.general.lastName}
          value={lastName}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          isError={errors.lastName?.length > 0}
        />
      </div>

      <div className="form__field">
        <InputText
          id="email"
          name="email"
          placeholder={locales.general.email}
          value={email.toLowerCase()}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          isError={errors.email?.length > 0}
        />
      </div>

      <div className="form__field">
        <Dropdown
          id="yearOfBirth"
          name="yearOfBirth"
          placeholder={locales.general.yearOfBirth}
          value={yearOfBirth}
          onChange={handleYearOfBirthChange}
          onBlur={handleInputBlur}
          error={errors.yearOfBirth}
          options={options.years}
        />
      </div>
    </AuthenticationSkeleton>
  );
}
