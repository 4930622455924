import { ColumnProps } from 'primereact/column';

const columns: ColumnProps[] = [
  {
    header: 'Invoice',
    field: 'clover_invoice',
    sortable: true,
  },
  {
    header: 'Country Of Company Invoiced',
    field: 'country',
    sortable: true,
  },
  {
    header: 'Company',
    field: 'company',
    sortable: true,
  },
  {
    header: 'Invoice Date',
    field: 'created',
    sortable: true,
  },
  {
    header: 'Invoice Amount',
    field: 'total',
    sortable: true,
  },
  {
    header: 'Currency',
    field: 'currency',
    sortable: true,
  },
  {
    header: 'NB. Of People In Invoice',
    field: 'personcount',
    sortable: true,
  },
  {
    header: 'Endorsement Status',
    field: 'clover_endorsementstatus',
    sortable: true,
  },
  {
    header: 'Exception Description',
    field: 'clover_exceptiondescription',
    sortable: true,
  },
  {
    header: 'AZADEA Status Description',
    field: 'azadea_statusdescription',
    sortable: true,
  },
  {
    header: 'AZADEA Force Approval',
    field: 'azadea_forceapproval',
    sortable: true,
  },
  {
    header: 'AZADEA Force Approval User',
    field: 'azadea_forceapprovaluser',
    sortable: true,
  },
  {
    header: 'AZADEA Force Approval Date',
    field: 'azadea_forceapprovaldate',
    sortable: true,
  },
  {
    header: 'AZADEA Rejection Description',
    field: 'azadea_hrms_rejectiondescription',
    sortable: true,
  },

  {
    header: 'More Details',
    field: 'more_details',
  },
  {
    header: 'Details in List 6',
    field: 'list_6_details',
  },
];

export default columns;
