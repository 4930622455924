import { useQuery } from '@tanstack/react-query';

import { fetchListOneTicketHistoryInfoApi } from '../api';

export default function useListOneTicketHistoryQuery(
  params: any,
  options?: any
) {
  return useQuery(
    ['ticket', 'history', 'info', params],
    fetchListOneTicketHistoryInfoApi,
    options
  );
}
