import { InputText, Otp, Button, Spinner } from 'components';

import { locales } from 'constants/index';

import { NewPinResetFormUiProps } from './NewPinResetForm.types';

import './new-pin-reset-form.scss';

export default function NewPinResetFormUi({
  isLoading,
  errors,
  data,
  handlers,
}: NewPinResetFormUiProps) {
  if (isLoading) return <Spinner />;

  return (
    <form
      className="new-pin-reset-form formgrid"
      onSubmit={handlers.handleSubmitClick}
    >
      <div className="field sm:col-12">
        <div className="new-pin-reset-form__label">
          {locales.general.yearOfBirth}
        </div>

        <InputText
          name="securityAnswer"
          value={data.securityAnswer}
          placeholder={locales.general.yearOfBirth}
          onChange={handlers.handleInputChange}
          isError={errors.securityAnswer?.length > 0}
        />
      </div>

      <div className="field sm:col-12">
        <div className="new-pin-reset-form__label">
          {locales.OTP.inputLabel}
        </div>

        <Otp
          numInput={6}
          value={data.otp}
          onChange={handlers.handleOtpChange}
          error={errors.otp}
        />
      </div>

      <div className="field sm:col-12">
        <div className="new-pin-reset-form__label">
          {locales.resetPin.newPin}
        </div>

        <Otp
          isInputSecure
          numInput={6}
          value={data.pinCode}
          onChange={handlers.handlePinChange}
          error={errors.pinCode}
        />
      </div>

      <div className="field sm:col-12">
        <Button label={locales.resetPin.submit} />
      </div>
    </form>
  );
}
