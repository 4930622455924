import { api } from 'utils';

import {
  PhoneRegisterApiPayload,
  PhoneRegisterApiResponse,
  LoginApiPayload,
  LoginApiResponse,
  ValidatePhoneNumberApiPayload,
  ValidatePhoneNumberApiResponse,
  ResendPhoneOtpApiResponse,
  ValidateEmailApiPayload,
  ValidateEmailApiResponse,
  ResendEmailOtpApiResponse,
  CreatePinApiPayload,
  CreatePinApiResponse,
  RegisterInfoApiPayload,
  RegisterInfoApiResponse,
  UpdateUserPinApiPayload,
  RequestPinResetApiResponse,
  UpdateUserPinApiResponse,
} from './api.types';

export function phoneRegisterApi(payload: PhoneRegisterApiPayload) {
  return api().post<PhoneRegisterApiResponse>('/users/register/phone', payload);
}

export function loginApi(payload: LoginApiPayload) {
  return api().post<LoginApiResponse>('/auth/login', payload);
}

export function validatePhoneNumberApi(payload: ValidatePhoneNumberApiPayload) {
  return api().post<ValidatePhoneNumberApiResponse>(
    '/users/validate/phone',
    payload
  );
}

export function resendPhoneOtpApi(userId: string) {
  return api().post<ResendPhoneOtpApiResponse>('/users/resend/phone', {
    userId,
  });
}

export function validateEmailApi(payload: ValidateEmailApiPayload) {
  return api().post<ValidateEmailApiResponse>('/users/validate/email', payload);
}

export function resendEmailOtpApi(userId: string) {
  return api().post<ResendEmailOtpApiResponse>('/users/resend/email', {
    userId,
  });
}

export function createPersonalPinApi(payload: CreatePinApiPayload) {
  return api().post<CreatePinApiResponse>('/users/register/pin', payload);
}

export function registerInfoApi(payload: RegisterInfoApiPayload) {
  return api().post<RegisterInfoApiResponse>('/users/register/info', payload);
}

export function requestPinResetApi(phoneNumber: string) {
  return api().post<RequestPinResetApiResponse>('/users/change/pin', {
    phoneNumber,
  });
}

export function updateUserPinApi(payload: UpdateUserPinApiPayload) {
  return api().put<UpdateUserPinApiResponse>('/users/update/pin', payload);
}
