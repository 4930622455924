import classNames from 'classnames';
import { Button as PrimeReactButton, ButtonProps } from 'primereact/button';

export default function Button({
  children,
  onClick,
  label,
  severity,
  size,
  className = '',
  icon,
  badge = '',
  badgeClassName = '',
  iconPos,
  disabled = false,
  loading = false,
  link = false,
  rounded = false,
  text = false,
  outlined = false,
}: ButtonProps) {
  const classes = classNames({
    'std-button': true,
    [className]: className.length > 0,
  });

  return (
    <PrimeReactButton
      onClick={onClick}
      className={classes}
      label={label}
      severity={severity}
      size={size}
      icon={icon}
      iconPos={iconPos}
      badge={badge}
      badgeClassName={badgeClassName}
      disabled={disabled}
      loading={loading}
      link={link}
      rounded={rounded}
      text={text}
      outlined={outlined}
    >
      {children}
    </PrimeReactButton>
  );
}
