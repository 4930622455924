const columns = [
  {
    header: 'Employee Id',
    field: 'azadeaemployee_id',
    sortable: true,
  },
  {
    header: 'Person Id',
    field: 'personid',
    sortable: true,
  },
  {
    header: 'Gender',
    field: 'gender',
    sortable: true,
  },
  {
    header: 'Marital status',
    field: 'maritalstatus',
    sortable: true,
  },
  {
    header: 'Invoice number',
    field: 'clover_invoice',
    sortable: true,
  },
  {
    header: 'Invoice date',
    field: 'invoicedate',
    sortable: true,
  },
  {
    header: 'Invoice type',
    field: 'invoicetype',
    sortable: true,
  },
  {
    header: 'Active',
    field: 'active',
    sortable: true,
  },
  {
    header: 'Company',
    field: 'company',
    sortable: true,
  },
  {
    header: 'BU',
    field: 'bu',
    sortable: true,
  },
  {
    header: 'Name',
    field: 'name',
    sortable: true,
  },
  {
    header: 'Relation',
    field: 'relation',
    sortable: true,
  },
  {
    header: 'Endorsement type',
    field: 'endorsementtype',
    sortable: true,
  },
  {
    header: 'Endorsement LOB',
    field: 'endorsement_lob',
    sortable: true,
  },
  {
    header: 'Plan',
    field: 'plan',
    sortable: true,
  },
  {
    header: 'Exception Id',
    field: 'clover_exceptionid',
    sortable: true,
  },
  {
    header: 'Exception description',
    field: 'clover_exceptiondescription',
    sortable: true,
  },
  {
    header: 'Total',
    field: 'total',
    sortable: true,
  },
  {
    header: 'More details',
    field: 'details_link',
  },
  {
    header: 'Created',
    field: 'created',
    sortable: true,
  },
  {
    header: 'Updated At',
    field: 'updated',
    sortable: true,
  },
];

export default columns;
