import { Button } from 'components';

import { locales } from 'constants/index';

import { TErrorFallbackUi } from './ErrorFallback.types';

import './error-fallback.scss';

const ErrorFallbackUi: TErrorFallbackUi = ({
  errorMessage,
  handleRefreshClick,
}) => {
  return (
    <div className="error-fallback flex flex-column flex-align-items-center flex-justify-content-center">
      <div className="error-fallback__image" />

      <div className="error-fallback__title">{locales.errors.title}</div>
      <div className="error-fallback__description">{errorMessage}</div>

      <Button onClick={handleRefreshClick} label={locales.errors.refreshPage} />
    </div>
  );
};

export default ErrorFallbackUi;
