import { Otp } from 'components';

import { locales } from 'constants/index';

import AuthenticationSkeleton from '../AuthenticationSkeleton';

import { ILoginOtpUiProps } from './LoginOtpVerification.types';

function LoginOtpUi({
  isLoading,
  isResendOtpEnabled,
  otpError,
  countdownTimer,
  data: { otp },
  handlers: { handleOtpChange, handleVerifyOtp, handleResendOtp },
}: ILoginOtpUiProps) {
  return (
    <AuthenticationSkeleton
      title={locales.login.title}
      isLoading={isLoading}
      onContinue={handleVerifyOtp}
    >
      <div className="form__field">
        <div className="personal-pin__label">OTP</div>
        <Otp
          value={otp}
          numInput={6}
          onChange={handleOtpChange}
          error={otpError}
        />

        <div className="otp-verification__timer">
          {isResendOtpEnabled ? (
            <div
              className="otp-verification__resend-otp"
              role="presentation"
              onClick={handleResendOtp}
            >
              {locales.formatString(locales.OTP.resendPlatform, {
                platform: locales.general.mobileNumber.toLowerCase(),
              })}
            </div>
          ) : (
            `${locales.OTP.resend} ${new Date(countdownTimer * 1000)
              .toISOString()
              .substr(14, 5)}`
          )}
        </div>
      </div>
    </AuthenticationSkeleton>
  );
}

export default LoginOtpUi;
