import { useRef, useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Messages } from 'primereact/messages';

import { Header } from 'components';

import { locales, routes } from 'constants/index';
import { AzadeaImages } from 'assets';

import './navigation-layout.scss';

export default function NavigationLayout() {
  const messagesRef = useRef<Messages>(null);
  const { pathname } = useLocation();
  const { ticketId } = useParams();
  const isPageHeaderVisible =
    !pathname.includes(routes.role) &&
    !pathname.includes(routes.authentication.resetPin);

  function getPageTitle() {
    if (pathname.includes(routes.listOne.index)) {
      if (ticketId) return locales.azadea.ticketDetails.title;

      return `${locales.general.list} One Tickets`;
    }

    if (pathname.includes(routes.listTwo.index)) {
      if (ticketId) return locales.azadea.ticketDetails.title;

      return `${locales.general.list} Two Tickets`;
    }

    if (pathname.includes(routes.listThree.index)) {
      if (ticketId) return 'List Three Table';

      return `${locales.general.list} Three Table`;
    }

    if (pathname.includes(routes.listFour.index))
      return `${locales.general.list} Four Tickets`;

    if (pathname.includes(routes.listFive.index))
      return `${locales.general.list} Five Tickets`;

    if (pathname.includes(routes.listSix.index))
      return `${locales.general.list} Six Tickets`;

    if (pathname.includes(routes.logs.index))
      return locales.azadea.historyTickets.title;

    return `Dashboard`;
  }

  useEffect(() => {
    if (
      window.location.hostname.includes('uat') ||
      window.location.hostname.includes('localhost')
    )
      messagesRef.current?.show({
        sticky: true,
        severity: 'warn',
        detail: 'Development Mode',
        closable: false,
      });
  }, []);

  return (
    <div className="navigation-layout">
      <header className="navigation-layout-header">
        <Messages ref={messagesRef} />

        <Header isRolePage={pathname.includes(routes.role)} />

        {isPageHeaderVisible && (
          <div className="navigation-layout__page-head flex flex-align-items-center">
            <div className="navigation-layout__title">{getPageTitle()}</div>

            <img
              className="navigation-layout__logo"
              src={AzadeaImages.Logo}
              alt="logo"
            />
          </div>
        )}
      </header>

      <main className="navigation-layout__main">
        <Outlet />
      </main>
    </div>
  );
}
