import { TRouter } from './types';

import AuthorizedRoutes from './AuthorizedRoutes';
import UnAuthorizedRoutes from './UnAuthorizedRoutes';

const Router: TRouter = ({ isAuthenticated }) => {
  return isAuthenticated ? <AuthorizedRoutes /> : <UnAuthorizedRoutes />;
};

export default Router;
