// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/icons/slide.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-one-ticket-details__slide {\n  border-radius: 50rem;\n  position: absolute;\n  right: -2rem;\n  top: 2.5rem;\n  background-size: cover;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  width: 5rem;\n  height: 5rem;\n  z-index: 9999;\n  transform: rotate(180deg);\n  cursor: pointer;\n}\n.list-one-ticket-details__slide:hover {\n  box-shadow: 0px 1px 1.5rem rgba(0, 0, 0, 0.2);\n}\n.list-one-ticket-details__type--red {\n  color: #b50000;\n}", "",{"version":3,"sources":["webpack://./src/modules/ListOne/ListOneTicketDetails/list-one-ticket-details.scss"],"names":[],"mappings":"AAGE;EACE,oBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,yDAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;AAFJ;AAII;EACE,6CAAA;AAFN;AAOI;EACE,cAAA;AALN","sourcesContent":["@import '../../../styles/theme';\n\n.list-one-ticket-details {\n  &__slide {\n    border-radius: 50rem;\n    position: absolute;\n    right: -2rem;\n    top: 2.5rem;\n    background-size: cover;\n    background-image: url('../../../assets/icons/slide.png');\n    width: 5rem;\n    height: 5rem;\n    z-index: 9999;\n    transform: rotate(180deg);\n    cursor: pointer;\n\n    &:hover {\n      box-shadow: 0px 1px 1.5rem #00000033;\n    }\n  }\n\n  &__type {\n    &--red {\n      color: $primary-color;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
