import { useQueries } from '@tanstack/react-query';

import {
  fetchListZeroBriefApi,
  fetchListOneBriefApi,
  fetchListTwoBriefApi,
  fetchListThreeBriefApi,
  fetchListFourBriefApi,
  fetchListFiveBriefApi,
  fetchStatusApi,
  fetchTicketStatusStatsApi,
  fetchCompanyCodeDiscrepancy,
} from '../api';

const useDashboardDataQuery = (listOneFilter: any, listTwoFilter: any) =>
  useQueries({
    queries: [
      {
        queryKey: ['dashboard', 'list', 'zero'],
        queryFn: fetchListZeroBriefApi,
      },
      {
        queryKey: ['dashboard', 'list', 'one'],
        queryFn: () => fetchListOneBriefApi(listOneFilter),
      },
      {
        queryKey: ['dashboard', 'list', 'two'],
        queryFn: () => fetchListTwoBriefApi(listTwoFilter),
      },
      {
        queryKey: ['dashboard', 'list', 'three'],
        queryFn: fetchListThreeBriefApi,
      },
      {
        queryKey: ['dashboard', 'list', 'four'],
        queryFn: fetchListFourBriefApi,
      },
      {
        queryKey: ['dashboard', 'list', 'five'],
        queryFn: fetchListFiveBriefApi,
      },
      {
        queryKey: ['dashboard', 'list', 'status'],
        queryFn: fetchStatusApi,
      },
      {
        queryKey: ['dashboard', 'tickets', 'status', 'stats'],
        queryFn: fetchTicketStatusStatsApi,
      },
      {
        queryKey: ['dashboard', 'health', 'companyCodeDiscrepancy'],
        queryFn: fetchCompanyCodeDiscrepancy,
      },
    ],
  });

export default useDashboardDataQuery;
