import { api } from 'utils';

import {
  FetchListSixApiResponse,
  FetchListSixInfoApiResponse,
} from './api.types';

export function fetchListSixApi({ queryKey }: any) {
  const [, , filter] = queryKey;

  return api().get<FetchListSixApiResponse>('/list/six/fetch', {
    params: {
      limit: filter.limit,
      page: filter.page,
      azadeaEmployee_id: filter.azadeaId,
      endorsement_id: filter.endorsementId,
      endorsementStatus: filter.endorsementStatus,
      azadeaStatus: filter.azadeaStatus,
    },
  });
}

export function fetchListSixInfoApi({ queryKey }: any) {
  const [, , , id] = queryKey;

  return api().get<FetchListSixInfoApiResponse>('/list/six/info', {
    params: { id },
  });
}
