const routes = {
  main: '/',
  role: '/role',
  studies: 'studies',
  admin: '/admin',
  authentication: {
    index: '/auth',
    phoneRegister: 'phone-register',
    login: 'login',
    loginOtpVerification: 'login-otp-verification',
    signup: 'signup',
    phoneOtp: 'phone-verification',
    userDetails: 'user-details',
    emailOtp: 'email-verification',
    personalPin: 'personal-pin',
    logout: 'logout',
    resetPin: 'reset-pin',
    newPinReset: 'reset-pin/new',
  },
  campaign: {
    index: '/campaign',
    consent: 'consent',
    optOut: 'opt-out',
  },
  processedEvents: {
    index: '/processed-events',
  },
  listOne: {
    index: '/list-one',
    statuses: 'statuses',
  },
  listTwo: {
    index: '/list-two',
  },
  listThree: {
    index: '/list-three',
  },
  listFour: {
    index: '/list-four',
  },
  listFive: {
    index: '/list-five',
    needApproval: 'need-approval',
    otherInvoices: 'other-invoices',
  },
  listSix: {
    index: '/list-six',
  },
  logs: {
    index: '/logs',
  },
};

export default routes;
