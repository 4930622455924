import { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';

import {
  useListTwoTicketQuery,
  useListTwoTicketHistoryQuery,
} from '../queries';

import ListTwoTicketDetailsUi from './ListTwoTicketDetails.ui';

export default function ListTwoTicketDetails() {
  //* Hooks Init
  const { ticketId = '', studyId = '' } = useParams();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  //* Queries
  const listTwoTicketQuery = useListTwoTicketQuery(ticketId, {
    enabled: ticketId.length > 0 && studyId.length === 0,
  });
  const listTwoTicketHistoryQuery = useListTwoTicketHistoryQuery(
    { ticketId, studyId },
    {
      enabled: ticketId.length > 0 && studyId.length > 0,
    }
  );

  //* Local State
  const [isInfoSideBarVisible, setIsInfoSideBarVisible] = useState(false);

  //* Handlers
  function handleSideBarClose() {
    setIsInfoSideBarVisible(false);
  }

  function handleRowClick() {
    setIsInfoSideBarVisible(true);
  }

  useEffect(() => {
    if (listTwoTicketQuery.isError || listTwoTicketHistoryQuery.isError) {
      const errorMessage = extractErrorMessage(
        listTwoTicketQuery.error || listTwoTicketHistoryQuery.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listTwoTicketQuery.isError || listTwoTicketHistoryQuery.isError]);

  const data = {
    ticketDetails:
      listTwoTicketQuery.data?.data.data ||
      listTwoTicketHistoryQuery.data?.data.data.study,
    ticketId,
  };

  const handler = {
    handleSideBarClose,
    handleRowClick,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListTwoTicketDetailsUi
        isLoading={
          (listTwoTicketQuery.isLoading &&
            listTwoTicketQuery.isInitialLoading) ||
          (listTwoTicketHistoryQuery.isLoading &&
            listTwoTicketHistoryQuery.isInitialLoading)
        }
        isInfoSideBarVisible={isInfoSideBarVisible}
        data={data}
        handlers={handler}
      />
    </>
  );
}
