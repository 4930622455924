import { CampaignOptOutUiProps } from './CampaignOptOut.types';

import { Button } from 'components';

import '../CampaignConsent/campaign-consent.scss';

export default function CampaignOptOutUi({ handlers }: CampaignOptOutUiProps) {
  return (
    <div className="campaign-consent">
      <div className="campaign-consent__form flex flex-column justify-content-center	align-items-center	">
        <div className="campaign-consent__logo"></div>

        <div className="campaign-consent__message">
          Would you like to opt-out from policy notifications service?
        </div>

        <div className="campaign-consent__actions flex">
          <Button
            outlined
            className="campaign-consent__action"
            onClick={handlers.handleCancelClick}
          >
            No, thanks
          </Button>

          <Button
            className="campaign-consent__action"
            onClick={handlers.handleConfirmClick}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
}
