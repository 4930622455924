import classNames from 'classnames';
import {
  Calendar as PrimeReactCalendar,
  CalendarProps,
} from 'primereact/calendar';

export default function Calendar({
  id,
  name,
  inputId,
  value,
  placeholder,
  dateFormat,
  minDate,
  maxDate,
  readOnlyInput,
  locale,
  selectionMode = 'single',
  showIcon,
  showButtonBar,
  showTime,
  hourFormat = '12',
  timeOnly,
  view = 'date',
  numberOfMonths = 1,
  dateTemplate,
  touchUI,
  inline,
  showWeek,
  disabled,
  className = '',
  onChange,
}: CalendarProps) {
  return (
    <PrimeReactCalendar
      className={classNames({
        'std-calendar': true,
        [className]: className.length > 0,
      })}
      id={id}
      name={name}
      inputId={inputId}
      value={value}
      placeholder={placeholder}
      dateFormat={dateFormat}
      locale={locale}
      minDate={minDate}
      maxDate={maxDate}
      readOnlyInput={readOnlyInput}
      selectionMode={selectionMode}
      showIcon={showIcon}
      showButtonBar={showButtonBar}
      showTime={showTime}
      hourFormat={hourFormat}
      timeOnly={timeOnly}
      view={view}
      numberOfMonths={numberOfMonths}
      dateTemplate={dateTemplate}
      touchUI={touchUI}
      inline={inline}
      showWeek={showWeek}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
