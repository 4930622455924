import { Routes, Route, Navigate } from 'react-router-dom';

import { Authentication, CampaignConsent, CampaignOptOut } from 'modules';
import { routes } from 'constants/index';

const UnAuthorizedRoutes = () => {
  return (
    <Routes>
      <Route
        path={`${routes.authentication.index}/*`}
        element={<Authentication />}
      />

      <Route path={routes.campaign.index}>
        <Route path={routes.campaign.consent} element={<CampaignConsent />} />

        <Route path={routes.campaign.optOut} element={<CampaignOptOut />} />
      </Route>

      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`${routes.authentication.index}/${routes.authentication.login}`}
          />
        }
      />
    </Routes>
  );
};

export default UnAuthorizedRoutes;
