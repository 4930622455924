import { useState, useRef, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useExtractServerError } from 'hooks';
import { useConfigQueries } from 'modules/App/queries';

import { useListSixQuery } from './queries';

import ListSixUi from './ListSix.ui';

import {
  ListSixFilter,
  PaginatorPageChangeEvent,
  DropdownChangeEvent,
} from './ListSix.types';

export default function ListSix() {
  //* Hooks
  const toastRef = useRef<Toast>(null);
  const { id } = useParams<{ id?: string }>();
  const { extractErrorMessage } = useExtractServerError();
  const [searchParams, setSearchParams] = useSearchParams();

  let params = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    azadeaId: searchParams.get('azadeaId') || '',
    endorsementStatus: searchParams.get('endorsementStatus') || '',
    azadeaStatus: searchParams.get('azadeaStatus') || '',
  };

  //* Local State
  const [isListSixInfoVisible, setIsListSixInfoVisible] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [filter, setFilter] = useState<ListSixFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    azadeaId: params.azadeaId,
    endorsementStatus: params.endorsementStatus,
    azadeaStatus: params.azadeaStatus,
  });

  const [, endorsementConfig] = useConfigQueries();

  const listSixQuery = useListSixQuery({ ...params, endorsementId: id }, {});

  //* Handlers
  function handlerClearFilter() {
    setSearchParams({
      page: '1',
      rows: '50',
      azadeaId: '',
      endorsementStatus: '',
      azadeaStatus: '',
    });

    setFilter({
      page: 1,
      rows: 50,
      azadeaId: '',
      endorsementStatus: '',
      azadeaStatus: '',
    });
  }

  function handleInputChange({ target: { value } }: any) {
    setFilter({
      ...filter,
      azadeaId: value.trim(),
    });
  }

  function handlerEndorsementStatuses(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      endorsementStatus: event.value,
      page: '1',
      first: '0',
    });

    setFilter({
      ...filter,
      endorsementStatus: event.value,
      page: 1,
      first: 0,
    });
  }

  function handleAzadeaStatusChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      azadeaStatus: event.value,
      page: '1',
      first: '0',
    });

    setFilter({
      ...filter,
      azadeaStatus: event.value,
      page: 1,
      first: 0,
    });
  }

  function handleSubmitSearch(event: any) {
    event.preventDefault();

    if (event.target.elements.azadeaId.value.length > 0) {
      setSearchParams({
        ...params,
        azadeaId: event.target.elements.azadeaId.value.trim(),
        page: '1',
        rows: '50',
        first: '0',
      });

      setFilter((filter) => ({
        ...filter,
        azadeaId: event.target.elements.azadeaId.value,
        page: 1,
        rows: 50,
        first: 0,
      }));
    } else {
      const { azadeaId, ...others } = params;
      setSearchParams(others);
    }
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...searchParams,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleListSixInfoDialogShow(id: number) {
    return function () {
      setIsListSixInfoVisible(true);
      setSelectedId(id);
    };
  }

  function handleListSixInfoDialogClose() {
    setIsListSixInfoVisible(false);
  }

  useEffect(() => {
    if (listSixQuery.isError) {
      const errorMessage = extractErrorMessage(listSixQuery.error);
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [listSixQuery.isError]);

  const options = {
    endorsementStatuses:
      endorsementConfig.data?.data.listStatuses
        .find(({ list, belongsTo }) => list === 5 && belongsTo === 'clover')
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
    azadeaStatuses:
      endorsementConfig.data?.data.listStatuses
        .find(({ list, belongsTo }) => list === 5 && belongsTo === 'azadea')
        ?.options.map(({ key, value }) => ({
          key: key.toString(),
          label: value,
          value: key.toString(),
        })) || [],
  };

  const data = {
    selectedId,
    list: listSixQuery.data?.data.data || [],
  };

  const handlers = {
    handlerClearFilter,
    handlePageChange,
    handleInputChange,
    handlerEndorsementStatuses,
    handleAzadeaStatusChange,
    handleSubmitSearch,
    handleListSixInfoDialogShow,
    handleListSixInfoDialogClose,
  };

  return (
    <ListSixUi
      isLoading={listSixQuery.isLoading}
      isListSixInfoVisible={isListSixInfoVisible}
      totalRecords={listSixQuery.data?.data.total || 0}
      options={options}
      filter={filter}
      data={data}
      handlers={handlers}
    />
  );
}
