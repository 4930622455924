import { useQuery } from '@tanstack/react-query';

import { fetchInvoiceInfoApi } from '../api';

export default function useInvoiceInfoQuery(
  invoiceNumber: string,
  options?: any
) {
  return useQuery(
    ['list', 'five', 'invoice', 'info', invoiceNumber],
    fetchInvoiceInfoApi,
    options
  );
}
