class Validator {
  rules = {
    isEmpty: false,
    isNumeric: false,
    isInvalidEmail: false,
    isContainSpecialCharacters: false,
    isLowerCase: false,
    isUpperCase: false,
    isAboveMaxLength: false,
    isBelowMinLength: false,
    isValid: false,
  };

  isEmpty(string: string | number): boolean {
    return !string;
  }

  isNumeric(string: string): boolean {
    const regEx = /\d/;
    return regEx.test(string);
  }

  isInvalidEmail(email: string): boolean {
    const regEx =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !regEx.test(email);
  }

  isInvalidNumber(number: number): boolean {
    return false;
  }

  isContainSpecialCharacters(string: string): boolean {
    const regEx = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    return regEx.test(string);
  }

  isLowerCase(string: string): boolean {
    const regEx = /[a-z]/;
    return regEx.test(string);
  }

  isUpperCase(string: string): boolean {
    const regEx = /[A-Z]/;
    return regEx.test(string);
  }

  isContainOneNumber(string: string): boolean {
    const regEx = /(?=.*[0-9])/;
    return !regEx.test(string);
  }

  isAboveMaxLength(string: string, maxLength: number): boolean {
    return string.length > maxLength;
  }

  isBelowMinLength(string: string, minLength: number): boolean {
    return string.length < minLength;
  }
}

export default Validator;
