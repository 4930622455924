import { useState, useEffect, useRef, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { routes, locales } from 'constants/index';
import { getUserId, getPhoneNumber } from 'utils';
import { useExtractServerError, useCountdownTimer } from 'hooks';

import {
  useValidatePhoneMutation,
  useResendPhoneOtpMutation,
} from '../mutations';

import OtpVerification from '../OtpVerification';

export default function PhoneOtp() {
  const { phoneNumber = '', callingCode = '' } = getPhoneNumber();

  //* Hooks Init
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();
  const { countdownTimer, isResendOtpEnabled, startCountdownTimer } =
    useCountdownTimer();

  //* Mutations
  const validatePhoneMutation = useValidatePhoneMutation({
    onSuccess: handleVerifyOtpSuccess,
  });
  const resendPhoneOtpMutation = useResendPhoneOtpMutation();

  //* Local State
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');

  //* Handlers
  function handleChangeOtp(otp: string) {
    setOtp(otp);
    setOtpError('');
  }

  function handleVerifyOtp(event: FormEvent) {
    event.preventDefault();

    if (!otp) return setOtpError(locales.login.errors.otpRequired);

    validatePhoneMutation.mutate({
      userId: getUserId(),
      otp,
    });
  }

  function handleVerifyOtpSuccess() {
    navigate(
      `${routes.authentication.index}/${routes.authentication.userDetails}`
    );
  }

  function handleResendOtp() {
    setOtp('');
    startCountdownTimer();

    resendPhoneOtpMutation.mutate(getUserId());
  }

  useEffect(() => {
    if (validatePhoneMutation.isError) {
      const errorMessage = extractErrorMessage(validatePhoneMutation.error);
      toastRef.current?.show({
        severity: 'error',
        detail: errorMessage,
      });
    }

    if (resendPhoneOtpMutation.isError) {
      const errorMessage = extractErrorMessage(resendPhoneOtpMutation.error);
      toastRef.current?.show({
        severity: 'error',
        detail: errorMessage,
      });
    }
  }, [validatePhoneMutation.isError, resendPhoneOtpMutation.isError]);

  useEffect(() => {
    if (!phoneNumber)
      navigate(
        `${routes.authentication.index}/${routes.authentication.phoneRegister}`
      );
  });

  const data = {
    title: locales.formatString(locales.OTP.mobileTitle, {
      phoneNumber: `${callingCode}${phoneNumber}`,
    }),
    platform: locales.general.phoneNumber.toLocaleLowerCase(),
    otp,
  };

  const handlers = {
    handleChangeOtp,
    handleVerifyOtp,
    handleResendOtp,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <OtpVerification
        isLoading={
          validatePhoneMutation.isLoading || resendPhoneOtpMutation.isLoading
        }
        isResendOtpEnabled={isResendOtpEnabled}
        countdownTimer={countdownTimer}
        otpError={otpError}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
