import { useQuery } from '@tanstack/react-query';

import { fetchListOneApi } from '../api';

import { ListOneParams } from '../ListOne.types';

export default function useFetchListOneData(
  params: ListOneParams,
  options?: any
) {
  return useQuery(
    ['list', 'one', params],
    () => fetchListOneApi(params),
    options
  );
}
