import { InputText as PrimeReactInputText } from 'primereact/inputtext';

import { IInputTextProps } from './InputText.types';
import classNames from 'classnames';

export default function InputText({
  name,
  value,
  onChange,
  onBlur,
  id = '',
  type = 'text',
  placeholder,
  className = '',
  disabled = false,
  isError = false,
  icon,
  iconPos = 'left',
  keyfilter,
}: IInputTextProps) {
  const classes = classNames({
    'p-invalid': isError,
    className: className.length > 0,
  });

  const inputText = (
    <>
      <PrimeReactInputText
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        className={classes}
        keyfilter={keyfilter}
      />
    </>
  );

  if (icon) {
    return (
      <span className={`p-input-${iconPos}`}>
        <i className={`pi ${icon}`} />
        {inputText}
      </span>
    );
  } else {
    return inputText;
  }
}
