import { useQuery } from '@tanstack/react-query';

import { fetchListTwoTicketHistoryInfoApi } from '../api';

export default function useListTwoTicketHistoryQuery(
  params: any,
  options?: any
) {
  return useQuery(
    ['ticket', 'list', 'two', 'history', 'info', params],
    fetchListTwoTicketHistoryInfoApi,
    options
  );
}
