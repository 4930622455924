import { Otp, Button, Spinner } from 'components';

import { locales } from 'constants/index';

import { IOtpVerificationProps } from './OtpVerification.types';

import './otp-verification.scss';

export default function OtpVerificationUi({
  isLoading,
  isResendOtpEnabled,
  countdownTimer,
  otpError,
  data: { title, platform, otp },
  handlers: { handleChangeOtp, handleVerifyOtp, handleResendOtp },
}: IOtpVerificationProps) {
  if (isLoading) return <Spinner />;

  return (
    <div className="otp-verification">
      <div className="otp-verification__description">{title}</div>

      <form className="form" onSubmit={handleVerifyOtp}>
        <div className="otp-verification__label">{locales.OTP.inputLabel}</div>

        <Otp
          numInput={6}
          value={otp}
          onChange={handleChangeOtp}
          error={otpError}
        />

        <div className="otp-verification__timer">
          {isResendOtpEnabled ? (
            <div
              className="otp-verification__resend-otp"
              role="presentation"
              onClick={handleResendOtp}
            >
              {locales.formatString(locales.OTP.resendPlatform, {
                platform,
              })}
            </div>
          ) : (
            `${locales.OTP.resend} ${new Date(countdownTimer * 1000)
              .toISOString()
              .substr(14, 5)}`
          )}
        </div>

        <div className="form__field flex ">
          <Button
            className="login__submit"
            disabled={isLoading}
            label={locales.general.continue}
          />
        </div>
      </form>
    </div>
  );
}
