import {
  InputText,
  Dropdown,
  Button,
  Paginator,
  SkeletonLoader,
  DataTable,
} from 'components';

import { humanReadable } from 'utils';
import { locales } from 'constants/index';

import columns from './columns';
import {
  ProcessedEventsUiProps,
  ProcessedEvent,
} from './ProcessedEvents.types';

function CreatedDateBodyTemplate(row: ProcessedEvent) {
  return humanReadable(row.createdDate);
}

function StatusBodyTemplate(row: any) {
  return row.status ? locales.general.success : locales.general.unsuccessful;
}

export default function ProcessedEventsUi({
  isLoading,
  totalRecords,
  filter,
  options,
  data,
  handlers,
}: ProcessedEventsUiProps) {
  return (
    <div className="processed-events">
      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3">
            <form onSubmit={handlers.handleSearchClick}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="studyId"
                    name="studyId"
                    placeholder={locales.general.studyID}
                    value={filter.studyId}
                    onChange={handlers.handleChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="4rem" />
          ) : (
            <Dropdown
              id="status"
              name="status"
              placeholder={locales.general.filterByStatus}
              value={filter.status}
              onChange={handlers.handleTicketStatusChange}
              options={options.ticketStatuses}
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="8rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          columns={columns.map((column) => {
            if (column.field === 'status') {
              return {
                ...column,
                body: StatusBodyTemplate,
              };
            }

            if (
              column.field === 'startTime' ||
              column.field === 'endTime' ||
              column.field === 'createdDate'
            ) {
              return {
                ...column,
                body: CreatedDateBodyTemplate,
              };
            }

            return column;
          })}
          value={data.list.map(
            ({
              createdDate,
              log: {
                statusCode,
                response: {
                  startTime,
                  endTime,
                  studyId,
                  success,
                  message,
                  duplicatedEmployees,
                  deletedEmployees,
                },
              },
            }) => ({
              statusCode,
              startTime,
              endTime,
              studyId,
              status: success,
              message,
              duplicatedEmployees:
                duplicatedEmployees?.length > 0
                  ? duplicatedEmployees.join('-')
                  : duplicatedEmployees,
              deletedEmployees:
                deletedEmployees?.length > 0
                  ? deletedEmployees.join('-')
                  : deletedEmployees,
              createdDate,
            })
          )}
        />
      )}
      {data.list.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}
    </div>
  );
}
