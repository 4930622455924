import { useQuery } from '@tanstack/react-query';

import { getListZeroStatus } from '../api';

const useListZeroStatusQuery = (isEnabled: boolean = false) =>
  useQuery(['list', 'zero', 'status'], getListZeroStatus, {
    enabled: isEnabled,
  });

export default useListZeroStatusQuery;
