import { locales } from 'constants/index';

import { IError } from 'types';

const useExtractServerError = () => {
  const extractErrorMessage = (errorObject: unknown) => {
    const error = errorObject as IError;

    let message =
      error?.response.data?.message ||
      error?.response.data?.error ||
      locales.errors.title;

    if (error.response.status === 403) {
      message = locales.errors.forbidden;
    }

    if (error.response.status === 401 || error.response.status === 403) {
      message = 'You are not authorized to perform this action.';
    }

    return message;
  };

  return { extractErrorMessage };
};

export default useExtractServerError;
