import { useQuery } from '@tanstack/react-query';

import { fetchUserInfoApi } from '../api';

const useUserQuery = (accessToken?: string) =>
  useQuery(['users'], fetchUserInfoApi, {
    enabled: !!accessToken,
    staleTime: Infinity,
  });

export default useUserQuery;
