import { useQuery } from '@tanstack/react-query';

import { fetchListTwoTicketDetailsApi } from '../api';

export default function useFetchListTwoData(ticketId: string, options?: any) {
  return useQuery(
    ['ticket', 'list', 'two', 'info', ticketId],
    fetchListTwoTicketDetailsApi,
    options
  );
}
