import { Chip as PRChip } from 'primereact/chip';
import classNames from 'classnames';

import { ChipProps } from './Chip.types';

import './chip.scss';

export default function Chip({
  label,
  removable,
  icon,
  image,
  template,
  color = '',
  style = {},
}: ChipProps) {
  const classes = classNames({
    'std-chip': true,
    [`std-chip--${color}`]: color.length > 0,
  });

  return (
    <PRChip
      label={label}
      removable={removable}
      icon={icon}
      image={image}
      template={template}
      className={classes}
      style={style}
    />
  );
}
