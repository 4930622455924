import { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { locales, routes } from 'constants/index';
import {
  getPhoneNumber,
  getUserId,
  setAccessToken,
  setRefreshToken,
} from 'utils';
import { useExtractServerError } from 'hooks';
import { useConfigQueries } from 'modules/App/queries';

import { authenticateUser, clearAuthStore } from '../store/actions';
import { useCreatePersonalPinMutation } from '../mutations';

import PersonalPinUi from './PersonalPin.ui';
import { IStoreState } from './PersonalPin.types';

export default function PersonalPin() {
  //* Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const { extractErrorMessage } = useExtractServerError();

  const [authConfig] = useConfigQueries();
  const createPersonalPinMutation = useCreatePersonalPinMutation({
    onSuccess: handleCreatePersonalPinSuccess,
  });

  //* Redux State
  const authData = useSelector(({ auth }: IStoreState) => auth, shallowEqual);

  //* Local State
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [pin, setPin] = useState('');
  const [pinConfirmation, setPinConfirmation] = useState('');
  const [errors, setErrors] = useState({
    pin: '',
    pinConfirmation: '',
  });

  //* Handlers
  function handlePinChange(pin: string) {
    setPin(pin);
    setErrors((prevState) => ({
      ...prevState,
      pin: '',
    }));
  }

  function handlePinConfirmationChange(pinConfirmation: string) {
    setPinConfirmation(pinConfirmation);
    setErrors((prevState) => ({
      ...prevState,
      pinConfirmation: '',
    }));
  }

  function handleSubmit() {
    if (!validatePins()) return;

    createPersonalPinMutation.mutate({
      userId: getUserId(),
      pinCode: pin,
    });
  }

  function handleConfirmationDialogHide() {
    setIsConfirmationDialogOpen(false);

    navigate(
      `${routes.authentication.index}/${routes.authentication.phoneRegister}`
    );
  }

  //* Helpers
  function validatePins() {
    if (pin.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        pin: locales.personalPin.errors.requiredPin,
      }));

      return pin.length === 6;
    }
    if (pinConfirmation.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        pinConfirmation: locales.personalPin.errors.requiredPinConfirmation,
      }));

      return pinConfirmation.length === 6;
    }

    if (pin !== pinConfirmation) {
      setErrors((prevState) => ({
        ...prevState,
        pinConfirmation: locales.personalPin.errors.notMatch,
      }));

      return pin === pinConfirmation;
    }

    return pin.length === 6 && pinConfirmation.length === 6;
  }

  function handleCreatePersonalPinSuccess({ data }: any) {
    if (data?.data.token && data?.data.refresh_token) {
      setAccessToken(
        data.data.token,
        authConfig.data?.data.tokensVariables.token
      );
      setRefreshToken(
        data.data.refresh_token,
        authConfig.data?.data.tokensVariables.refreshToken || 0
      );

      dispatch(
        authenticateUser({
          accessToken: data.data.token,
          refreshToken: data.data.refresh_token,
        })
      );

      navigate(routes.role);
    } else {
      setIsConfirmationDialogOpen(true);
    }
  }

  useEffect(() => {
    if (!getPhoneNumber().phoneNumber)
      navigate(
        `${routes.authentication.index}/${routes.authentication.phoneRegister}`
      );
  });

  useEffect(() => {
    if (createPersonalPinMutation.isError) {
      const errorMessage = extractErrorMessage(createPersonalPinMutation.error);
      toastRef.current?.show({
        severity: 'error',
        detail: errorMessage,
      });
    }
  }, [createPersonalPinMutation.isError]);

  useEffect(() => {
    return () => {
      dispatch(clearAuthStore());
    };
  }, []);

  const data = {
    pin,
    pinConfirmation,
    firstName: authData.firstName,
    lastName: authData.lastName,
  };

  const handlers = {
    handlePinChange,
    handlePinConfirmationChange,
    handleSubmit,
    handleConfirmationDialogHide,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <PersonalPinUi
        isLoading={createPersonalPinMutation.isLoading}
        isConfirmationDialogOpen={isConfirmationDialogOpen}
        errors={errors}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
