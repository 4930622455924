import { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

import { useConfigQueries, useUserQuery } from 'modules/App/queries';
import { useExtractServerError } from 'hooks';
import { getGermanDate } from 'utils';

import { useListOneTicketsQuery } from './queries';
import {
  useListOneMutation,
  useDeleteListOneOpenPendingTicketMutation,
  useTransferListOneDeletionTickets,
} from './mutations';

import ListOneUi from './ListOne.ui';

import {
  ListOneFilter,
  ListOneParams,
  PaginatorPageChangeEvent,
  DropdownChangeEvent,
  CalendarChangeEvent,
  ListOneTicket,
} from './ListOne.types';

export default function ListOne() {
  //* Hooks Init
  const toastRef = useRef<Toast>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { extractErrorMessage } = useExtractServerError();

  let params: ListOneParams = {
    page: searchParams.get('page') || '1',
    first: searchParams.get('first') || '0',
    rows: searchParams.get('rows') || '50',
    ticketStatus: searchParams.get('ticketStatus') || 'All',
    ticketType: searchParams.get('ticketType') || 'All',
    employeeId: searchParams.get('employeeId') || '',
    statusCodes: searchParams.get('statusCodes') || '',
    startDate: searchParams.get('startDate') || '',
    endDate: searchParams.get('endDate') || '',
  };

  //* Local state
  const [filter, setFilter] = useState<ListOneFilter>({
    page: parseInt(params.page),
    first: parseInt(params.first),
    rows: parseInt(params.rows),
    employeeId: params.employeeId,
    ticketStatus: params.ticketStatus,
    ticketType: params.ticketType,
    statusCodes: params.statusCodes,
    dates: [new Date(params.startDate), new Date(params.endDate)],
  });
  const [openPendingTicketId, setOpenPendingTicketId] = useState('');
  const [selectedTickets, setSelectedTickets] = useState<ListOneTicket[]>([]);
  const [
    isTransferDeletionTicketsConfirmDialogVisible,
    setTransferDeletionTicketsConfirmDialogVisible,
  ] = useState(false);

  //* Queries & Mutations
  const [, endorsementConfig] = useConfigQueries();
  const userQuery = useUserQuery();
  const listOneTicketsQuery = useListOneTicketsQuery(params);
  const listOneMutation = useListOneMutation({
    onSuccess: handleDownloadSuccess,
  });
  const deleteListOneOpenPendingTicketMutation =
    useDeleteListOneOpenPendingTicketMutation({
      onSuccess: handleDeleteOpenPendingTicketSuccess,
    });
  const transferListOneDeletionTickets = useTransferListOneDeletionTickets({
    onSuccess: handleTransferDeletionTicketsSuccess,
  });

  //* Handlers
  function handleInputChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    setFilter((filter) => ({
      ...filter,
      employeeId: value,
    }));
  }

  function handleSearchClick(event: any) {
    event.preventDefault();

    const value = event.target.elements.employeeId.value;

    if (value.length > 0) {
      setSearchParams({
        ...params,
        page: '1',
        rows: '50',
        first: '0',
        employeeId: value.trim(),
      });

      setFilter((filter) => ({
        ...filter,
        page: 1,
        rows: 50,
        first: 0,
      }));
    } else {
      const { employeeId, ...others } = params;
      setSearchParams(others);
    }

    setFilter((filter) => ({
      ...filter,
      employeeId: value,
    }));
  }

  function handleFilterClear() {
    setSearchParams({
      page: '1',
      rows: '50',
      first: '0',
      ticketStatus: 'All',
      ticketType: 'All',
    });

    setFilter({
      page: 1,
      rows: 50,
      first: 0,
      ticketStatus: 'All',
      ticketType: 'All',
      employeeId: '',
      statusCodes: '',
      dates: null,
    });
  }

  function handleTicketTypeChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      ticketType: event.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      ticketType: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handleTicketStatusChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      ticketStatus: event.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      ticketStatus: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handleStatusCodeChange(event: DropdownChangeEvent) {
    setSearchParams({
      ...params,
      statusCodes: event.value,
      page: '1',
      first: '0',
    });

    setFilter((filter) => ({
      ...filter,
      statusCodes: event.value,
      page: 1,
      first: 0,
    }));
  }

  function handleDateChange(event: CalendarChangeEvent) {
    if (Array.isArray(event.value)) {
      const startDate = !!event.value[0]
        ? getGermanDate(event.value[0].toISOString())
        : '';
      const endDate = !!event.value[1]
        ? getGermanDate(event.value[1].toISOString())
        : '';

      setSearchParams({
        ...params,
        startDate,
        endDate,
      });

      setFilter((filter) => ({
        ...filter,
        dates: event.value,
        page: 1,
        first: 0,
      }));
    }
  }

  function handlePageChange(event: PaginatorPageChangeEvent) {
    setSearchParams({
      ...params,
      page: `${event.page + 1}`,
      first: event.first.toString(),
      rows: event.rows.toString(),
    });

    setFilter((filter) => ({
      ...filter,
      page: event.page,
      first: event.first,
      rows: event.rows,
    }));
  }

  function handleDownloadClick() {
    listOneMutation.mutate({ ...filter, exportable: true });
  }

  function handleDeleteOpenPendingTicketConfirmationDialogVisible(
    ticketId: string
  ) {
    return () => setOpenPendingTicketId(ticketId);
  }

  function handleDeleteOpenPendingTicketConfirmationDialogHide() {
    setOpenPendingTicketId('');
  }

  function handleDeleteOpenPendingTicketClick() {
    deleteListOneOpenPendingTicketMutation.mutate(openPendingTicketId);
  }

  function handleDownloadSuccess({ data }: any) {
    window.open(data.url);
  }

  function handleDeleteOpenPendingTicketSuccess() {
    listOneTicketsQuery.refetch();
    setOpenPendingTicketId('');
  }

  function handleTransferDeletionTicketsSuccess() {
    setTransferDeletionTicketsConfirmDialogVisible(false);
    setSelectedTickets([]);

    listOneTicketsQuery.refetch();
  }

  function handleSelectionChange(event: any) {
    setSelectedTickets(event.value);
  }

  function handleConfirmTransferDeletionTicketsClick() {
    transferListOneDeletionTickets.mutate(
      selectedTickets.map(({ employeeId }) => employeeId)
    );
  }

  function handleTransferDeletionTicketsClick() {
    setTransferDeletionTicketsConfirmDialogVisible(true);
  }

  function handleConfirmTransferDeletionTicketsDialogHide() {
    setTransferDeletionTicketsConfirmDialogVisible(false);
  }

  useEffect(() => {
    if (
      listOneTicketsQuery.isError ||
      listOneMutation.isError ||
      deleteListOneOpenPendingTicketMutation.isError ||
      transferListOneDeletionTickets.isError
    ) {
      const errorMessage = extractErrorMessage(
        listOneTicketsQuery.error ||
          listOneMutation.error ||
          deleteListOneOpenPendingTicketMutation.error ||
          transferListOneDeletionTickets.error
      );
      toastRef.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: errorMessage,
      });
    }
  }, [
    listOneTicketsQuery.isError,
    listOneMutation.isError,
    deleteListOneOpenPendingTicketMutation.isError,
    transferListOneDeletionTickets.isError,
  ]);

  const options = {
    ticketStatuses:
      endorsementConfig.data?.data.statuses.map(({ key, value }) => {
        if (key === 'All') {
          return {
            key,
            label: key,
            value: key,
          };
        }
        return {
          key: value,
          label: key,
          value,
        };
      }) || [],
    ticketTypes:
      endorsementConfig.data?.data.ticketsType.map(({ key, value }) => {
        if (key === 'All') {
          return {
            key,
            label: key,
            value: key,
          };
        }
        return {
          key: value,
          label: key,
          value,
        };
      }) || [],
    statusCodes:
      endorsementConfig.data?.data.statusCodes.map(
        ({ _id, statusId, statusDescription }) => {
          return {
            key: _id,
            label: statusDescription,
            value: statusId.toString(),
          };
        }
      ) || [],
  };

  const data = {
    tickets: listOneTicketsQuery.data?.data.data || [],
    selectedTickets,
  };

  const handlers = {
    handleTicketStatusChange,
    handleTicketTypeChange,
    handleDateChange,
    handlePageChange,
    handleFilterClear,
    handleInputChange,
    handleSearchClick,
    handleDownloadClick,
    handleDeleteOpenPendingTicketClick,
    handleStatusCodeChange,
    handleDeleteOpenPendingTicketConfirmationDialogVisible,
    handleDeleteOpenPendingTicketConfirmationDialogHide,
    handleSelectionChange,
    handleTransferDeletionTicketsClick,
    handleConfirmTransferDeletionTicketsClick,
    handleConfirmTransferDeletionTicketsDialogHide,
  };

  return (
    <>
      <Toast ref={toastRef} />

      <ListOneUi
        isLoading={listOneTicketsQuery.isLoading}
        isExporting={listOneMutation.isLoading}
        isTransferDeletionTicketsConfirmDialogVisible={
          isTransferDeletionTicketsConfirmDialogVisible
        }
        isDeleteOpenPendingTicketButtonVisible={
          !!userQuery.data?.data.data.user.userTypes
            .find(
              (type) => type.id === userQuery.data?.data.data.user.userTypeId
            )
            ?.buttons.find(
              (button) => button.identifier === 'deleteOpenPendingTicket'
            ) ?? false
        }
        openPendingTicketId={openPendingTicketId}
        totalRecords={listOneTicketsQuery.data?.data.total || 0}
        options={options}
        filter={filter}
        data={data}
        handlers={handlers}
      />
    </>
  );
}
