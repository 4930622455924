const columns = [
  {
    header: 'Type',
    field: 'type',
    sortable: true,
  },
  {
    header: 'Employee ID',
    field: 'employeeId',
    sortable: true,
  },
  {
    header: 'Endorsement ID',
    field: 'endorsementId',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
  {
    header: 'Status Description',
    field: 'statusDescription',
    sortable: true,
  },
  {
    header: 'Affected Date',
    field: 'affectedDate',
    sortable: true,
  },
  {
    header: 'Created Date',
    field: 'createdDate',
    sortable: true,
  },
  {
    header: 'Updated Date',
    field: 'updatedDate',
    sortable: true,
  },
  {
    header: 'Policy ID',
    field: 'policy.id',
    sortable: true,
  },
  {
    header: 'Ticket ID',
    field: 'id',
    sortable: true,
  },
  {
    header: '',
    field: 'link',
    sortable: false,
  },
];

export default columns;
