import { useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Toast } from 'primereact/toast';

import Router from 'modules/Router';

import { Spinner } from 'components';

import { getAccessToken, getRefreshToken, getLocale } from 'utils';
import { useToast, useExtractServerError } from 'hooks';

import { initApp, unAuthenticateUser, authenticateUser } from './store/actions';
import {
  useUserQuery,
  useConfigQueries,
  useListZeroStatusQuery,
} from './queries';

import { IStoreState, TApp } from './App.types';

const App: TApp = () => {
  const accessToken = getAccessToken();

  //* Hooks
  const toastRef = useRef<Toast>(null);
  const dispatch = useDispatch();
  const showToast = useToast(toastRef);
  const { extractErrorMessage } = useExtractServerError();

  const [authConfigQuery, endorsementConfigQuery] = useConfigQueries();
  const userQuery = useUserQuery(accessToken);

  const listZeroStatusQuery = useListZeroStatusQuery(
    !!accessToken &&
      !!userQuery.data?.data.data.user.userTypes
        ?.find(({ id }) => id === userQuery.data?.data.data.user.userTypeId)
        ?.buttons.find(({ identifier }) => identifier === 'yearly-tags')
  );

  //* Redux State
  const { locale, authStatus } = useSelector(
    ({ app }: IStoreState) => app,
    shallowEqual
  );

  if (authConfigQuery.isError) {
    const errorMessage = extractErrorMessage(authConfigQuery.error);
    showToast({ severity: 'error', message: errorMessage });
  }

  if (endorsementConfigQuery.isError) {
    const errorMessage = extractErrorMessage(endorsementConfigQuery.error);
    showToast({ severity: 'error', message: errorMessage });
  }

  //* Helpers
  const setLocale = () => {
    const appLocale: string = getLocale() || locale;
    dispatch(initApp(appLocale));
  };

  const checkAuthStatus = () => {
    const refreshToken = getRefreshToken();

    if (!accessToken || !refreshToken) {
      dispatch(unAuthenticateUser());
      return;
    }

    dispatch(
      authenticateUser({
        accessToken,
        refreshToken,
      })
    );
  };

  useEffect(() => {
    setLocale();
  }, []);

  useEffect(() => {
    if (authStatus === 'LOADED') checkAuthStatus();
  }, [authStatus]);

  useEffect(() => {
    if (accessToken) userQuery.refetch();
  }, [accessToken]);

  if (
    authConfigQuery.isLoading ||
    endorsementConfigQuery.isLoading ||
    listZeroStatusQuery.isInitialLoading
  )
    return <Spinner />;

  return (
    <div className="page">
      <Toast ref={toastRef} />

      <Router isAuthenticated={accessToken ? accessToken.length > 0 : false} />
    </div>
  );
};

export default App;
