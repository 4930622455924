import { useQuery } from '@tanstack/react-query';

import { fetchListOneHistoryLogsApi } from '../api';

export default function useListOneLogsData(filter: any, options?: any) {
  return useQuery(
    ['list', 'one', 'logs', filter],
    () => fetchListOneHistoryLogsApi(filter),
    options
  );
}
