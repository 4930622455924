import { Link } from 'react-router-dom';

import {
  DataTable,
  Dropdown,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
} from 'components';

import { locales, routes } from 'constants/index';

import columns from './columns';
import { ListThreeUiProps } from './ListThree.types';

function DetailedInfoBodyTemplate(row: any) {
  return (
    <Link
      className="az-tickets-table__link"
      to={`${routes.listThree.index}/${row['employee_staff_id']}`}
    >
      Read more
    </Link>
  );
}

function HistoryTicketsBodyTemplate(row: any) {
  return (
    <Link
      className="az-tickets-table__link"
      to={`${routes.logs.index}?list=2&employeeId=${row['employee_staff_id']}`}
    >
      Read more
    </Link>
  );
}

export default function ListThree({
  isLoading,
  isExporting,
  totalRecords,
  options,
  filter,
  data,
  handlers,
}: ListThreeUiProps) {
  return (
    <>
      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3">
            <form onSubmit={handlers.handleSubmitSearch}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="azadeaId"
                    name="azadeaId"
                    placeholder="Search Azadea ID"
                    value={filter.azadeaId}
                    onChange={handlers.handleInputChange}
                  />
                </div>

                <div className="col-4">
                  <Button label={locales.general.search} />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="country"
              name="country"
              placeholder="Filter by country"
              value={filter.country}
              onChange={handlers.handleCountryChange}
              options={options.countries}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleDownload}
              loading={isExporting}
              disabled={isExporting || !data.list?.length}
              label="Export to Excel (Full Table)"
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleClearAllFilters}
              label={locales.general.clearFilter}
              outlined
              className="clear-filter-button"
            />
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height={200} />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          columns={columns.map((column) => {
            if (column.field === 'detailedInfo') {
              return {
                ...column,
                body: DetailedInfoBodyTemplate,
              };
            }

            if (column.field === 'historyTickets') {
              return {
                ...column,
                body: HistoryTicketsBodyTemplate,
              };
            }

            return {
              ...column,
              body: (row: any) =>
                !row[column.field] ? 'N/A' : row[column.field],
            };
          })}
          value={data.list}
        />
      )}

      {data.list?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}
    </>
  );
}
