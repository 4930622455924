import { api } from 'utils';

import {
  FetchListOneApiResponse,
  FetchListOneTicketDetailsApiResponse,
  FetchListOneTicketStudiesApiResponse,
  FetchListOneStatusesApiResponse,
  DeleteListOneOpenPendingTicketApiResponse,
} from './api.types';

export async function fetchListOneApi(filter: any) {
  return await api().get<FetchListOneApiResponse>('/tickets/fetch', {
    params: {
      page: filter.exportable ? undefined : filter.page,
      limit: filter.exportable ? undefined : filter.rows,
      type: filter.ticketType === 'All' ? '' : filter.ticketType,
      status: filter.ticketStatus === 'All' ? '' : filter.ticketStatus,
      minDate: filter.startDate,
      maxDate: filter.endDate,
      searchString: filter.employeeId,
      exportable: filter.exportable,
      statusCodes: filter.statusCodes,
    },
  });
}

export async function fetchListOneTicketDetailsApi({ queryKey }: any) {
  const [, , , ticketId] = queryKey;

  return api().get<FetchListOneTicketDetailsApiResponse>(`/ticket/fetch/info`, {
    params: { id: ticketId },
  });
}

export async function fetchListOneTicketHistoryInfoApi({ queryKey }: any) {
  const [, , , params] = queryKey;

  return api().get(`/ticket/history/info`, {
    params: { id: params.studyId, ticketId: params.ticketId },
  });
}

export async function fetchListOneTicketStudiesApi({ queryKey }: any) {
  const [, , ticketId] = queryKey;

  return api().get<FetchListOneTicketStudiesApiResponse>('/tickets/history', {
    params: {
      ticketId,
    },
  });
}

export async function fetchListOneStatusesBriefApi({ queryKey }: any) {
  const [, , , params] = queryKey;

  return api().get<FetchListOneStatusesApiResponse>('/tickets/status/brief', {
    params: {
      page: params.exportable ? undefined : params.page,
      limit: params.exportable ? undefined : params.rows,
      type: params.ticketType === 'All' ? '' : params.ticketType,
    },
  });
}

export async function deleteListOneOpenPendingTicketApi(ticketId: string) {
  return api().delete<DeleteListOneOpenPendingTicketApiResponse>(
    `/list/AD/deletion?ticketId=${ticketId}`
  );
}

export async function transferListOneDeletionTickets(ticketIds: string[]) {
  return api().post<DeleteListOneOpenPendingTicketApiResponse>(
    `/list/transfer/deletion`,
    { employee_ids: ticketIds }
  );
}
