import { useState, useRef, useCallback } from 'react';

export default function useCountdownTimer(seconds: number = 300) {
  //* Hooks Initialization
  const intervalRef: any = useRef();

  //* Local States
  const [isResendOtpEnabled, setIsResendOtpEnabled] = useState(true);
  const [countdownTimer, setCountdownTimer] = useState(seconds);

  function otpTimer() {
    if (seconds > 0) setCountdownTimer((prevSeconds) => prevSeconds - 1);

    if (seconds <= 0) {
      stopCountdownTimer();
      setIsResendOtpEnabled(true);
    }
  }

  function stopCountdownTimer() {
    clearInterval(intervalRef.current);
  }

  function startCountdownTimer() {
    intervalRef.current = setInterval(memoizedOtpTimer, 1000);

    setIsResendOtpEnabled(false);
  }

  const memoizedOtpTimer = useCallback(otpTimer, [seconds]);

  return {
    countdownTimer: countdownTimer,
    isResendOtpEnabled,
    startCountdownTimer,
  };
}
