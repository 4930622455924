import { Routes, Route, Navigate } from 'react-router-dom';

import {
  Dashboard,
  ProcessedEvents,
  Role,
  ListOne,
  ListOneTicketDetails,
  ListOneTicketStudies,
  ListOneStatusesListing,
  ListTwo,
  ListTwoTicketDetails,
  ListTwoTicketStudies,
  ListThree,
  ListThreeDetails,
  ListFour,
  ListFiveLayout,
  ListFiveNeedForApprovalListing,
  ListFiveOtherInvoicesListing,
  ListSix,
  HistoryLogs,
  Logout,
  ResetPin,
  NewPinResetForm,
  Admin,
} from 'modules';
import { NavigationLayout } from 'layouts';

import { routes } from 'constants/index';

export function AzadeaRoutes() {
  return (
    <Routes>
      <Route element={<NavigationLayout />}>
        <Route path={routes.main} element={<Dashboard />} />

        <Route path={routes.listOne.index}>
          <Route index element={<ListOne />} />

          <Route
            path={routes.listOne.statuses}
            element={<ListOneStatusesListing />}
          />

          <Route path=':ticketId'>
            <Route index element={<ListOneTicketDetails />} />

            <Route path={routes.studies}>
              <Route index element={<ListOneTicketStudies />} />

              <Route path=':studyId' element={<ListOneTicketDetails />} />
            </Route>
          </Route>
        </Route>

        <Route path={routes.listTwo.index}>
          <Route index element={<ListTwo />} />

          <Route path=':ticketId'>
            <Route index element={<ListTwoTicketDetails />} />

            <Route path={routes.studies}>
              <Route index element={<ListTwoTicketStudies />} />

              <Route path=':studyId' element={<ListTwoTicketDetails />} />
            </Route>
          </Route>
        </Route>

        <Route path={routes.listThree.index}>
          <Route index element={<ListThree />} />

          <Route path=':id'>
            <Route index element={<ListThreeDetails />} />
          </Route>
        </Route>

        <Route path={routes.listFour.index} element={<ListFour />} />

        <Route path={routes.listFive.index} element={<ListFiveLayout />}>
          <Route
            path={routes.listFive.needApproval}
            element={<ListFiveNeedForApprovalListing />}
          />

          <Route
            path={routes.listFive.otherInvoices}
            element={<ListFiveOtherInvoicesListing />}
          />

          <Route
            path={routes.listFive.index}
            element={<Navigate replace to={routes.listFive.needApproval} />}
          />
        </Route>

        <Route path={routes.listSix.index}>
          <Route index element={<ListSix />} />

          <Route path=':id'>
            <Route index element={<ListSix />} />
          </Route>
        </Route>

        <Route path={routes.logs.index} element={<HistoryLogs />} />

        <Route path={routes.processedEvents.index}>
          <Route index element={<ProcessedEvents />} />

          <Route path=':status'>
            <Route index element={<ProcessedEvents />} />
          </Route>
        </Route>

        <Route path={routes.role} element={<Role />} />

        <Route path={routes.admin} element={<Admin />} />

        <Route path={routes.authentication.index}>
          <Route path={routes.authentication.resetPin} element={<ResetPin />} />
          <Route
            path={routes.authentication.newPinReset}
            element={<NewPinResetForm />}
          />
          <Route path={routes.authentication.logout} element={<Logout />} />
        </Route>

        <Route path='*' element={<Navigate replace to={routes.main} />} />
      </Route>
    </Routes>
  );
}

export default function AuthorizedRoutes() {
  return <AzadeaRoutes />;
}
