import { Link } from 'react-router-dom';

import {
  DataTable,
  Dropdown,
  Calendar,
  Paginator,
  SkeletonLoader,
  Button,
  InputText,
} from 'components';

import { humanReadable, humanReadableGermanDate } from 'utils';
import { locales, routes } from 'constants/index';

import columns from './columns';
import { ListTwoUiProps, ListTwoTicket } from './ListTwo.types';

import './list-two.scss';

function TypeBodyTemplate(row: ListTwoTicket) {
  return (
    <span
      className={`colored-circle colored-circle--${row.type.toLowerCase()}`}
    >
      {row.type}
    </span>
  );
}

function dateBodyTemplate(field: string) {
  return function (row: ListTwoTicket) {
    if (row[field as keyof ListTwoTicket])
      return humanReadable(row[field as keyof ListTwoTicket] as string);

    return 'N/A';
  };
}

function affectedDateTemplate(row: ListTwoTicket) {
  if (row.affectedDate) return row.affectedDate;

  return 'N/A';
}

function LinkBodyTemplate(row: ListTwoTicket) {
  return (
    <Link to={`${routes.listTwo.index}/${row.id}`}>
      <div className="list-two-tickets__icon list-two-tickets__icon--arrow" />
    </Link>
  );
}

export default function ListTwoUi({
  isExporting,
  isLoading,
  filter,
  options,
  totalRecords,
  data: { tickets },
  handlers,
}: ListTwoUiProps) {
  return (
    <div className="list-two-tickets">
      <div className="formgrid grid justify-content-end">
        <div className="field sm:col-6 md:col-6 lg:col-2">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              className="az-tickets__download"
              disabled={!tickets || tickets.length === 0}
              onClick={handlers.handleDownload}
              loading={isExporting}
              label={locales.general.download}
            />
          )}
        </div>

        <div className="field sm:col-6 md:col-6 lg:col-1">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Button
              onClick={handlers.handleClearAllFilters}
              label={locales.general.clearFilter}
              outlined
            />
          )}
        </div>
      </div>

      <div className="formgrid grid align-items-end">
        {isLoading ? (
          <SkeletonLoader height="3.5rem" />
        ) : (
          <div className="field sm:col-12 md:col-6 lg:col-3">
            <form onSubmit={handlers.handleSubmitSearch}>
              <div className="formgrid grid">
                <div className="col-8">
                  <InputText
                    id="employeeId"
                    name="employeeId"
                    placeholder={locales.general.employeeID}
                    value={filter.employeeId}
                    onChange={handlers.handleChange}
                  />
                </div>

                <div className="col-4">
                  <Button
                    className="processed-events__search"
                    label={locales.general.search}
                  />
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="ticketType"
              name="ticketType"
              placeholder={locales.general.filterByType}
              value={filter.ticketType ? filter.ticketType : ''}
              onChange={handlers.handleTicketTypeChange}
              options={options.ticketTypes}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <Dropdown
              id="status"
              name="status"
              placeholder={locales.general.filterByStatus}
              value={filter.ticketStatus ? filter.ticketStatus : ''}
              onChange={handlers.handleTicketStatusChange}
              options={options.ticketStatuses}
            />
          )}
        </div>

        <div className="field sm:col-12 md:col-6 lg:col-3">
          {isLoading ? (
            <SkeletonLoader height="3.5rem" />
          ) : (
            <div className="flex flex-column">
              <Calendar
                placeholder="Filter by date"
                selectionMode="range"
                onChange={handlers.handleDateChange}
                value={filter.dates}
              />
            </div>
          )}
        </div>
      </div>

      {isLoading ? (
        <SkeletonLoader height="10rem" />
      ) : (
        <DataTable
          sortMode="multiple"
          totalRecords={totalRecords}
          columns={columns.map((column) => {
            if (column.field === 'type') {
              return {
                ...column,
                body: TypeBodyTemplate,
              };
            }

            if (
              column.field === 'createdDate' ||
              column.field === 'updatedDate'
            ) {
              return {
                ...column,
                body: dateBodyTemplate(column.field),
              };
            }

            if (column.field === 'affectedDate')
              return {
                ...column,
                body: affectedDateTemplate,
              };

            if (column.field === 'link') {
              return {
                ...column,
                body: LinkBodyTemplate,
              };
            }

            return {
              ...column,
              Cell: ({ value }: any) => (!value ? 'N/A' : value),
            };
          })}
          value={tickets.map((ticket) => ({
            ...ticket,
            affectedDate: humanReadableGermanDate(
              ticket.affectedDate as string,
              'DD/MM/YYYY'
            ),
          }))}
        />
      )}

      {tickets?.length > 0 &&
        (isLoading ? (
          <SkeletonLoader height="3rem" width="40rem" />
        ) : (
          <Paginator
            first={filter.first}
            rows={filter.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={handlers.handlePageChange}
          />
        ))}
    </div>
  );
}
